import { HomeComponent } from './home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from '../core/services/auth-guard.service';

const routes: Routes = [
  // {
  //   path: 'home',
  //   component: HomeComponent,
  //   pathMatch: 'full',
  //   canActivate: [AuthGuard],
  //   data: {
  //     roles: [
  //       environment.roles.CompApprovAdmin,
  //       environment.roles.CompApprovUser,
  //       environment.roles.CompApprovInitiator,
  //       environment.roles.CompApprovPayroll,
  //       environment.roles.CompApprovReviewer
  //     ]
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class HomeRoutingModule {}
