import { CompensationReviewHistory, VacationReviewHistory } from './compensationReview.model';
import { ServiceLine, ConsultantLevel, PrimaryCompetency } from './draftSummary.model';

export class Employee {
  userId: string;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
  accountingUserId: string;
  practiceId: string;
  practiceName: string;
  serviceLineId: string;
  startDate: Date;
  compensationReviewHistory: CompensationReviewHistory[];
  vacationReviewHistory: VacationReviewHistory[];
  serviceLine: ServiceLine;
  consultantLevel: ConsultantLevel;
  primaryCompetency: PrimaryCompetency;
}

export class AnnualSalaryHistory {
  salaryHistoryId: number;
  employeeId: string;
  amount: number;
  effectiveDate: Date;
  currencyId: number;
  ModifiedDate: Date;
}

export class AnnualVacationHistory {
  vacationHistoryId: number;
  employeeId: string;
  daysAmount: number;
  effectiveDate: Date;
  ModifiedDate: Date;
}
