import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationService } from '../../core/services/navigation.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-mini-navigation',
  templateUrl: './mini-navigation.component.html',
  styleUrls: ['./mini-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MiniNavigationComponent implements OnInit {
  constructor(public ns: NavigationService, private router: Router) { }
  showMenuItems = false;
  ngOnInit() {
    if (!this.showMenuItems) {
      const interaval = interval(100).subscribe(() => {
        this.showMenuItems = this.ns.showMenu;
        if (this.showMenuItems) interaval.unsubscribe();
      });
    }
  }

  isChildActive(url: string) {
    return this.router.isActive(url, false);
  }
}
