import { formatDate } from '@angular/common';
import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      // Customize formatting in a full numeric date.
      return formatDate(date, 'MM/dd/yyyy', this.locale);;
    } else {
      // For the other cases, return the default
      return super.format(date, displayFormat);
    }
  }
}
