import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, reduce, switchMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';

import { Practice, PrimaryCompetency, ConsultantLevel, ServiceLine, StatusType } from '../models/draftSummary.model';
import { CompensationReviewStatusType, CompensationReviewCommentType } from './../models/compensationReview.model';
import { CommonData } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/common`;

  private _commonData$: Observable<CommonData>;

  constructor(private http: AuthorizedHttp) { }

  getAllCommonData = (): Observable<CommonData> => {
    return this._commonData$
      ? of(this._commonData$)
      : this.http.get(`${this._apiEndpoint}/all`).pipe(
        map(result => {
          this._commonData$ = result;
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Could not retrieve common data'))
      );
  }

}
