<div class="p-3 dialog-header text-center">
  <span class="dialog-title d-inline">{{data.title}}</span>
</div>

<div class="dialog-body">
  <form *ngIf="form" [formGroup]="form">
    <div class="row">
      <label>{{data.message}}</label>
    </div>
    <div class="row">
      <label class="required-label">{{data.hint}}</label>
    </div>
    <div class="row">
      <textarea type="text" class="form-control field-input" id="reasons" formControlName="reasons">
      </textarea>
    </div>
  </form>
</div>

<mat-dialog-actions class="justify-content-center">
  <div class="row " >
    <button type="button" class="btn btn-action-dialog cancel-button"
      (click)="close()">{{data.cancelButtonTitle}}</button>
    <button type="button" class="btn btn-action-dialog success-button ml-1"
      (click)="submit()">{{data.okButtonTitle}}</button>
  </div>
</mat-dialog-actions>