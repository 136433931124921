import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { DraftSummary, ConsultantLevel, Practice, PrimaryCompetency, ServiceLine, StatusType, FilterStatusType } from '../models/draftSummary.model';
import { Employee } from './../models/employee.model';
import { defaultPractice, defaultCompetency, defaultConsultantLevel, defaultServiceLine, defaultFilterStatusType, defaultFiscalYear } from './compensation.service';
import { defaultOnliner } from './onliner.service';
import { WorkflowSummary } from '../models/workflowSummary.model';
import { StatusTypeEnum } from '../models/enums.model';
import { DatePipe } from '@angular/common';
import { WorkflowPageViewSummary } from '../models/workflowPageViewSummary.model';

@Injectable({
  providedIn: 'root'
})
export class CompensationFilterService {

  constructor(private formBuilder: UntypedFormBuilder, private datePipe: DatePipe) { }
  _filterCriteriaForm: FilterFormGroup;

  resetForm(defaultReviewer: boolean, defaultInitiator: boolean, reviewerUser: Employee, initiatorUser: Employee, reviewerStatusType: FilterStatusType, fiscalYear: number) {
    this._filterCriteriaForm && this._filterCriteriaForm.form.reset(resetFormValues(defaultReviewer, defaultInitiator, reviewerUser, initiatorUser, reviewerStatusType, fiscalYear));
  }

  getFilterCriteriaForm(defaultReviewer: boolean, defaultInitiator: boolean, reviewerUser: Employee = null, initiatorUser: Employee = null, defStatusType: FilterStatusType = null): FilterFormGroup {
    return this.createFilterCriteriaForm(defaultReviewer, defaultInitiator, reviewerUser, initiatorUser, defStatusType, defaultFiscalYear);
  }

  getFilterCriteriaModel(defaultReviewer: boolean, defaultInitator: boolean, reviewerUserId: string = null, initiatorAccountingUserId: string = null) {
    const filterCriteria = this._filterCriteriaForm.getFilterCriteria(defaultReviewer, defaultInitator, reviewerUserId, initiatorAccountingUserId);
    return filterCriteria;
  }

  prepareStringToFilter = (valueString: string) => {
    return valueString.toLocaleUpperCase().trim();
  };

  filterPractice(
    { practice }: WorkflowPageViewSummary,
    { practiceId: criteriaPracticeId, practice: criteriaPractice }: CompensationFilterCriteria
  ): boolean {
    if (practice == null) {
      practice = '';
      //        { practiceId: '', name: '' };
    }

    return criteriaPracticeId === defaultPractice.practiceId || criteriaPracticeId === practice || criteriaPractice === practice;
  }

  filterConsultantLevel(
    onlinerCompensation: WorkflowPageViewSummary,
    { consultantLevelId: criteriaConsultantLevelId }: CompensationFilterCriteria
  ): boolean {
    if (onlinerCompensation.consultantLevel == null) {
      onlinerCompensation.consultantLevel = new ConsultantLevel();
      onlinerCompensation.consultantLevel.consultantLevelId = defaultConsultantLevel.consultantLevelId;
    }
    return (
      criteriaConsultantLevelId === defaultConsultantLevel.consultantLevelId ||
      criteriaConsultantLevelId === onlinerCompensation.consultantLevel.consultantLevelId
    );
  }

  filterPrimaryCompetency(
    onlinerCompensation: WorkflowPageViewSummary,
    { primaryCompetencyId: criteriaCompetencyId, primaryCompetency: criteriaCompetency }: CompensationFilterCriteria
  ): boolean {
    if (onlinerCompensation.primaryCompetency == null) {
      //onlinerCompensation.primaryCompetency = new PrimaryCompetency();
      onlinerCompensation.primaryCompetency = defaultCompetency.competencyId;
    }
    return criteriaCompetencyId === defaultCompetency.competencyId ||
      criteriaCompetencyId === onlinerCompensation.primaryCompetency ||
      criteriaCompetency === onlinerCompensation.primaryCompetencyName;
  }

  filterReviewer(
    { reviewerEmployee }: WorkflowPageViewSummary,
    { reviewerId: criteriaReviewerId }: CompensationFilterCriteria
  ): boolean {
    if (reviewerEmployee == null) {
      reviewerEmployee = new Employee();
      reviewerEmployee.userId = '';
    }
    return criteriaReviewerId === defaultOnliner.userId || criteriaReviewerId === reviewerEmployee.userId;
  }

  filterInitiator(
    { initiatorEmployeeId }: WorkflowPageViewSummary,
    { initiatorId: criteriaInitiatorId }: CompensationFilterCriteria
  ): boolean {
    //const initiatedIX = status.findIndex(s => s.statusTypeId == StatusTypeEnum.Initiated);
    //const initiatedIX = statusTypeId == StatusTypeEnum.Initiated ? statusTypeId : null;
    const initiatorAccountingId = initiatorEmployeeId == null ? criteriaInitiatorId : initiatorEmployeeId;
    return criteriaInitiatorId === defaultOnliner.accountingUserId || criteriaInitiatorId === initiatorAccountingId;
  }

  filterServiceLine(
    { serviceLine }: WorkflowPageViewSummary,
    { serviceLineId: criteriaServiceLineId, serviceLine: criteriaServiceLine }: CompensationFilterCriteria
  ): boolean {
    return criteriaServiceLineId === defaultServiceLine.serviceLineId || criteriaServiceLineId === serviceLine || criteriaServiceLine === serviceLine;
  }

  filterstatusType(
    { statusType }: WorkflowPageViewSummary,
    { statusTypeId: criteriastatusTypeId }: CompensationFilterCriteria
  ): boolean {
    return criteriastatusTypeId === defaultFilterStatusType.statusTypeId || criteriastatusTypeId === statusType;
  }

  filterFiscalYear(
    { anniversaryDate }: WorkflowPageViewSummary,
    { fiscalYear: criteriaFiscalYear }: CompensationFilterCriteria
  ): boolean {
    var date = anniversaryDate as Date;
    const defaultFiscalYear = anniversaryDate == null ? criteriaFiscalYear : parseInt(this.datePipe.transform(date, 'yyyy'));
    return criteriaFiscalYear == parseInt(this.datePipe.transform(date, 'yyyy'));
  }

  filterByReviewer(
    { reviewerId }: any,
    { revieweraccountingUserId: criteriaReviewerId }: CompensationFilterCriteria
  ): boolean {
    return (
      reviewerId &&
      (this.prepareStringToFilter(criteriaReviewerId) === 'ALL' ||
        this.prepareStringToFilter(criteriaReviewerId) === this.prepareStringToFilter(reviewerId))
    );
  }

  filterByPractice({ practiceId }: any, { practiceId: criteriaPracticeId }: CompensationFilterCriteria): boolean {
    return (
      practiceId &&
      (this.prepareStringToFilter(criteriaPracticeId) === 'ALL' ||
        this.prepareStringToFilter(criteriaPracticeId) === this.prepareStringToFilter(practiceId))
    );
  }

  filterByServiceLine(
    { serviceLineId }: any,
    { serviceLineId: criteriaServiceLineId }: CompensationFilterCriteria
  ): boolean {
    return (
      this.prepareStringToFilter(criteriaServiceLineId) === 'ALL' ||
      this.prepareStringToFilter(criteriaServiceLineId) === this.prepareStringToFilter(serviceLineId)
    );
  }

  filterByCompetency(
    { primaryCompetencyId }: any,
    { primaryCompetencyId: criteriaPrimaryCompetencyId }: CompensationFilterCriteria
  ): boolean {
    if (!primaryCompetencyId) primaryCompetencyId = -1;
    return criteriaPrimaryCompetencyId === -1 || criteriaPrimaryCompetencyId === primaryCompetencyId;
  }

  filterByConsultantLevel(
    { consultantLevelId }: any,
    { consultantLevelId: criteriaConsultantLevelId }: CompensationFilterCriteria
  ): boolean {
    if (!consultantLevelId) consultantLevelId = -1;
    return criteriaConsultantLevelId === -1 || criteriaConsultantLevelId === consultantLevelId;
  }

  private createFilterCriteriaForm(defaultReviewer: boolean, defaultInitiator: boolean, reviewerUser: Employee, initiatorUser: Employee, defStatusType: FilterStatusType, fiscalYear: number): FilterFormGroup {
    this._filterCriteriaForm = new FilterFormGroup(this.formBuilder.group(defaultForm(defaultReviewer, defaultInitiator, reviewerUser, initiatorUser, defStatusType, fiscalYear)));
    if (defaultReviewer && reviewerUser.practiceId != null) {
      const newDefaultPractice: Practice = { practiceId: reviewerUser.practiceId, name: reviewerUser.practiceName };
      this._filterCriteriaForm.form.controls[fieldNames.practice].setValue(newDefaultPractice);
      this._filterCriteriaForm.form.controls[fieldNames.practice].updateValueAndValidity();
    }
    return this._filterCriteriaForm;
  }
}

interface IFilterForm {
  startDate: Date;
  endDate: Date;
  practice: Practice;
  competency: PrimaryCompetency;
  consultantLevel: ConsultantLevel;
  reviewer: Employee;
  initiator: Employee;
  serviceLine: ServiceLine;
  statusType: StatusType;
  fiscalYear: number;
}
class CompensationFilterCriteria {
  startDate: Date;
  endDate: Date;
  practiceId: string;
  practice: string;
  consultantLevelId: number;
  primaryCompetencyId: number;
  primaryCompetency: string;
  reviewerId: string;
  revieweraccountingUserId: string;
  initiatorId: string;
  serviceLineId: string;
  serviceLine: string;
  statusTypeId: number;
  fiscalYear: number;
}

const firstDayOfTheYear = () => new Date(new Date().getFullYear(), 0, 1);
const defaultEndDate = () => new Date(new Date().getFullYear(), 11, 31);

const fieldNames = {
  startDate: 'startDate',
  endDate: 'endDate',
  practice: 'practice',
  competency: 'competency',
  consultantLevel: 'consultantLevel',
  reviewer: 'reviewer',
  initiator: 'initiator',
  serviceLine: 'serviceLine',
  statusType: 'statusType',
  fiscalYear: 'fiscalYear'
};
const resetFormValues = (defaultReviewer: boolean, defaultInitiator: boolean, reviewerUser: Employee, initiatorUser: Employee, defStatusType: FilterStatusType, fiscalYear: number): IFilterForm => {
  const form = {} as IFilterForm;
  const newDefaultPractice: Practice = defaultReviewer && reviewerUser.practiceId != null ? { practiceId: reviewerUser.practiceId, name: reviewerUser.practiceName } : defaultPractice;

  form[fieldNames.startDate] = firstDayOfTheYear();
  form[fieldNames.endDate] = defaultEndDate();
  form[fieldNames.practice] = newDefaultPractice;
  form[fieldNames.competency] = defaultCompetency;
  form[fieldNames.consultantLevel] = defaultConsultantLevel;
  form[fieldNames.reviewer] = defaultReviewer ? reviewerUser : defaultOnliner;
  form[fieldNames.initiator] = defaultInitiator ? initiatorUser : defaultOnliner;
  form[fieldNames.serviceLine] = defaultServiceLine;
  form[fieldNames.statusType] = defaultReviewer ? defStatusType : defaultFilterStatusType;
  form[fieldNames.fiscalYear] = defaultFiscalYear;
  return form;
};
const defaultForm = (defaultReviewer: boolean, defaultInitiator: boolean, reviewerUser: Employee, initiatorUser: Employee, defStatusType: FilterStatusType, fiscalYear: number) => {
  const reset = resetFormValues(defaultReviewer, defaultInitiator, reviewerUser, initiatorUser, defStatusType, fiscalYear);
  const form: { [key: string]: any } = {
    [fieldNames.startDate]: [reset.startDate],
    [fieldNames.startDate]: [reset.startDate],
    [fieldNames.endDate]: [reset.endDate],
    [fieldNames.practice]: [reset.practice],
    [fieldNames.competency]: [reset.competency],
    [fieldNames.consultantLevel]: [reset.consultantLevel],
    [fieldNames.reviewer]: [reset.reviewer],
    [fieldNames.initiator]: [reset.initiator],
    [fieldNames.serviceLine]: [reset.serviceLine],
    [fieldNames.statusType]: [reset.statusType],
    [fieldNames.fiscalYear]: [reset.fiscalYear]
  };
  return form;
};

// prettier-ignore
export class FilterFormGroup {
  constructor(public form: UntypedFormGroup) { }

  get startDateControl() { return this.form.controls[fieldNames.startDate]; }
  get startDateError() { return this.startDateControl.errors && this.startDateControl.touched; }
  get startDatePatternError() { return this.startDateControl.hasError('datePatternError') && this.startDateControl.dirty; }
  get startDateInvalidError() { return this.startDateControl.hasError('invalidStartDate') && this.startDateControl.touched; }
  get startDateOrDefault(): Date { return this.startDateControl && this.startDateControl.value ? this.startDateControl.value : firstDayOfTheYear(); }

  get endDateControl() { return this.form.controls[fieldNames.endDate]; }
  get endDateError() { return this.startDateControl.errors && this.startDateControl.touched; }
  get endDatePatternError() { return this.startDateControl.hasError('datePatternError') && this.startDateControl.dirty; }
  get endDateInvalidError() { return this.startDateControl.hasError('invalidEndDate') && this.startDateControl.touched; }
  get endDateOrDefault(): Date { return this.endDateControl && this.endDateControl.value ? this.endDateControl.value : defaultEndDate(); }

  get practiceControl() { return this.form.controls[fieldNames.practice]; }
  get practiceError() { return this.practiceControl.hasError('required') && this.practiceControl.touched; }
  get practiceOrDefault(): Practice { return this.practiceControl && this.practiceControl.value ? this.practiceControl.value : defaultPractice; }

  get primaryCompetencyControl() { return this.form.controls[fieldNames.competency]; }
  get primaryCompetencyError() { return this.primaryCompetencyControl.hasError('required') && this.primaryCompetencyControl.touched; }
  get primaryCompetencyOrDefault(): PrimaryCompetency { return this.primaryCompetencyControl && this.primaryCompetencyControl.value ? this.primaryCompetencyControl.value : defaultCompetency; }

  get consultantLevelControl() { return this.form.controls[fieldNames.consultantLevel]; }
  get consultantLevelError() { return this.consultantLevelControl.hasError('required') && this.consultantLevelControl.touched; }
  get consultantLevelOrDefault(): ConsultantLevel { return this.consultantLevelControl && this.consultantLevelControl.value ? this.consultantLevelControl.value : defaultConsultantLevel; }

  get reviewerControl() { return this.form.controls[fieldNames.reviewer]; }
  get reviewersError() { return this.reviewerControl.hasError('required') && this.reviewerControl.touched; }
  get reviewerOrDefault(): Employee { return this.reviewerControl && this.reviewerControl.value ? this.reviewerControl.value : defaultOnliner; }

  get InitiatorControl() { return this.form.controls[fieldNames.initiator]; }
  get InitiatorsError() { return this.InitiatorControl.hasError('required') && this.InitiatorControl.touched; }
  get InitiatorOrDefault(): Employee { return this.InitiatorControl && this.InitiatorControl.value ? this.InitiatorControl.value : defaultOnliner; }

  get serviceControl() { return this.form.controls[fieldNames.serviceLine]; }
  get serviceLineError() { return this.serviceControl.hasError('required') && this.serviceControl.touched; }
  get serviceLineOrDefault(): ServiceLine { return this.serviceControl && this.serviceControl.value ? this.serviceControl.value : defaultServiceLine; }

  get statusTypeControl() { return this.form.controls[fieldNames.statusType]; }
  get statusTypeError() { return this.statusTypeControl.hasError('required') && this.statusTypeControl.touched; }
  get statusTypeOrDefault(): StatusType { return this.statusTypeControl && this.statusTypeControl.value ? this.statusTypeControl.value : defaultFilterStatusType; }

  get fiscalYearControl() { return this.form.controls[fieldNames.fiscalYear]; }
  get fiscalYearError() { return this.fiscalYearControl.hasError('required') && this.fiscalYearControl.touched; }
  get fiscalYearOrDefault(): number { return this.fiscalYearControl && this.fiscalYearControl.value ? this.fiscalYearControl.value : defaultFiscalYear; }

  getFilterCriteria(defaultReviewer: boolean, defaultIntiator: boolean, reviewerUserId: string, initiatorUserId: string): CompensationFilterCriteria {
    const filterCriteria = {
      startDate: this.startDateOrDefault,
      endDate: this.endDateOrDefault,
      practiceId: this.practiceOrDefault.practiceId,
      practice: this.practiceOrDefault.name,
      consultantLevelId: this.consultantLevelOrDefault.consultantLevelId,
      primaryCompetencyId: this.primaryCompetencyOrDefault.competencyId,
      primaryCompetency: this.primaryCompetencyOrDefault.name,
      reviewerId: defaultReviewer ? reviewerUserId : this.reviewerOrDefault.userId,
      revieweraccountingUserId: defaultReviewer ? reviewerUserId : this.reviewerOrDefault.accountingUserId,
      initiatorId: defaultIntiator ? initiatorUserId : this.InitiatorOrDefault.accountingUserId,
      serviceLineId: this.serviceLineOrDefault.serviceLineId,
      serviceLine: this.serviceLineOrDefault.name,
      statusTypeId: this.statusTypeOrDefault.statusTypeId,
      fiscalYear: this.fiscalYearOrDefault
    };

    return filterCriteria;
  }
}
