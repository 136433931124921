import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../core/services/navigation.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(public ns: NavigationService, private router: Router) { }
  showMenuItems = this.ns.showMenu;
  ngOnInit() {
    if (!this.showMenuItems) {
      const interaval = interval(500).subscribe(() => {
        this.showMenuItems = this.ns.showMenu;
        if (this.showMenuItems) interaval.unsubscribe();
      });
    }
  }

  isChildActive(url: string) {
    return this.router.isActive(url, false);
  }
}
