import { AuthService } from './../core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { PrintService } from '../core/services/print.service';
import { Subscription, timer } from 'rxjs';
import { CompensationPrintForm, PrintComments, PrintAudits } from '../core/models/compensationPrintForm.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compensation-form-print',
  templateUrl: './compensation-form-print.component.html',
  styleUrls: ['./compensation-form-print.component.scss']
})
export class CompensationFormPrintComponent implements OnInit {

  subscriptions: Subscription[] = [];

  onlinerName: string = "";
  reviewerName: string;
  formStatus: string;
  anniversary: Date;
  startDate: Date;
  compEffectiveDate: Date;
  previousCompEffectiveDate: Date;
  previousToLastCompensation: number;
  previousCompensation: number;
  newCompensation: number;
  compensationIncrease: number;
  percentageIncrease: string;
  vacationEffectiveDate: Date;
  previousVacationEffectiveDate: Date;
  previousToLastVacation: string;
  previousVacation: string;
  newVacation: string;
  vacationDollars: string;
  comments: PrintComments[];
  audits: PrintAudits[];
  appURL: string = environment.postLogoutRedirect;
  printBy: string;
  printDate: Date = new Date();


  constructor(private printService: PrintService, private authService: AuthService) { }

  ngOnInit() {
    this.subscriptions.push(this.printService.buildPrintForm$.subscribe(printData => this.buildForm(printData)));
  }

  private buildForm(printData: CompensationPrintForm) {
    this.onlinerName = printData.onlinerName
    this.reviewerName = printData.reviewerName
    this.formStatus = printData.statusDescription
    this.anniversary = printData.anniversaryDate;
    this.startDate = printData.startDate;
    this.compEffectiveDate = printData.compensationEffectiveDate;
    this.previousCompensation = printData.previousCompensation;
    this.newCompensation = printData.newCompensation;
    this.compensationIncrease = printData.newCompensation - printData.previousCompensation;
    this.percentageIncrease = this.getIncrease(printData.newCompensation, printData.previousCompensation);
    this.vacationEffectiveDate = printData.vacationEffectiveDate;
    this.previousVacation = printData.previousVacation + " weeks/year";
    this.newVacation = printData.newVacation + " weeks/year";
    this.comments = printData.commentsAndAudits.comments;
    this.audits = printData.commentsAndAudits.audits;
    this.printBy = this.authService.getUserId().toLowerCase();
  }

  formatComment = (comment: string) => comment.replace(/\n/g, `<br>`);

  getIncrease(newComp: number, oldComp: number): string {
    const diff = (newComp - oldComp);
    const percentageIncrease = oldComp > 0 ? ((diff / oldComp) * 100).toFixed(2) : '~';
    return `(${percentageIncrease}%)`;
  }

}
