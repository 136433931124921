import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class CommentDialog {
  title: string;
  message: string;
  hint: string;
  reasons: string;
  okButtonTitle: string;
  cancelButtonTitle: string;
  theme: string;
}

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent implements OnInit {

  form: UntypedFormGroup;

  rejectionReasonsDialog: CommentDialog;

  constructor(private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommentDialog) {

    this.rejectionReasonsDialog = data;

    if (!this.rejectionReasonsDialog.theme || this.rejectionReasonsDialog.theme === '') {
      this.rejectionReasonsDialog.theme = 'success';
    }

    this.form = fb.group({
      reasons: [data.reasons, Validators.required]
    });
  }

  ngOnInit() {
  }

  submit() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}

