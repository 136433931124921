import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthorizedHttp } from './authorized-http.service';
import { FilterFormGroup } from './compensation-filter.service';
import {
  DraftSummary,
  ConsultantLevel,
  Practice,
  PrimaryCompetency,
  ServiceLine,
  StatusType,
  FilterStatusType,
  DraftsView,
} from '../models/draftSummary.model';
import {
  CompensationReview,
  CompensationReviewHistory,
  VacationReviewHistory,
  CompensationReviewUpdate,
  ReviewCommentsAndAudits,
} from '../models/compensationReview.model';
import { HttpErrorResponse } from '@angular/common/http';
import { BatchGenerationResult } from '../models/batchGenerationResult.model';
import { WorkflowSummary } from '../models/workflowSummary.model';
import { AnnualSalaryHistory, AnnualVacationHistory, Employee } from '../models/employee.model';
import { StatusTypeEnum } from '../models/enums.model';
import { APIError } from '../models/error.model';
import { CompensationReviewStatusType } from '../models/compensationPrintForm.model';
import { WorkflowPageViewSummary } from '../models/workflowPageViewSummary.model';
import { ProcessedFormsView } from '../models/processedFormsView.model';

@Injectable({
  providedIn: 'root',
})
export class CompensationService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/compensation`;

  private _compensationDrafts: DraftSummary[];
  private _compensationApprovalForms: FilterFormGroup[];
  private _compensationWorkflows: WorkflowSummary[];

  private _drafts: DraftsView[];
  private _processedFormsView: ProcessedFormsView[];

  private onlinerUpdatedSource = new BehaviorSubject<string>('');
  public onlinerUpdated$ = this.onlinerUpdatedSource.asObservable();
  public onlinerUpdated = (onliner: string) => this.onlinerUpdatedSource.next(onliner);

  private reviewAddedSource = new Subject<CompensationReview>();
  public reviewAdded$ = this.reviewAddedSource.asObservable();
  public reviewAdded = (review: CompensationReview) => this.reviewAddedSource.next(review);

  private printFormSource = new Subject();
  public printForm$ = this.printFormSource.asObservable();
  public printForm = (num: number) => this.printFormSource.next(num);

  private massInitiatedDraftsUpdateSource = new Subject<DraftSummary[]>();
  public massInitiatedDraftsUpdate$ = this.massInitiatedDraftsUpdateSource.asObservable();
  public massInitiatedDraftsUpdate = (updatedReviews: DraftSummary[], statusTypes: CompensationReviewStatusType[]) => {
    const ignoreCache = false;

    if (updatedReviews) {
      this.getCompensationWorkflows(ignoreCache).subscribe((data) => {
        this.getCompensationDrafts(ignoreCache).subscribe((data) => {
          this.applyDraftReviewUpdates(updatedReviews, statusTypes);
        });
      });
    } else {
      this.massInitiatedDraftsUpdateSource.next(updatedReviews as DraftSummary[]);
    }
  };

  private reviewUpdatedSource = new Subject<DraftSummary>();
  public reviewUpdated$ = this.reviewUpdatedSource.asObservable();
  public reviewUpdated = (updatedReview: DraftSummary | CompensationReview, authorizedForDrafts: boolean) => {
    const ignoreCache = false;

    if (updatedReview) {
      const review = (updatedReview as CompensationReview).approverEmployeeId
        ? this.convertToDraftSummary(updatedReview as CompensationReview)
        : (updatedReview as DraftSummary);
      this.getCompensationWorkflows(ignoreCache).subscribe((data) => {
        const workflowsIX = this._compensationWorkflows.findIndex(
          (r) => r.compensationReviewId == review.compensationReviewId
        );

        if (authorizedForDrafts) {
          this.getCompensationDrafts(ignoreCache).subscribe((data) => {
            const draftsIX = this._compensationDrafts.findIndex(
              (r) => r.compensationReviewId == review.compensationReviewId
            );
            this.applyReviewUpdate(draftsIX, workflowsIX, review);
          });
        } else {
          this.applyReviewUpdate(-1, workflowsIX, review);
        }
      });
    } else {
      this.reviewUpdatedSource.next(updatedReview as DraftSummary);
    }
  };

  applyReviewUpdate(draftsIX: number, workflowsIX: number, review: DraftSummary) {
    if (draftsIX >= 0) {
      this.applyCommonReviewChanges(review, draftsIX, workflowsIX);

      if (review.statusTypeId == StatusTypeEnum.Initiated) {
        var spliced = this._compensationDrafts.splice(draftsIX, 1);
        this._compensationDrafts = [...this._compensationDrafts];
        spliced[0].statusTypeId = review.statusTypeId;
        spliced[0].statusType = review.statusType;
        if (workflowsIX < 0) {
          this._compensationWorkflows.push(spliced[0]);
        }
      }
    } else if (workflowsIX >= 0) {
      if (
        review.statusTypeId == StatusTypeEnum.Initiated ||
        review.statusTypeId == StatusTypeEnum.Reviewed ||
        review.statusTypeId == StatusTypeEnum.Acknowledged
      ) {
        this.applyCommonReviewChanges(review, draftsIX, workflowsIX);
        this._compensationWorkflows[workflowsIX].statusType = review.statusType;
        this._compensationWorkflows[workflowsIX].statusTypeId = review.statusTypeId;
      } else if (review.statusTypeId == StatusTypeEnum.Processed || review.statusTypeId == StatusTypeEnum.Rejected) {
        this._compensationWorkflows.splice(workflowsIX, 1);
        this._compensationWorkflows = [...this._compensationWorkflows];
      }
    } else {
      if (
        review.statusTypeId == StatusTypeEnum.Initiated ||
        (review.isForce0 && review.statusTypeId == StatusTypeEnum.Acknowledged)
      ) {
        this._compensationWorkflows.push(review);
      }
    }

    this.reviewUpdatedSource.next(review);
  }

  applyDraftReviewUpdates(reviews: DraftSummary[], statusTypes: CompensationReviewStatusType[]) {
    let compDraftIX = -1;

    if (reviews.length > 0) {
      for (let review of reviews) {
        compDraftIX = this._compensationDrafts.findIndex(
          (draft) => draft.compensationReviewId == review.compensationReviewId
        );
        this.applyCommonReviewChanges(review, compDraftIX, 0);

        var spliced = this._compensationDrafts.splice(compDraftIX, 1);
        this._compensationDrafts = [...this._compensationDrafts];
        spliced[0].statusTypeId = StatusTypeEnum.Initiated;
        spliced[0].statusType = statusTypes.find((stat) => stat.statusTypeId == StatusTypeEnum.Initiated);
        this._compensationWorkflows.push(spliced[0]);
      }
    }

    this.massInitiatedDraftsUpdateSource.next(reviews);
  }

  convertToDraftSummary(review: CompensationReview): DraftSummary {
    const draftSummary = {
      ...review,
      createdDate: review.status.sort((c) => c.statusTypeId).shift().statusDate,
      consultantLevel: review.onlinerEmployee.consultantLevel,
      primaryCompetency: review.onlinerEmployee.primaryCompetency,
      serviceLine: review.onlinerEmployee.serviceLine,
    } as DraftSummary;
    return draftSummary;
  }

  constructor(private http: AuthorizedHttp) { }

  public applyCommonReviewChanges(review: DraftSummary, draftsIX: number, workflowsIX: number) {
    var useDrafts = draftsIX >= 0;
    var reviewToModify = useDrafts ? this._compensationDrafts[draftsIX] : this._compensationWorkflows[workflowsIX];

    reviewToModify.reviewerEmployee = review.reviewerEmployee;
    reviewToModify.reviewerEmployeeId = review.reviewerEmployeeId;
    reviewToModify.bccAll = review.bccAll;
    reviewToModify.bccAllName = review.bccAllName;
    reviewToModify.status = review.status;

    if (review.history.length > 0) {
      reviewToModify.history
        ? reviewToModify.history[0] = review.history[0]
        : (reviewToModify.history = [],
          reviewToModify.history[0] = review.history[0]);
    }

    if (review.vacationHistory.length > 0) {
      reviewToModify.vacationHistory
        ? reviewToModify.vacationHistory[0] = review.vacationHistory[0]
        : (reviewToModify.vacationHistory = [],
          reviewToModify.vacationHistory[0] = review.vacationHistory[0]);
      ;
    }

    if (useDrafts) this._compensationDrafts[draftsIX] = reviewToModify;
    else this._compensationWorkflows[workflowsIX] = reviewToModify;
  }

  public removeForce0 = (removedReview: DraftSummary | CompensationReview) => {
    const ignoreCache = false;
    this.getCompensationWorkflows(ignoreCache).subscribe((data) => {
      const workflowsIX = this._compensationWorkflows.findIndex(
        (r) => r.compensationReviewId == removedReview.compensationReviewId
      );
      if (removedReview.isForce0 && removedReview.statusTypeId == StatusTypeEnum.Acknowledged) {
        this._compensationWorkflows.splice(workflowsIX, 1);
        this._compensationWorkflows = [...this._compensationWorkflows];
      }
    });
  };

  public getCompensationDrafts(ignoreCache: boolean): Observable<DraftSummary[]> {
    const useCache = this._compensationDrafts && !ignoreCache;
    return useCache
      ? of(this._compensationDrafts)
      : this.http.get(`${this._apiEndpoint}/drafts`).pipe(
        map((result) => {
          this._compensationDrafts = result;
          return result;
        }),
        catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get compensation drafts'))
      );
  }

  public getDrafts(): Observable<DraftsView[]> {
    return this._drafts
      ? of(this._drafts)
      : this.http.get(`${this._apiEndpoint}/drafts`).pipe(
        map((result) => result),
        catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get compensation drafts'))
      );
  }

  public getCompensationWorkflows(ignoreCache: boolean): Observable<WorkflowPageViewSummary[]> {
    return this.http.get(`${this._apiEndpoint}/workflows`).pipe(
        map((result) => {
          this._compensationWorkflows = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error || 'Could not get active compensation reviews')
        )
      );
  }

  public getCompensationWorkflowsDetails(ignoreCache: boolean): Observable<WorkflowSummary[]> {
    return this.http.get(`${this._apiEndpoint}/workflowsDetail`).pipe(
        map((result) => {
          this._compensationWorkflows = result;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error || 'Could not get active compensation reviews')
        )
      );
  }

  public getAllProcessedForms(fiscalYear: string): Observable<ProcessedFormsView[]> {
    return this.http.get(`${this._apiEndpoint}/processedForms/${fiscalYear}`).pipe(
      map((result) => {
        this._processedFormsView = result;
        return result;
      }),
        catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get Processed Forms'))
      );
  }

  public getDraftCount(): Observable<number> {
    return this.http
      .get(`${this._apiEndpoint}/draftCount`)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get draft count')));
  }

  public getWorkflowCount(): Observable<number> {
    return this.http
      .get(`${this._apiEndpoint}/workflowCount`)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get workflow count')));
  }

  public getMostRecentProcessedReview(onlinerId: string): Observable<DraftSummary> {
    onlinerId = this.http.encrypt(onlinerId);
    return this.http
      .get(`${this._apiEndpoint}/mostRecentProcessed/${onlinerId}`)
      .pipe(
        catchError((errorResponse: any) =>
          throwError(errorResponse.error || 'Could not get most recent processed Form for the onliner ' + onlinerId)
        )
      );
  }

  public getOnlinerCompensationReviews(
    onlinerId: string,
    onlyClosedReviews: boolean
  ): Observable<CompensationReview[]> {
    onlinerId = this.http.encrypt(onlinerId);
    return this._compensationApprovalForms
      ? of(this._compensationApprovalForms)
      : this.http
        .get(`${this._apiEndpoint}/onlinerCompensationReviews/${onlinerId}&${onlyClosedReviews}`)
        .pipe(
          catchError((errorResponse: any) =>
            throwError(errorResponse.error || 'Could not get compensation Reviews for the onliner ' + onlinerId)
          )
        );
  }

  public getFormReviewsById(reviewId: number): Observable<CompensationReview[]> {
    return this._compensationApprovalForms
      ? of(this._compensationApprovalForms)
      : this.http
        .get(`${this._apiEndpoint}/onlinerCompensationForm/${reviewId}`)
        .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get form Reviews')));
  }

  public getOnlinerCompensationForm(onlinerId: string): Observable<CompensationReview[]> {
    onlinerId = this.http.encrypt(onlinerId);
    return this.http
      .get(`${this._apiEndpoint}/onlinerCompensationForm/${onlinerId}`)
      .pipe(
        catchError((errorResponse: any) =>
          throwError(errorResponse.error || 'Could not get compensation Form for the onliner ' + onlinerId)
        )
      );
  }

  public createNewReview(accountingUserId: string): Observable<CompensationReview> {
    accountingUserId = this.http.encrypt(accountingUserId);
    return this.http
      .post(`${this._apiEndpoint}/createNewReview`, `"${accountingUserId}"`)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not create new review')));
  }

  public createNewForceZeroReview(accountingUserId: string): Observable<CompensationReview> {
    accountingUserId = this.http.encrypt(accountingUserId);
    return this.http
      .post(`${this._apiEndpoint}/createNewForceZeroReview`, `"${accountingUserId}"`)
      .pipe(
        catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not create new force-zero form'))
      );
  }

  public createBatchReview(batchGenerationResult: BatchGenerationResult): Observable<BatchGenerationResult> {
    return this.http
      .post(`${this._apiEndpoint}/createBatchReview`, batchGenerationResult)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not create batch reviews')));
  }

  public getBatchDrafts(): Observable<number> {
    return this.http
      .get(`${this._apiEndpoint}/getBatchDrafts`)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse.error || 'Could not get batch drafts')));
  }

  public isSameReviewData(data1: CompensationReviewUpdate, data2: CompensationReviewUpdate) {
    return (
      +data1.reviewId === +data2.reviewId &&
      data1.onlinerEmployeeId === data2.onlinerEmployeeId &&
      data1.reviewerEmployeeId === data2.reviewerEmployeeId &&
      data1.history.effectiveDate === data2.history.effectiveDate &&
      +data1.history.amount === +data2.history.amount &&
      data1.vacationHistory.effectiveDate === data2.vacationHistory.effectiveDate &&
      +data1.vacationHistory.days === +data2.vacationHistory.days &&
      data1.bccAll === data2.bccAll &&
      `${data1.comment}`.trim() === `${data2.comment}`.trim()
    );
  }

  public getWorkflowsDetail(onlinerId: string, rvwId: number): Observable<CompensationReview[]> {
    return this._compensationApprovalForms
      ? of(this._compensationApprovalForms)
      : this.http
        .get(`${this._apiEndpoint}/getWorkflowsDetail/${onlinerId}&${rvwId}`)
        .pipe(
          catchError((errorResponse: any) =>
            throwError(errorResponse.error || 'Could not get compensation Reviews for the onliner ' + onlinerId)
          )
        );
  }

  public saveReview(reviewData: CompensationReviewUpdate): Observable<DraftSummary> {
    return this.http
      .post(`${this._apiEndpoint}/saveReview`, reviewData)
      .pipe(catchError((errorResponse: APIError) => throwError(errorResponse)));
  }

  public updateReview(nextStatusData: CompensationReviewUpdate): Observable<DraftSummary> {
    return this.http
      .post(`${this._apiEndpoint}/updateReview`, nextStatusData)
      .pipe(catchError((errorResponse: any) => throwError(errorResponse || 'Could not update review')));
  }

  public massInitiate(draftsToInitiate: DraftSummary[]): Observable<any> {
    return this.http
      .post(`${this._apiEndpoint}/massInitiate`, draftsToInitiate)
      .pipe(
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.ErrorMessage || 'Could not mass initiate reviews')
        )
      );
  }

  public rejectReview(rejectData: CompensationReviewUpdate): Observable<DraftSummary> {
    return this.http
      .post(`${this._apiEndpoint}/rejectReview`, rejectData)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => throwError(errorResponse.error || 'Could not reject review'))
      );
  }

  public removeDraft(compensation: CompensationReview | DraftSummary): Observable<CompensationReview> {
    return this.http
      .post(`${this._apiEndpoint}/removeDraft`, compensation)
      .pipe(catchError((errorResponse: APIError) => throwError(errorResponse)));
  }

  practiceIdFormat = (option?: Practice): string | undefined => (option ? `${option.practiceId}`.trim() : undefined);

  serviceLineIdFormat = (option?: ServiceLine): string | undefined =>
    option ? `${option.serviceLineId}`.trim() : undefined;

  competencyIdFormat = (option?: PrimaryCompetency): string | undefined =>
    option ? `${option.competencyId}`.trim() : undefined;
}
export const defaultHistory = { amount: 0 } as CompensationReviewHistory;
export const defaultVacayHistory = { days: 0, effectiveDate: undefined } as VacationReviewHistory;
export const defaultAnnualSalaryHistory = {
  salaryHistoryId: -1,
  employeeId: '',
  ModifiedDate: new Date('January 1st, 1970'),
  amount: 0,
  effectiveDate: new Date('January 1st, 1970'),
  currencyId: -1,
} as AnnualSalaryHistory;
export const defaultAnnualVacationHistory = {
  vacationHistoryId: -1,
  employeeId: '',
  daysAmount: 0,
  effectiveDate: new Date('January 1st, 1970'),
  ModifiedDate: new Date('January 1st, 1970'),
} as AnnualVacationHistory;
export const defaultReview: CompensationReview = {
  history: [defaultHistory],
  vacationHistory: [defaultVacayHistory],
} as CompensationReview;

export const defaultPractice: Practice = { practiceId: 'ALL', name: 'All' };
export const defaultCompetency: PrimaryCompetency = { competencyId: -1, name: 'All' };
export const defaultConsultantLevel: ConsultantLevel = { consultantLevelId: -1, name: 'All' };
export const defaultServiceLine: ServiceLine = { serviceLineId: 'ALL', name: 'All', practiceId: 'All' };
export const defaultFilterStatusType: FilterStatusType = { statusTypeId: -1, name: 'All', sortOrder: -1 };
export const defaultStatusType: StatusType = { statusTypeId: -1, statusTypeName: 'All', sortOrder: -1 };
export const defaultFiscalYear: number = new Date().getFullYear();
