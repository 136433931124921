import { environment } from 'src/environments/environment';

export interface Role {
  admin: string;
  user: string;
  init: string;
  reviewer: string;
  payroll: string;
}

export const roles: Role = {
  admin: environment.roles.CompApprovAdmin,
  init: environment.roles.CompApprovInitiator,
  payroll: environment.roles.CompApprovPayroll,
  reviewer: environment.roles.CompApprovReviewer,
  user: environment.roles.CompApprovUser
};
