import { Employee } from './employee.model';
import { StatusType, DraftSummary } from './draftSummary.model';
import { CompensationReview, ReviewCommentsAndAudits } from './compensationReview.model';

export class CompensationPrintForm {
  compensationReviewId: number;
  statusDescription: string;
  anniversaryDate: Date;
  startDate: Date;
  onlinerName: string;
  reviewerName: string;
  compensationEffectiveDate: Date;
  previousCompensation: number;
  newCompensation: number;
  compensationIncrease: number;
  vacationEffectiveDate: Date;
  previousVacation: number;
  newVacation: number;
  vacationDollars: string;
  isForce0: boolean;
  commentsAndAudits: PrintCommentsAndAudits;
}

export class PrintCommentsAndAudits {
  comments: PrintComments[];
  audits: PrintAudits[];
}

export class PrintComments {
  commentDate: Date;
  commentEmployeeName: string;
  commentNote: string;
  commentTypeDescription: string;
}

export class PrintAudits {
  amount: string;
  amountEffectiveDate: Date;
  auditDate: number;
  reviewerName: string;
  updateEmployee: string;
  statusDescription: string;
  vacationDays: number;
  vacationDaysEffectiveDate: Date;
  info: string;
}

export class CompensationFormData {
  reviews: CompensationReview[];
  draftSummary: DraftSummary;
  closedStatusTypes: CompensationReviewStatusType[];
}

export interface CompensationReviewStatusType {
  statusTypeId: number;
  statusTypeName: string;
  sortOrder: number;
}

export interface CompensationReviewHistory {
  id: number;
  reviewId: number;
  effectiveDate: Date;
  amount: number;
}

export interface CompensationReviewComment {

  commentId: number;
  commentTypeId: number;
  reviewId: number;
  commentDate: Date;
  employeeId: string;
  commentNote: string;
  commentEmployee: Employee;
  commentType: CompensationReviewCommentType;

}

export interface CompensationReviewCommentType {
  commentTypeId: number;
  name: string;
  description: string;
  sortOrder: number;
}

export interface CompensationReviewStatus {
  statusId: number;
  reviewId: number;
  statusTypeId: number;
  statusDate: Date;
  statusEmployeeId: string;
  statusType: CompensationReviewStatusType;
}

export interface VacationReviewHistory {
  id: number;
  reviewId: number;
  effectiveDate: Date;
  days: number;
}
