import { Observable, of as observableOf } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { ConfirmationDialogComponent, ConfirmDialogData } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { first } from 'rxjs/operators';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {

    dialogConfirmation = new EventEmitter<boolean>();

    canDeactivate(component: ComponentCanDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> {
        return component.canDeactivate() ? observableOf(true) : this.openConfirmationDialog().pipe(first());
        // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
    }

    constructor(private dialog: MatDialog) { }

    openConfirmationDialog(): Observable<boolean> {

        return new Observable(observer => {
            this.showConfirmationDialog();
            this.dialogConfirmation.subscribe(result => {
                observer.next(result);
                observer.complete;
            });
        });

    }


    private showConfirmationDialog() {
        let confirmDialog = new ConfirmDialogData();
        confirmDialog.title = "Unsaved Changes";
        confirmDialog.message =
            `<p> There are currently unsaved changes that have not been committed. Are you sure you want to continue?</p>`;
        confirmDialog.okButtonTitle = "Continue";
        confirmDialog.cancelButtonTitle = "Cancel";
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: confirmDialog,
            disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
            this.dialogConfirmation.emit(result === 'ok');
        });
    }
}
