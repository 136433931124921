import { Directive, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][compensationCurrencyMask]'
})
export class CurrencyMaskDirective implements AfterViewInit {


  constructor(public ngControl: NgControl) {
  }
  ngAfterViewInit() {
    this.onInputChange();
  }


  @HostListener('input') onInputChange() {
    let newVal = `${this.ngControl.value}`.replace(/[^0-9.]/g, '') as string;
    newVal = newVal.replace(/^0+/, ''); /* remove leading zeroes */
    if (newVal === '') newVal = '0';
    this.ngControl.control.setValue(newVal);
    this.ngControl.valueAccessor.writeValue(`$${newVal}`);
  }
}
