export enum CommentTypeEnum {
  AdditionalInformation = 1,
  PeopleCareComments = 2,
  ReviewerComments = 3,
  ApproverComments = 4,
  OnlinerComments = 5,
  PayrollComments = 6,
  OtherComments = 7
}
export enum StatusTypeEnum {
  Initiated = 1,
  Reviewed = 2,
  Approved = 3,
  Acknowledged = 4,
  Processed = 5,
  Rejected = 6,
  SystemGenerated = 7,
  New = 8
}