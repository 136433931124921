<div class="component-container">
  <div class="col-md-3">
      <h3 class="page-title mb-3">History</h3>
    </div>

  <app-compensation-result-list 
    [onlyClosedReviews]="true" 
    [isSearching]="isSearching" 
    [clickable]="false"
    [isHistoryPage]="true"
    (doneSearch)="isSearching = false">
  </app-compensation-result-list>
</div>