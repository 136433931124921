import { Component, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';

import { AuthService } from './../core/services/auth.service';
import { CommonService } from './../core/services/common.service';
import { CompensationService } from './../core/services/compensation.service';
import { FilterFormGroup, CompensationFilterService } from '../core/services/compensation-filter.service';
import { SnackBarService } from '../core/services/snackbar.service';

import { DraftsView } from '../core/models/draftSummary.model';
import { CompensationReviewStatusType } from './../core/models/compensationReview.model';
import { environment } from './../../environments/environment';

import { Dialogs } from '../util/Dialogs';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss'],
})
export class DraftsComponent implements OnInit {
  isAdmin: boolean;
  drafts: DraftsView[];
  filteredDrafts: DraftsView[];
  filterCriteriaForm: FilterFormGroup;
  closedStatusTypes: CompensationReviewStatusType[];
  statusTypes: CompensationReviewStatusType[];
  subscriptions: Subscription[] = [];
  get numDraftsDisplay(): string {
    return this.filteredDrafts ? `(${this.filteredDrafts.filter((d) => d.compensationReviewId != 0).length})` : '';
  }
  filterPracticeValue: string;
  filterServiceValue: string;
  filterPrimaryCompetencyValue: string;
  filterReviewerValue: string;
  filterConsultantLevelValue: string;
  filtersValue: string;
  isLoading: boolean = true;
  isInitiator: boolean = false;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private compensationService: CompensationService,
    private filterService: CompensationFilterService,
    private snackBarService: SnackBarService,
    private dialogs: Dialogs
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(false, false);
    this.isAdmin = this.authService.doesUserHaveRole([environment.roles.CompApprovAdmin]);
    this.isInitiator = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.subscriptions.push(
      this.compensationService.reviewUpdated$.subscribe((result) => {
        if (result) {
          this.getCompensationDrafts();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getCompensationDrafts() {
    forkJoin([this.commonService.getAllCommonData(), this.compensationService.getDrafts()]).subscribe(
      ([commonData, drafts]) => {
        this.closedStatusTypes = commonData.closedStatusTypes;
        this.statusTypes = commonData.statusTypes;
        this.drafts = drafts;
        this.filteredDrafts = drafts;
        this.filterDrafts();
      },
      (error) => {
        this.dialogs.getErrorDialog(error);
        this.drafts = [];
        this.filteredDrafts = [];
        // this.snackBarService.error(error);
      }
    );
  }

  filterDrafts() {
    const filterCriteriaModel = this.filterService.getFilterCriteriaModel(false, false);
    if (this.drafts) {
      this.filteredDrafts = this.drafts.filter(
        (f) =>
          this.filterService.filterByPractice(f, filterCriteriaModel) &&
          this.filterService.filterByConsultantLevel(f, filterCriteriaModel) &&
          this.filterService.filterByCompetency(f, filterCriteriaModel) &&
          this.filterService.filterByReviewer(f, filterCriteriaModel) &&
          this.filterService.filterByServiceLine(f, filterCriteriaModel)
      );
      this.getFilterFormValues();
    }
    this.isLoading = false;
  }

  updateDraftNum(draft: DraftsView) {
    const draftIx = this.drafts.findIndex((d) => d.compensationReviewId === draft.compensationReviewId);
    this.drafts.splice(draftIx, 1);
    this.drafts = [...this.drafts];
    this.filterDrafts();
  }

  updateDraftNums(drafts: DraftsView[]) {
    let draftIx = -1;

    drafts.forEach((draft) => {
      draftIx = this.drafts.findIndex((d) => d.compensationReviewId === draft.compensationReviewId);
      this.drafts.splice(draftIx, 1);
      this.drafts = [...this.drafts];
    });

    this.filterDrafts();
  }

  getFilterFormValues() {
    this.filterPracticeValue = this.filterCriteriaForm.form.controls.practice.value['name'];
    this.filterServiceValue = this.filterCriteriaForm.form.controls.serviceLine.value['name'];
    this.filterPrimaryCompetencyValue = this.filterCriteriaForm.form.controls.competency.value['name'];
    this.filterReviewerValue = this.filterCriteriaForm.form.controls.reviewer.value['fullName'];
    this.filterConsultantLevelValue = this.filterCriteriaForm.form.controls.consultantLevel.value['name'];
    this.filtersValue =
      this.filterPracticeValue +
      ', ' +
      this.filterServiceValue +
      ', ' +
      this.filterPrimaryCompetencyValue +
      ', ' +
      this.filterReviewerValue +
      ', ' +
      this.filterConsultantLevelValue;
  }
}
