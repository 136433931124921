import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';

import { Observable, forkJoin } from 'rxjs';

import { AutoSuggestService } from '../../core/services/auto-suggest.service';
import { OnlinerService } from '../../core/services/onliner.service';
import { SnackBarService } from '../../core/services/snackbar.service';
import { Employee } from '../../core/models/employee.model';
import { SalaryReviewerApprover } from '../../core/models/salaryReviewerApprover.model';

import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import * as formValidator from '../../shared/form-validator';

@Component({
  selector: 'app-assign-reviewer',
  templateUrl: './assign-reviewer.component.html',
  styleUrls: ['./assign-reviewer.component.scss']
})
export class AssignReviewerComponent implements OnInit {
  assignReviewerForm: UntypedFormGroup;

  onliners: Employee[];
  reviewers: Employee[];
  onlinerReviewers: SalaryReviewerApprover[];

  filteredOnliners: Observable<Employee[]>;
  filteredReviewers: Observable<Employee[]>;

  isLoading: boolean = true;
  isSearching: boolean;

  selectedOnliner: Employee;
  selectedReviewer: Employee;

  get onlinerControl() { return this.assignReviewerForm.controls['onliner']; };
  get reviewerControl() { return this.assignReviewerForm.controls['reviewer']; };

  constructor(
    private autoSuggestService: AutoSuggestService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.onliners = undefined;
    this.reviewers = undefined;
    this.onlinerReviewers = undefined;
    //this.assignReviewerForm = undefined;
    this.setupPage();
  }

  public reviewerLabel: any;

  private setupPage() {
    this.selectedOnliner = undefined;
    this.selectedReviewer = undefined;

    this.isLoading = true;

    forkJoin([this.onlinerService.getOnliners(), this.onlinerService.getReviewers(), this.onlinerService.getOnlinersOfReviewer()])
      .subscribe(([onliners, reviewers, onlinerReviewers]) => {
        this.onliners = onliners.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
        this.reviewers = reviewers.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
        this.onlinerReviewers = onlinerReviewers.sort((a, b) => `${a.reviewerUserId} ${a.onlinerUserId}`.localeCompare(`${b.reviewerUserId} ${b.onlinerUserId}`));

        this.assignReviewerForm = this.createForm();

        this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(this.onlinerControl, this.onliners);
        this.filteredReviewers = this.autoSuggestService.setOnlinerFilter(this.reviewerControl, this.reviewers);
        this.isLoading = false;
      },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        });
  }

  private createForm() {
    return this.formBuilder.group({
      'reviewer': ['', formValidator.validateOnliner()],
      'onliner': ['', formValidator.validateOnliner()]
    });
  }

  onlinerDisplay = (option?: Employee): string | undefined => this.autoSuggestService.onlinerDisplay(option);

  onlinerSelected({ option: { value } }: MatAutocompleteSelectedEvent) {
    this.selectedOnliner = value;

    const selectedOnlinerReviewer = this.onlinerReviewers.find(r => r.onlinerUserId == this.selectedOnliner.userId);
    if (selectedOnlinerReviewer === undefined || value === undefined) {
      this.reviewerLabel = null;
      this.reviewerLabel = '';
    } else {
      const wantedReviewer = this.reviewers.find(o => o.userId == selectedOnlinerReviewer.reviewerUserId);
      this.reviewerLabel = `Default Reviewer: ${wantedReviewer.firstName} ${wantedReviewer.lastName}`;
    }
  }

  reviewerDisplay = (option?: Employee): string | undefined => this.autoSuggestService.reviewerDisplay(option);

  reviewerSelected({ option: { value } }: MatAutocompleteSelectedEvent) {
    this.selectedReviewer = value;
  }

  assignReviewerOnliner() {
    const onliner = this.assignReviewerForm.controls.onliner.value as Employee;
    const reviewer = this.assignReviewerForm.controls.reviewer.value as Employee;

    if (onliner.userId === reviewer.userId) {
      this.assignReviewerDialog(reviewer.userId, onliner.userId, "The Onliner and Reviewer cannot be the same person");
      return;
    }

    const onlinerFullName = `${onliner.firstName} ${onliner.lastName}`;
    const reviewerFullName = `${reviewer.firstName} ${reviewer.lastName}`;

    this.isLoading = true;
    this.onlinerService.addSalaryReviewer(onliner.userId, reviewer.userId)
      .subscribe(data => {
        this.snackBarService.message(`${reviewerFullName} has been assigned as a Reviewer for ${onlinerFullName}`);
        this.reviewerLabel = `Default Reviewer: ${reviewerFullName}`;
        this.isLoading = false;
        this.assignReviewerForm.controls.reviewer.reset();
        this.getOnlinersOfReviewer();
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      })
  }

  getOnlinersOfReviewer(){
    this.isLoading = true;
    this.onlinerService.getOnlinersOfReviewer()
      .subscribe(data => {
        this.onlinerReviewers = data.sort((a, b) => `${a.reviewerUserId} ${a.onlinerUserId}`.localeCompare(`${b.reviewerUserId} ${b.onlinerUserId}`));
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      })
  }

  assignReviewerDialog(reviewer: string, onliner: string, message: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Assign Reviewers to Onliners',
        message: message,
        okButtonTitle: 'OK',
        theme: 'danger'
      },
      disableClose: true
    });
  }

  display(e: any) {
    console.log(e);
  }
}
