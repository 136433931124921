<mat-card appearance="outlined" class="dashboard-card mx-auto p-0" (click)="navigateToAddress()">
  <mat-card-content>
    <div class="mx-auto">
      <span><i class="fas fa-2x" [ngClass]="iconName" [ngStyle]="{'background-color': primaryColor}" ></i></span>
    </div>
    <div class="mx-auto mt-4">
      <h4 [ngStyle]="{'color': primaryColor}"><div>{{description}}</div><div>{{description2}}</div></h4>
    </div>
    <div class="mx-auto mt-2">
      <span class="badge-text">{{count}}</span>
    </div>
  </mat-card-content>
</mat-card>
