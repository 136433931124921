<header class="header">
  <mat-card appearance="outlined" class="pt-2 pb-2 pl-2 pr-0">
    <div class="row mr-0 ml-0">
      <div class="col-md-4 col-sm-4 col-xs-4 float-left">
        <div>
          <img src="assets/images/obs-logo.png" class="img-fluid" alt="Online Business Systems" />
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <div class="mx-auto p-1" style="display: table">
          <img src="assets/images/CompensationApproval-logo-602x50.png" style="width: 100%; height: auto; width:auto\9"
            class="img-fluid" alt="Compensation Approval App" />
        </div>
      </div>
      <div *ngIf="isAuthenticated" class="col-md-4 col-sm-4 col-xs-4 float-right pr-0 clearfix">
        <div class="btn-group float-right" ngbDropdown role="group" aria-label="user name">
          <button ngbDropdownToggle class="btn btn-link">
            <span class="mr-3 username" [matTooltip]="roles()" [matTooltipPosition]="'right'"
              matTooltipClass="custom-tooltip">{{ userName }}</span>
            <ng-template #noImageFound>
              <i class="fas fa-user"></i>
            </ng-template>
            <img [src]="profilePhotoData" class="img-fluid rounded-circle profilePhotoData"
              *ngIf="profilePhotoData; else noImageFound" alt="Signed in as {{ userName }}" />
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <div *ngIf="isAdmin">
              <button *ngFor="let item of selections" class="dropdown-item btn btn-link" (click)="openForm(item.id)">{{item.name}}</button>
            </div>
            <button class="dropdown-item btn btn-link" (click)="logout()">Log Off</button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</header>