<div class="row m-0 h-100">
  <div class="component-container flex-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter ({{ filtersValue }})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-compensation-filter-form
        [searchCriteriaForm]="filterCriteriaForm"
        [onWorkflowPage]="false"
        (filterTriggered)="filterDrafts()"
      >
        >
      </app-compensation-filter-form>
    </mat-expansion-panel>

    <h4 class="mb-3 mt-3 page-title">Drafts {{ numDraftsDisplay }}</h4>
    <app-draft-list
      class="flex-list mat-elevation-z8"
      [drafts]="filteredDrafts"
      [statusTypes]="statusTypes"
      [closedStatusTypes]="closedStatusTypes"
      [canMassInitiate]="isInitiator"
      (reloadList)="getCompensationDrafts()"
      (updateDraftNum)="updateDraftNum($event)"
      (updateDraftNums)="updateDraftNums($event)"
      [finishedUpdating]="isLoading"
    ></app-draft-list>
  </div>
</div>
