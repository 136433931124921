import { ConfirmationDialogComponent, ConfirmDialogData } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from "@angular/core";

@Injectable()
export class Dialogs {

    constructor(private dialog: MatDialog) { }

    public getErrorDialog(msg: string) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '500px',
            data: {
                title: 'Error',
                theme: 'danger',
                message: `<p>${msg}</p>`,
                cancelButtonTitle: 'OK',
            } as ConfirmDialogData,
            disableClose: true,
        });
    }

}
