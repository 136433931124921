import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredDialogComponent } from './shared/session-expired-dialog/session-expired-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {}
  // isAuthenticated : boolean = false;

  // mobileQuery: MediaQueryList;
  // private _mobileQueryListener: () => void;
  // showMainNav: boolean = false;

  // sub: Subscription;
  // sessionExpiredDialogIsOpened: boolean = false;

  // constructor(private authService: AuthService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,  private dialog: MatDialog){
  //   this.mobileQuery = media.matchMedia('(max-width: 600px)');
  //   this._mobileQueryListener = () => changeDetectorRef.detectChanges();
  //   this.mobileQuery.addListener(this._mobileQueryListener);
  // }

  // ngOnInit(): void {

  //   this.isAuthenticated = this.authService.isAuthenticated();

  //   if(!this.isAuthenticated){
  //     this.authService.login();
  //     this.isAuthenticated = this.authService.isAuthenticated();
  //   }

  //   this.sub = interval(30000)
  //   .subscribe((val) => {
  //     if(this.authService.isSessionExpired && !this.sessionExpiredDialogIsOpened ){
  //       this.showSessionExpiredDialog();
  //     }
  //    });
  // }

  // ngOnDestroy(): void {
  //   this.mobileQuery.removeListener(this._mobileQueryListener);
  //   this.sub.unsubscribe();
  // }

  // showSessionExpiredDialog(){
  //   this.sessionExpiredDialogIsOpened = true;
  //   let dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
  //       width: '400px',
  //       disableClose: true
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //       this.sessionExpiredDialogIsOpened = false;
  //   });
  // }



}
