import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
import { AuthService } from './../core/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reviewer',
  templateUrl: './reviewer.component.html',
  styleUrls: ['./reviewer.component.scss']
})

export class ReviewerComponent implements OnInit {
  isAdmin: boolean;
  selections = [{ "name": "Add Reviewer", id: "add" }, { "name": "Assign Reviewer", id: "assign" }];
  valueChosen: string;
  businessChoice: Observable<any>;
  newReviewerFormOption: any;

  constructor(
    private authService: AuthService
  ) { }
  ngOnInit() {
    this.isAdmin = this.authService.doesUserHaveRole([environment.roles.CompApprovAdmin]);
    this.newReviewerFormOption = "add";
  }
}
