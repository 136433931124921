import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';

import { AuthService } from './../core/services/auth.service';
import { AutoSuggestService } from '../core/services/auto-suggest.service';
import { CompensationService } from '../core/services/compensation.service';
import { OnlinerService } from '../core/services/onliner.service';
import { SnackBarService } from '../core/services/snackbar.service';

import { BatchGenerationResult } from './../core/models/batchGenerationResult.model';
import { BatchGenerationResultForCSV } from './../core/models/batchGenerationResult.model';
import { CompensationReview } from '../core/models/compensationReview.model';
import { Employee } from '../core/models/employee.model';

import * as formValidator from '../shared/form-validator';
import { ConfirmationDialogComponent, ConfirmDialogData } from './../shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { generateCsv, download } from "export-to-csv";
import { ColumnHeader } from 'export-to-csv/output/lib/types';
import { StatusTypeEnum } from '../core/models/enums.model';
import { DraftSummary } from '../core/models/draftSummary.model';

/*prettier-ignore*/
@Component({
  selector: 'app-onlinerform',
  templateUrl: './onlinerform.component.html',
  styleUrls: ['./onlinerform.component.scss']
})
export class OnlinerFormComponent implements OnInit, OnDestroy {
  searchForm: UntypedFormGroup = this.initSearchForm();
  onliners: Employee[];
  linkReviewId: number;
  linkOnliner: string;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  get onlinerControl() {
    return this.searchForm.controls['onliner'];
  }

  filteredOnliners: Observable<Employee[]>;
  currentYear = new Date().getFullYear();

  onlinerHasOpenReview: boolean = true;
  isLoading: boolean;
  isSearching: boolean;
  isCreating: boolean = false;
  generatingForms: boolean = false;
  generateResult: BatchGenerationResult;
  onliner: Employee;
  clicked: boolean = false;
  isForce0: boolean = false;
  isAuthorized: boolean;
  isChecking: boolean = false;
  totalFormsCreated: number = 0;
  loggedInUserId: string;
  isReviewer: boolean = false;

  private totalBatchFormsCreated = 0;
  workflowsCount: number;

  dataSource: MatTableDataSource<BatchGenerationResultForCSV>;
  errorList: BatchGenerationResultForCSV[];
  resultColumnHeaders: string[] = ['employeeWithError', 'reasonForError'];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private autoSuggestService: AutoSuggestService,
    private compensationService: CompensationService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.setupPage();
  }
  ngOnDestroy() {
    this.snackBarService.clean();
  }

  private initSearchForm() {
    return this.formBuilder.group({
      'onliner': ['', formValidator.validateOnliner()]
    });
  }

  private setupPage() {
    this.isLoading = true;
    this.isAuthorized = this.checkIfAuthorized();
    this.loggedInUserId = this.authService.getUserId().toLowerCase();
    if (this.authService.doesUserHaveRole([environment.roles.CompApprovReviewer]) && this.authService.doesUserHaveRole([environment.roles.CompApprovUser])
       && !this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]))
    {
      this.isReviewer = true;
    }
    this.onlinerService.getSearchOnlinersExcludingDefault(this.loggedInUserId, this.isReviewer).subscribe(
      onliners => {
        this.onliners = onliners;
        this.setOnlinerFilter();

        this.isLoading = false;
        this.isSearching = false;

        this.openLinkedReviewIfExists()
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  openLinkedReviewIfExists = () => {
    this.route.queryParamMap.subscribe(q => {
      this.linkReviewId = +q.get('reviewId');
      this.linkOnliner = q.get('onliner');
      if (this.linkOnliner && this.linkReviewId > 0) {
        const foundOnliner = this.onliners.find(o => o.accountingUserId === this.linkOnliner);
        if (foundOnliner) {
          this.onlinerControl.setValue(foundOnliner);
          setTimeout(() => {
            const option = this.matAutocomplete.options.find(o => (o.value as Employee).accountingUserId === this.linkOnliner);
            this.matAutocomplete.optionSelected.next(new MatAutocompleteSelectedEvent(this.matAutocomplete, option));
          }, 300)
        }
      }
    })
  }

  checkIfAuthorized(): boolean {
    let userHasRole = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    if (!userHasRole) {
      return false;
    }

    return true;
  }

  setOnlinerFilter() {
    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(this.onlinerControl, this.onliners);
  }

  onlinerSelected({ option: { value } }: MatAutocompleteSelectedEvent) {
    this.onliner = value;
    this.isForce0 = false;
    this.isSearching = true;

    this.compensationService.onlinerUpdated(this.onliner.accountingUserId);
  }

  onlinerDisplay = (option?: Employee): string | undefined => {
    return this.autoSuggestService.onlinerDisplay(option);
  };

  getOnlinerFromDropdown(employeeId: string) {
    return this.onliners ? (this.onliners.find(onliner => onliner.userId === employeeId) as Employee) : null;
  }

  createNewOnlinerForm = () => {
    this.isCreating = true;
    this.compensationService
      .createNewReview(this.onliner.accountingUserId)
      .subscribe((review: CompensationReview) => {
        this.compensationService.reviewAdded(review);
        this.isCreating = false;
        this.clicked = false;
      }, error => {
        this.snackBarService.error(error);
        this.isCreating = false;
        this.clicked = false;
      });
  }

  createForceZeroForm = () => {
    if (this.isForce0) {
      this.isChecking = true;
      this.compensationService
        .getMostRecentProcessedReview(this.onliner.accountingUserId)
        .subscribe((review: DraftSummary) => {

          const mostRecentProcessedReview = review;
          const decemberFirst = new Date(new Date().getFullYear(), 11, 1);
          const effectiveDate = (mostRecentProcessedReview != null && mostRecentProcessedReview.compensationReviewId != 0) ? new Date(mostRecentProcessedReview.onlinerEmployee.startDate) : new Date(new Date().getFullYear(), 11, 2);
          if (mostRecentProcessedReview != null && mostRecentProcessedReview.compensationReviewId != 0 && effectiveDate >= decemberFirst) {
            this.openDialog(
              {
                title: 'Invalid Date',
                message: 'Cannot create a force-0 form for this period because a previous form has been processed during the same period', okButtonTitle: 'OK', theme: 'danger'
              } as ConfirmDialogData
            );
            this.isForce0 = false;
            this.isChecking = false;
          } else {
            this.openDialog(
              {
                title: 'Force-Zero',
                message: 'Please confirm you wish to create a Force-Zero Compensation Review.',
                okButtonTitle: 'Confirm', cancelButtonTitle: 'Cancel', theme: 'danger'
              } as ConfirmDialogData,
              (result: boolean) => {
                if (!result) {
                  this.isChecking = false;
                  return;
                }
                this.isChecking = false;
                this.isCreating = true;
                this.compensationService
                  .createNewForceZeroReview(this.onliner.accountingUserId)
                  .subscribe((review: CompensationReview) => {
                    this.compensationService.reviewAdded(review);
                    this.isCreating = false;
                    this.clicked = false;
                  }, error => {
                    this.snackBarService.error(error);
                    this.isCreating = false;
                    this.clicked = false;
                  });
              }
            );
          }
        });
    }
  }

  openDialog(data: ConfirmDialogData, callBack: (result: boolean) => any = null) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      callBack(result === 'ok' ? true : false);
    });
  }

  /*
    checkForms() {
      forkJoin([this.compensationService.getDraftCount(), this.compensationService.getWorkflowCount(), this.onlinerService.getOnlinersExcludingDefault()])
      .subscribe(([numDrafts, numWorkflows, onliners]) => {
        var totalReviews = numDrafts + numWorkflows;
        (totalReviews === onliners.length) ? this.allFormsCreatedOrActive = true : this.allFormsCreatedOrActive = false;
      });
    }
  */

  generateBatchForms = (batchGenerationResult: BatchGenerationResult = null) => {
    this.isLoading = true;
    this.generatingForms = true;
    this.generateResult = undefined;

    this.compensationService
      .createBatchReview(batchGenerationResult)
      .subscribe((result: BatchGenerationResult) => {
        if (result.employeesRemaining.length > 0) {
          this.generateBatchForms(result);
        } else {
          this.completedBatchCreation(result);
          this.totalBatchFormsCreated = 100;
          this.generatingForms = false;
        }
      }, error => {
        this.snackBarService.error(error);
        this.isLoading = false;
        this.generatingForms = false;
      });

  }

  updateProgressBar() {
    this.compensationService.getWorkflowCount().subscribe(result => {
      this.workflowsCount = result;
      this.totalBatchFormsCreated = (this.workflowsCount / this.onliners.length) * 100;
      this.progressBarUpdate();
    });
  }

  completedBatchCreation(result: BatchGenerationResult) {
    this.generateResult = result;
    var intermediateErrorList: BatchGenerationResultForCSV[] = [];

    this.generateResult.errorList.forEach(x => {
      intermediateErrorList.push(x);
      intermediateErrorList = [...intermediateErrorList];
    });

    this.errorList = [...intermediateErrorList];
    this.dataSource = new MatTableDataSource<BatchGenerationResultForCSV>(this.errorList);

    this.isLoading = false;
    this.totalFormsCreated = result.successfulGenerations;
    this.snackBarService.message(`${this.totalFormsCreated} compensation forms created`);
  }

  displayErrorItem = (errorItemField: string) => {
    return errorItemField;
  };


  downloadCSV() {
    const generateCSV = () => {
      const data = this.errorList.map(x => ({
        employeeWithError: x.employeeWithError,
        reasonForError: x.reasonForError,
      }));

      const headerByKey: ColumnHeader[] = [
        {
          key: 'employeeWithError',
          displayLabel: 'Onliner',
        },
        {
          key: 'reasonForError',
          displayLabel: 'Reason for Error',
        },
      ];
      return { headerByKey, data };
    };

    const generated = generateCSV();
    const now = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
    const csvConfig = {
      fieldSeparator: ',',
      filename: `Compensation Form Generation Batch Error on ${now}`,
      title: `Compensation Form Generation Batch Error on ${now}\n\n`,
      quoteCharacter: '"',
      decimalSeparator: '.',
      columnHeaders: generated.headerByKey,
      showTitle: true,
      useBom: true,
    };
    const file = generateCsv(csvConfig)(generated.data);
    download(csvConfig)(file);
  }


  progressBarUpdate() {
    setTimeout(() => {
      this.compensationService.getBatchDrafts().subscribe(result => {
        this.totalBatchFormsCreated = ((this.workflowsCount + result) / this.onliners.length) * 100;
        if (this.generatingForms) {
          this.progressBarUpdate();
        } else {
          this.totalBatchFormsCreated = 0;
        }
      });
    }, progressBarPoll);
  }
}

export const progressBarPoll: number = 5000;
