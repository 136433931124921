import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, Injectable, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';

import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { CompensationService, defaultReview, defaultHistory, defaultVacayHistory } from '../core/services/compensation.service';
import { SnackBarService } from '../core/services/snackbar.service';

import { CompensationReview, CompensationReviewStatusType } from '../core/models/compensationReview.model';
import { Employee } from '../core/models/employee.model';
import { StatusTypeEnum } from '../core/models/enums.model';
import { environment } from 'src/environments/environment';

import { CompensationFormComponent, CompensationFormData } from './../compensation-form/compensation-form.component';
import { ConfirmDialogData, ConfirmationDialogComponent } from './../shared/confirmation-dialog/confirmation-dialog.component';
import { APIError } from '../core/models/error.model';

@Component({
  selector: 'app-compensation-result-list',
  templateUrl: './compensation-result-list.component.html',
  styleUrls: ['./compensation-result-list.component.scss']
})
@Injectable({
  providedIn: 'root',
})
export class CompensationResultListComponent implements OnInit, OnDestroy {

  @Input() onlyClosedReviews: boolean = false;
  @Input() isSearching: boolean;
  @Input() clickable: boolean;
  @Input() linkReviewId: number;
  @Input() isHistoryPage: boolean = false;

  @Output() doneSearch = new EventEmitter<boolean>();
  @Output() hasOpenReview = new EventEmitter<boolean>();
  subscriptions: Subscription[] = [];
  dataSource: MatTableDataSource<CompensationReview>;
  closedStatusTypes: CompensationReviewStatusType[];
  onliner: Employee;
  userCanDelete: boolean;
  onlinerHasOpenReview: boolean = false;
  isDeleting: boolean = false;
  clicked: boolean = false;

  get showTable() { return this.dataSource && this.dataSource.data };

  initiatorAdminHeaders: string[] = [
    'effDate',
    'prevCompensation',
    'newCompensation',
    'increase',
    'vacationEffectiveDate',
    'prevVacationDays',
    'newVacationDays',
    'statusName',
    'action'
  ];

  resultColumnHeaders: string[] = [
    'effDate',
    'prevCompensation',
    'newCompensation',
    'increase',
    'vacationEffectiveDate',
    'prevVacationDays',
    'newVacationDays',
    'statusName'
  ];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private compensationService: CompensationService,
    private snackBarService: SnackBarService,
    private currency: CurrencyPipe,
    private datePipe: DatePipe,
    private percent: PercentPipe,
    private commonService: CommonService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.userCanDelete = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator, environment.roles.CompApprovAdmin]);
    this.resultColumnHeaders = this.userCanDelete ? this.initiatorAdminHeaders : this.resultColumnHeaders;
    this.subscriptions.push(this.compensationService.onlinerUpdated$.subscribe(onliner => this.getCompensationResultList(onliner)));
    this.subscriptions.push(this.compensationService.reviewAdded$.subscribe(review => this.addReviewToList(review)));
    this.subscriptions.push(this.compensationService.reviewUpdated$.subscribe(review => this.clicked = review ? false : true));
  }

  ngOnDestroy() {
    this.snackBarService.clean();
    this.compensationService.onlinerUpdated('');
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reviewClosed = (r: CompensationReview) => this.closedStatusTypes.some(c => c.statusTypeId === r.statusTypeId);

  getCompensationResultList(onlinerId: string) {
    if (onlinerId !== '')
        forkJoin([this.commonService.getAllCommonData(), this.compensationService.getOnlinerCompensationReviews(onlinerId, this.onlyClosedReviews)])
        .subscribe(([commonData, reviews]) => {
          this.closedStatusTypes = commonData.closedStatusTypes;

          this.onliner = reviews.length > 0 ? reviews[0].onlinerEmployee : undefined;

          const data = (this.onlyClosedReviews) ? reviews.filter(r => this.reviewClosed(r)) : reviews;
          const hasOpenReview = reviews.some(r => !this.reviewClosed(r));

          this.warnIfIncompleteData(data);

          this.dataSource = new MatTableDataSource<CompensationReview>(data.sort((a, b) => b.compensationReviewId - a.compensationReviewId));

          this.doneSearch.emit(true);
          this.hasOpenReview.emit(hasOpenReview);
          this.isSearching = false;
          this.clicked = this.clicked ? false: this.clicked;

          const linkReview = this.dataSource.data.find(r => r.compensationReviewId === this.linkReviewId);
          if (linkReview && this.linkReviewId > 0) {
            this.openForm(linkReview, true);
          }
        },
          error => {
            this.doneSearch.emit(true);
            this.hasOpenReview.emit(true);
            this.snackBarService.error(error);
          });
  }

  warnIfIncompleteData(reviews: CompensationReview[]) {
    const goodData = (r: CompensationReview) => r.history && r.history.length > 0
      && r.vacationHistory && r.vacationHistory.length > 0;

    const badData = reviews.some(r => !goodData(r));
    if (badData) {
      this.snackBarService.warn('Some compensation review data is incomplete.');
    }
  }

  addReviewToList = (review: CompensationReview) => {
    if (!this.onliner || this.onliner.accountingUserId === review.onlinerEmployeeId) {
      this.dataSource.data = [review, ...this.dataSource.data];
      this.openForm(review);
      this.hasOpenReview.emit(true);
      this.doneSearch.emit(true);
    }
  }

  openForm = (review: CompensationReview, fromLink: boolean = false) => {
    this.clicked = true;
    let dialogWidth = '45%'; // default to desktop width
    // check if the device is mobile
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      dialogWidth = '90%'; // set to mobile width
    }

    const formReviews = [review, ...this.dataSource.data.filter(r => r.compensationReviewId < review.compensationReviewId && r.statusTypeId !== StatusTypeEnum.Rejected).slice(0, 2)]
    const dialogRef = this.dialog.open(CompensationFormComponent, {
      width: dialogWidth, height: '95vh', autoFocus: false,
      data: {
        reviews: formReviews,
        closedStatusTypes: this.closedStatusTypes
      } as CompensationFormData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '') {
        if (fromLink) {
          /* remove queryParams from link to avoid loop */
          this.router.navigate([], {
            queryParams: { reviewId: null }, /* onliner: null */
            queryParamsHandling: 'merge'
          });
        }

        this.getCompensationResultList(this.onliner ? this.onliner.accountingUserId : review.onlinerEmployeeId);
        if (review.isForce0) {
          this.compensationService.reviewUpdated(review, false);
        }
      }
    });

  }

  effectiveDateDisplay = ({ history, vacationHistory }: CompensationReview, isVacation = false) => {
    if (isVacation && vacationHistory && vacationHistory.length > 0) {
      const [{ effectiveDate }] = vacationHistory;
      return this.datePipe.transform(effectiveDate, 'MM/dd/yyyy');
    }
    if (history && history.length > 0) {
      const [{ effectiveDate }] = history;
      return this.datePipe.transform(effectiveDate, 'MM/dd/yyyy');
    }
  };

  prevReview = (ix: number): CompensationReview => {
    var review = defaultReview;
    var found = false;
    var iterator = 1;

    while (!found && ix+iterator < this.dataSource.data.length) {
      if (ix+iterator < this.dataSource.data.length) {
        if (this.dataSource.data[ix+iterator].statusTypeId == StatusTypeEnum.Processed) {
          found = true;
          review = this.dataSource.data[ix+iterator];
        }
      }
      iterator++;
    }

    return review;
  };

  prevCompDisplay = ({history: [{ previousAmount } = defaultHistory] }: CompensationReview) => {
    return this.currency.transform(previousAmount);
  };

  newCompDisplay = ({ history: [{ amount } = defaultHistory] }: CompensationReview) => {
    return this.currency.transform(amount);
  };

  increaseDisplay = ({ history: [{ amount, previousAmount } = defaultHistory] }: CompensationReview) => {
    if (amount === undefined) return;
    if (previousAmount === undefined) return;
    
    const prevAmount = previousAmount;
    const diff = amount - prevAmount;
    const pct = amount > 0 ? diff / prevAmount : 0;
    return `${this.currency.transform(diff)} (${this.percent.transform(pct, '.1')})`;
  };

  prevVacationDisplay = ix => this.prevReview(ix).vacationHistory[0].previousDays / 7;
  vacationDisplay = ({ vacationHistory: [{ days } = defaultVacayHistory] }: CompensationReview) => days/7;
  statusNameDisplay = ({ statusType, isForce0 }: CompensationReview) => statusType ? (isForce0 ? statusType.statusTypeName + '*' : statusType.statusTypeName) : '';

  openDeleteConfirmation = (review: CompensationReview, event: any) => {
    this.isDeleting = true;
    this.clicked=true;
    if (this.allowDelete(review)) {
      event.stopPropagation();

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          title: 'Confirm Delete', theme: 'danger',
          message: '<p>Do you wish to delete this review?</p>',
          okButtonTitle: 'Yes', cancelButtonTitle: 'No'
        } as ConfirmDialogData,
        disableClose: true
      });
      dialogRef.afterClosed()
        .subscribe(result => {
          if (result === 'ok') {
            this.compensationService.removeDraft(review).subscribe(result => {
              this.dataSource.data = this.dataSource.data.filter(d => d.compensationReviewId !== review.compensationReviewId);
              this.snackBarService.message('Review Deleted.');
              this.hasOpenReview.emit(this.dataSource.data.some(r => !this.reviewClosed(r)));
              this.compensationService.removeForce0(review);
              this.isDeleting = false;
              this.onlinerHasOpenReview = this.dataSource.data.some(r => !this.reviewClosed(r));
              this.clicked = false;
            }), error => {
              this.handleError(error);
            };
          } else if (result === 'cancel') {
            this.isDeleting = false;
            this.clicked = false;
          }

        });
    }
     else {
      this.isDeleting = false;
      this.clicked = false;
    }
  }

  handleError(error: any) {
    if (error instanceof APIError) {
      this.snackBarService.message(error.ErrorMessage);
    } else {
      this.snackBarService.message('Could not find review');
    }
    this.isDeleting = false;
    this.clicked = false;
    this.getCompensationResultList(this.onliner.accountingUserId);
  }

  allowDelete = (review: CompensationReview) => {
    return this.userCanDelete &&
      (review.statusTypeId === StatusTypeEnum.New ||
        (review.isForce0 && review.statusTypeId === StatusTypeEnum.Acknowledged)) &&
      this.clickable;
  }

  isRejected(review: CompensationReview) {
    return review.statusTypeId == StatusTypeEnum.Rejected;
  }
}
