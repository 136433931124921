import { HomeComponent } from './home.component';
import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        HomeRoutingModule,
        SharedModule
    ],
    declarations: [
        HomeComponent,
        DashboardCardComponent
    ],
    exports: [],
    providers: []
})

export class HomeModule { }
