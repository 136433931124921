import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { CompensationReviewStatusType } from 'src/app/core/models/compensationReview.model';
import { AddReviewerComponent } from 'src/app/reviewer/add-reviewer/add-reviewer.component';
import { AssignReviewerComponent } from 'src/app/reviewer/assign-reviewer/assign-reviewer.component';
import { environment } from 'src/environments/environment';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  isAuthenticated = false;
  userName: string;
  profilePhotoData: any;
  isImageLoading: boolean;
  isAdmin: boolean;
  adminRole: string = environment.roles.CompApprovAdmin;
  dialogRefAdd: MatDialogRef<{}, any>;
  dialogRefAssign: MatDialogRef<{}, any>;
  closedStatusTypes: CompensationReviewStatusType[];
  selections = [{ "name": "Add Reviewer", id: "add" }, { "name": "Assign Reviewer", id: "assign" }];

  constructor(private authService: AuthService, private userService: UserService, private dialog: MatDialog,
    private msalBroadcastService: MsalBroadcastService) { }
  closeDialog() {
    this.openForm = null;
    this.dialogRefAdd.close;
    this.dialogRefAssign.close;
  }

  roles(): string {
    let allRoles: Array<string> = this.authService.getUserRoles();
    if (allRoles) {
      const mappedRoles = allRoles
        .map(role => {
          switch (role) {
            case environment.roles.CompApprovUser:
              return 'User';
            case environment.roles.CompApprovInitiator:
              return 'Initiator';
            case environment.roles.CompApprovPayroll:
              return 'Payroll';
            case environment.roles.CompApprovReviewer:
              return 'Reviewer';
            case environment.roles.CompApprovAdmin:
              return 'Admin';
            default:
              return undefined;
          }
        })
        .filter(role => role !== undefined);
      return `Roles: ${mappedRoles.join(',')}`;
    }
    return 'No Roles';
  }

  ngOnInit() {
    this.dialogRefAdd = undefined;
    this.dialogRefAssign = undefined;

    this.isImageLoading = true;


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isAuthenticated = this.authService.isAuthenticated();
        if (this.isAuthenticated) {
          this.userName = this.authService.getUserId();

          const photo$ = this.userService.getMyProfilePhoto().pipe(finalize(() => (this.isImageLoading = false)));
          photo$.subscribe(
            data => {
              if (data) this.profilePhotoData = `data:image/png;base64,${data}`;
            },
            error => console.log(error)
          );

          this.isAdmin = this.authService.doesUserHaveRole([this.adminRole]);
            }
          })
  }

  logout() {
    this.authService.logout();
  }

  openForm = (newReviewerFormOption: string) => {
    this.dialog.closeAll();

    if (newReviewerFormOption === 'add') {
      this.dialogRefAdd = this.dialog.open(AddReviewerComponent, {
        autoFocus: false, disableClose: false
      });
    } else if (newReviewerFormOption === 'assign') {
      this.dialogRefAssign = this.dialog.open(AssignReviewerComponent, {
        autoFocus: false, disableClose: false
      });
    }
  }

  ngOnDestroy(): void {
    //this.dialogRefAdd = undefined;
    //this.dialogRefAssign = undefined;
    alert("ngOnDestroy()");
    this.dialogRefAssign.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`); // Pizza!
    });

    //dialogRef.close('Pizza!');
  }
}
