import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { AngularMaterialModule } from '../angular-material/angular-material.module';

import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { HeaderComponent } from './header/header.component';
import { MiniNavigationComponent } from './mini-navigation/mini-navigation.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SessionExpiredDialogComponent } from './session-expired-dialog/session-expired-dialog.component';

import { IfRoleDirective } from './if-role/if-role.directive';
import { CurrencyMaskDirective } from './currency-mask.directive';

const routes: Routes = [{ path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full' }];

const sharedModules = [FormsModule, CommonModule, ReactiveFormsModule, AngularMaterialModule];
const sharedComponents = [NavigationComponent, MiniNavigationComponent, HeaderComponent, ForbiddenComponent];
const dialogComponents = [ConfirmationDialogComponent, CommentDialogComponent, SessionExpiredDialogComponent];
const directives = [IfRoleDirective, CurrencyMaskDirective];
@NgModule({
    imports: [sharedModules, RouterModule.forChild(routes)],
    declarations: [dialogComponents, sharedComponents, directives],
    exports: [sharedModules, sharedComponents, DatePipe, directives],
    providers: []
})
export class SharedModule { }
