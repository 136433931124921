<div style="background-color:white;">
  <div class="p-3 dialog-header text-center">
    <span class="dialog-title d-inline">Add Reviewer</span>
  </div>
  <div *ngIf="isLoading" class="loading m-auto"></div>
  
  <div>
    <form *ngIf="!isLoading" [formGroup]="addReviewerForm" novalidate>
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            <mat-form-field class="mr-3 search">
              <input matInput name="onliner" placeholder="Choose Onliner" [matAutocomplete]="auto"
                     formControlName="onliner" />
              <mat-error *ngIf="addReviewerForm.get('onliner').hasError('required') && addReviewerForm.get('onliner').touched">
                Please enter the name of the Onliner.
              </mat-error>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="onlinerDisplay" autoActiveFirstOption
                                (optionSelected)="onlinerSelected($event)" panelWidth="280px">
                <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                  {{ onlinerDisplay(option) }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button type="button" mat-stroked-button class="submit-btn" (click)="addReviewer()" title="Add" id="getReviewerButton"
                    [disabled]="!addReviewerForm.valid || isSearching || isReviewerAlreadyAdded()">
              <span *ngIf="isSearching"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>Add
            </button>
          </mat-card-title>

        </mat-card-header>
        <mat-card-content>
          <mat-list dense class="reviewer-table">
            <mat-list-item *ngFor="let employee of reviewersOfOnliner; let even = even; let odd = odd"
                           [ngClass]="{ odd: odd, even: even }">
              <p matLine class="columns">
                <span class="reviewer-name">{{employee.firstName}} {{employee.lastName}} </span>
                <mat-icon class="delete-icon" matTooltip="Remove" matListIcon (click)="openDeleteConfirmation(employee)">
                  delete
                </mat-icon>
              </p>
            </mat-list-item>
          </mat-list>
          <!-- <div class="text-center" id="reviewerTable">
            <div class="row columns" *ngFor="let employee of reviewersOfOnliner; let even = even; let odd = odd">
              <div [ngClass]="{ odd: odd, even: even }" class="col-md-10 leftAlignCell">{{employee.firstName}}
                {{employee.lastName}}</div>
              <div [ngClass]="{ odd: odd, even: even }" class="col-md-2 delName"><i class="fas fa-trash-alt"
                  aria-hidden="true" matTooltip="Remove" (click)="openDeleteConfirmation(employee)"></i></div>
            </div>
          </div> -->
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
