import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { CompensationService } from '../core/services/compensation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  adminRole: string = environment.roles.CompApprovAdmin;
  reviewerRole: string = environment.roles.CompApprovReviewer;
  initiatorRole: string = environment.roles.CompApprovInitiator;
  onlinerRole: string = environment.roles.CompApprovUser;
  payrollRole: string = environment.roles.CompApprovPayroll;

  formRoles: string = `${this.adminRole},${this.initiatorRole}`;

  myDrafts: number;
  myWorkflows: number;

  draftsLoading: boolean;
  workflowsLoading: boolean;

  isAdmin: boolean;
  isReviewer: boolean;
  isInitiator: boolean;
  isOnliner: boolean;
  isPayroll: boolean;

  constructor(private authService: AuthService,
    private compApprovalService: CompensationService) { }

  ngOnInit() {

    // this.isAdmin = this.authService.doesUserHaveRole([this.adminRole]);
    // this.isReviewer = this.authService.doesUserHaveRole([this.reviewerRole]);
    // this.isInitiator = this.authService.doesUserHaveRole([this.initiatorRole]);
    // this.isOnliner = this.authService.doesUserHaveRole([this.onlinerRole]);
    // this.isPayroll = this.authService.doesUserHaveRole([this.payrollRole]);

    // if (this.isAdmin || this.isInitiator) {
    //   this.GetDraftsCount();
    // }

    // this.GetWorkflowCount();
  }

  private GetDraftsCount() {
    this.draftsLoading = true;
    this.compApprovalService.getDraftCount()
      .subscribe( count => {
        this.myDrafts = count;
        this.draftsLoading = false;
      })
  }

  private GetWorkflowCount() {
    this.workflowsLoading = true
    this.compApprovalService.getWorkflowCount()
      .subscribe( count => {
        this.myWorkflows = count;
        this.workflowsLoading = false;
      })
  }

}
