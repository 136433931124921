import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable()
export class SnackBarService {
  ref: MatSnackBarRef<SimpleSnackBar>;

  constructor(private snackBar: MatSnackBar) { }

  message(message: string) {
    this.ref = this.snackBar.open(message, 'Dismiss', {
      duration: 5000
    });
  }

  error(message: string) {
    this.ref = this.snackBar.open(`Error: ${message}`, 'Dismiss', {
      duration: 5000
    });
  }

  warn(message: string) {
    this.ref = this.snackBar.open(`Warning: ${message}`, 'Dismiss', {
      duration: 5000,
      // panelClass: `comp-warning`
    });
  }

  clean() {
    if (this.ref) {
      this.ref.dismiss();
    }
  }
}
