import { HttpClient, HttpHeaders, HttpHeaderResponse} from '@angular/common/http';

import {empty as observableEmpty, Observable, throwError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredDialogComponent } from 'src/app/shared/session-expired-dialog/session-expired-dialog.component';

@Injectable()
export class AuthorizedHttp {

  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private dialog: MatDialog) {}

  private createAuthorizationHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  get(url) {
    return this.intercept(this.http.get(url, this.createAuthorizationHeader()) );
  }

  ///getText receives a base64 encoded string response from API. May be useful for images or binary files returned from API.
  getText(url) {
    return this.intercept(this.http.get(url, { headers: this.createAuthorizationHeader().headers, responseType: 'text' as 'json' }));
  }

  post(url, data) {
    return this.intercept(this.http.post(url, data, this.createAuthorizationHeader()));
  }

  postWithNoData(url) {
    return this.intercept(this.http.post(url, '', this.createAuthorizationHeader()));
  }

  put(url, data) {
    return this.intercept(this.http.put(url, data, this.createAuthorizationHeader()));
  }

  delete(url, body = {}) {
    const deleteOptions = {...this.createAuthorizationHeader(), body};
    return this.intercept(this.http.delete(url, deleteOptions));
  }

  intercept(observable: Observable<any>)
  {
    return observable.pipe(catchError(err => {
        if (err.status === 401) {
          return this.unauthorize();
        } else if (err.status === 403) {
          return this.forbidden();
        }
        else {
            return throwError(err);
        }
      }));
  }

  refresh(): Observable<any>{
    location.reload();
    return observableEmpty();
  }

  forbidden(): Observable<any>
  {
      this.router.navigate(['/forbidden']);
      return observableEmpty();
  }

  unauthorize(): Observable<any> {
    this.showSessionExpiredDialog();
    return throwError({ error: { exceptionMessage: 'Session Expired' } });
  }
  public sessionExpiredDialogIsOpened = false;
  public showSessionExpiredDialog() {
    if (!this.sessionExpiredDialogIsOpened) {
      this.sessionExpiredDialogIsOpened = true;
      const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
        width: '400px',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(() => {
        this.sessionExpiredDialogIsOpened = false;
      });
    }
  }
  public encrypt(value: string): string {
    let encoded = btoa(value);
    encoded = encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return encoded;
  }



}
