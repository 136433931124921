 <div class="jumbotron m-5 text-center">
  <div class="component-container">
      <div *ngIf="!(draftsLoading || workflowsLoading); else loading" class="row">
        <div *ifRole="formRoles" class="col-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{'col-xl-4': (isAdmin || isInitiator)}">
          <app-dashboard-card [description]="'Drafts'" [count]="myDrafts" [routeAddress]="'drafts'" [iconName]="'fas fa-file-invoice'" [primaryColor]="'#090c60'"><span><i class="fas fa-file-invoice"></i></span></app-dashboard-card>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{'col-xl-4': (isAdmin || isInitiator), 'col-xl-6': !isAdmin && !isInitiator}">
          <app-dashboard-card [description]="'My Workflows'" [count]="myWorkflows" [iconName]="'fas fa-table fa-lg'" [routeAddress]="'workflows'" [primaryColor]="'#e08b23'"></app-dashboard-card>
        </div>
      </div>

      <ng-template #loading>
          <div class="loading m-auto"></div>
        </ng-template>
  </div>
</div> 


