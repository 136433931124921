import { UntypedFormControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';


export function requiredAndNoWhiteSpaceValidator(control: UntypedFormControl) {
  if (!control.value || control.value.trim().length <= 0) {
    let temp = {};
    temp['required'] = true;
    return temp;
  }

  return null;
}

export function checkDatePattern(control: UntypedFormControl) {
  if (control.value) {
    if (control.value instanceof Date) {
      let data = control.value;
      let testData = data.getMonth() + "/" + data.getDate() + "/" + data.getFullYear();
      const dateRegEx = new RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);
      if (!dateRegEx.test(testData)) {
        return { 'datePatternError': true };
      }
    }

  }
  return null;
}

export function validateOnliner(): ValidatorFn {
  return (onlinerControl: UntypedFormControl): { [key: string]: any } => {
    if (!onlinerControl.value || !onlinerControl.value.firstName) {
      return { 'required': true };
    }
  };
}

export function validateBusinessChoice(): ValidatorFn {
  return (businessControl: UntypedFormControl): { [key: string]: any } => {
    if (!businessControl.value || !businessControl.value.name) {
      let temp = {};
      temp['required'] = true;
      return temp;
    }
  }
}

export function validateSelectListItem(): ValidatorFn {
  return (control: UntypedFormControl): { [key: string]: any } => {
    if (!control.value || !control.value.name) {
      let temp = {};
      temp['required'] = true;
      return temp;
    }
  };
}

/** Error when invalid control is dirty, touched, or submitted. */
export class CompControlErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
