<div class="p-3"
  [ngClass]="{'dialog-header': confirmDialog.theme==='success', 'dialog-header-danger': confirmDialog.theme==='danger'}">
  <span class="dialog-title d-inline">{{confirmDialog.title}}</span>
  <button mat-dialog-close="cancel" type="button" class="btn btn-link close pull-right text-white no-underline"
    aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="dialog-body">
  <div *ngIf="confirmDialog.message" class="text-center">
    <span class="justified" [innerHTML]="confirmDialog.message"></span>
  </div>
  <div class="row no-gutters justify-content-center pt-1">
    <div class="col-auto">
      <button *ngIf="confirmDialog.cancelButtonTitle" type="button" class="btn btn-action-dialog cancel-button mr-1"
        mat-dialog-close="cancel">{{confirmDialog.cancelButtonTitle}}</button>
      <button *ngIf="confirmDialog.okButtonTitle" type="button" class="btn btn-action-dialog success-button ml-1"
        [ngClass]="{'success-button': confirmDialog.theme==='success', 'danger-button': confirmDialog.theme==='danger'}"
        mat-dialog-close="ok">
        {{confirmDialog.okButtonTitle}}
      </button>
    </div>
  </div>
</div>