<div style="background-color:white;">
  <div class="p-3 dialog-header text-center">
    <span class="dialog-title d-inline">Assign Reviewer</span>
  </div>
  <div *ngIf="isLoading" class="loading m-auto"></div>
  <div>
    <form *ngIf="!isLoading" [formGroup]="assignReviewerForm" novalidate>
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            <mat-form-field class="mr-3 search">
              <input matInput name="onliner" id="onliner_name" placeholder="Choose Onliner" [matAutocomplete]="auto"
                     formControlName="onliner" panelWidth="280px" required />
              <mat-error *ngIf="assignReviewerForm.get('onliner').hasError('required') && assignReviewerForm.get('onliner').touched">
                Please enter the name of the Onliner.
              </mat-error>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="onlinerDisplay"
                                (optionSelected)="onlinerSelected($event)" panelWidth="280px">
                <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                  {{onlinerDisplay(option)}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </mat-card-title>
          <br><br>
          <mat-card-title>
            <mat-label *ngIf="!assignReviewerForm.get('onliner').hasError('required')">
              {{reviewerLabel}}
            </mat-label>
            <br><br>
          </mat-card-title>
          <mat-card-title>
            <mat-form-field class="mr-3 search">
              <input matInput id="reviewer_name" placeholder="Choose Reviewer" [matAutocomplete]="autoR"
                     formControlName="reviewer" panelWidth="280px" required />
              <mat-error *ngIf="assignReviewerForm.get('reviewer').hasError('required') && assignReviewerForm.get('reviewer').touched">
                Please enter the name of the Reviewer.
              </mat-error>
              <mat-autocomplete #autoR="matAutocomplete" [displayWith]="reviewerDisplay"
                                (optionSelected)="reviewerSelected($event)" panelWidth="280px">
                <mat-option *ngFor="let option of filteredReviewers | async" [value]="option">
                  {{reviewerDisplay(option)}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <br><br>
            <mat-card-content>
              <button type="button" mat-stroked-button class="submit-btn" (click)="assignReviewerOnliner()" title="Assign"
                      id="getReviewerButton" name="getReviewerButton" [disabled]="!assignReviewerForm.valid || isSearching"
                      autofocus>
                <span *ngIf="isSearching"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>Assign
              </button>
            </mat-card-content>
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </form>
  </div>
</div>
