import { CompensationFormComponent } from './compensation-form.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, PICK_FORMATS } from '../shared/custom-date-adapter';



@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        CompensationFormComponent
    ],
    exports: [
        CompensationFormComponent
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: PICK_FORMATS
        },
    ]
})

export class CompensationFormModule { }
