import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  subMenu: { [name: string]: boolean } = {
    form: false,
    admin: false
  };

  adminRole: string = environment.roles.CompApprovAdmin;
  initRole: string = environment.roles.CompApprovInitiator;
  payrollRole: string = environment.roles.CompApprovPayroll;
  reviewerRole: string = environment.roles.CompApprovReviewer;
  formRoles: string = `${this.adminRole},${this.initRole},${this.payrollRole},${this.reviewerRole}`;
  draftRoles: string = `${this.adminRole},${this.initRole}`;
  processedRoles: string[] = [this.adminRole, this.initRole, this.payrollRole, this.reviewerRole];

  get showAdminSubMenu(): boolean { return this.subMenu['admin']; }
  get showFormSubMenu(): boolean { return this.subMenu['form']; }

  constructor() { }

  showMenu = true;

  toggleShowMenu = () => {
    this.showMenu = !this.showMenu;
  }

  private toggleSubMenuItem = (name: string) => {
    this.subMenu[name] = !this.subMenu[name];
  };
  toggleAdminSubMenu = () => this.toggleSubMenuItem('admin');
  toggleFormSubMenu = () => this.toggleSubMenuItem('form');
}
