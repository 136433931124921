import { Directive, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[maxDateRangeValidator][formControl],[maxDateRangeValidator][formControlName],[maxDateRangeValidator][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: maxDateRangeValidatorDirective, multi: true}]
})
export class maxDateRangeValidatorDirective implements Validator {
  constructor(
    @Attribute('maxDateRangeValidator') public maxDateRangeValidator: string,
  ) {
  }

  validate(dateRangeStartDateControl: UntypedFormControl) {
    let dateRangeEndtDateControl = dateRangeStartDateControl.root.get(this.maxDateRangeValidator);
    if (!dateRangeEndtDateControl) 
      return

    dateRangeEndtDateControl.setErrors(null);    
  
    let dateRangeEndtDate = dateRangeEndtDateControl.value;    
    let dateRangeStartDate = dateRangeStartDateControl.value;

    if (!dateRangeEndtDate || !(dateRangeEndtDate instanceof Date)){
      dateRangeEndtDateControl.setErrors({"invalidEndDate": true}); 
    }   
  
    var err = (!dateRangeStartDate || !(dateRangeStartDate instanceof Date) || dateRangeStartDate > dateRangeEndtDate)
      ? {"invalidStartDate": true} 
      : null;

    return err;
  }
} 