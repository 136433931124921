import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { WorkflowSummary } from '../core/models/workflowSummary.model';
import { CompensationReviewStatusType, CompensationReview } from '../core/models/compensationReview.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../core/services/snackbar.service';
import { DatePipe } from '@angular/common';
import { CompensationService, defaultCompetency, defaultConsultantLevel, defaultStatusType } from '../core/services/compensation.service';
import { CompensationFormComponent, CompensationFormData } from '../compensation-form/compensation-form.component';
import { Employee } from '../core/models/employee.model';
import { EmailService } from '../core/services/email.service';
import { StatusTypeEnum } from '../core/models/enums.model';
import { ConfirmationDialogComponent, ConfirmDialogData } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from '../core/services/auth.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { WorkflowPageViewSummary } from '../core/models/workflowPageViewSummary.model';
import { NavigationService } from '../core/services/navigation.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-workflows-list',
  templateUrl: './workflows-list.component.html',
  styleUrls: ['./workflows-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowsListComponent implements OnInit {
  bccView: boolean = false;
  
  @Input() set reviews(compensations: WorkflowPageViewSummary[]) {
    if (compensations) {
      this.isLoading = false;
      this.ns.toggleShowMenu();
      this.dataSource.data = compensations;
      this.dataSource.sortingDataAccessor = (row: WorkflowPageViewSummary, columnName: string) => {
        switch (columnName) {
          case 'onlinerName':
            return this.onlinerDisplay(row.onlinerEmployee) as string;
            break;
          case 'reviewerName':
            return this.onlinerDisplay(row.reviewerEmployee) as string;
            break;
          case 'practiceName':
            return this.practiceDisplay(row) as string;
            break;
          case 'serviceName':
            return this.serviceDisplay(row) as string;
            break;
          case 'consultantLevel':
            return this.consultantLevelDisplay(row) as string;
            break;
          case 'primaryCompetency':
            return this.primaryCompetencyDisplay(row) as string;
            break;
          case 'status':
            return this.statusDisplay(row) as string;
            break;
          case 'lastStatusChanged':
            return new Date(this.lastStatusDateDisplay(row)).getTime();
            break;
          case 'daysSinceStatusChange':
            return this.daysSinceLastUpdateDisplay(row);
            break;
          default:
            return this.onlinerDisplay(row.onlinerEmployee);
            break;
        }
      };
    }
  };
  @Input() unfilteredReviews: WorkflowSummary[];
  @Input() closedStatusTypes: CompensationReviewStatusType[];
  @Input() canEmail: boolean;
  @Input() set reviewId(id: number) {
    if (this.unfilteredReviews && id > 0) {
      const review = this.unfilteredReviews.find(r => r.compensationReviewId === id);

      if (review)
        this.openForm(review);
    }
  };
  @Input() loggedInUser: Employee;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  
  isLoading: boolean = true;
  dataSource = new MatTableDataSource<WorkflowPageViewSummary>();
  clicked: boolean = false;
  currReview: WorkflowPageViewSummary;
  isSending: boolean = false;
  sendingMassEmail: boolean = false;
  finishedRefreshing: boolean;
  rev: CompensationReview;

  selection = new SelectionModel<WorkflowPageViewSummary>(true, []);

  get showTable() { return this.dataSource && this.dataSource.data };

  initiatorHeaders = ['emailSelect',
    'onlinerName',
    'reviewerName',
    'practiceName',
    'serviceName',
    'consultantLevel',
    'primaryCompetency',
    'status',
    'lastStatusChanged',
    'daysSinceStatusChange',
    'bccAll',
    'action'];

  headers = [
    'onlinerName',
    'reviewerName',
    'practiceName',
    'serviceName',
    'consultantLevel',
    'primaryCompetency',
    'lastStatusChanged',
    'daysSinceStatusChange',
    'status'];

  resultColumnHeaders: string[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private snackBarService: SnackBarService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private compensationService: CompensationService,
    private dialog: MatDialog,
    private emailService: EmailService,
    public ns: NavigationService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.ns.toggleShowMenu();
    this.bccView = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.resultColumnHeaders = this.bccView ? this.initiatorHeaders : this.headers;
    this.subscriptions.push(this.compensationService.reviewUpdated$.subscribe(result => this.finishedRefreshing = result ? false : true));
  }

  ngOnDestroy() {
    this.snackBarService.clean();
  }

  ngOnChanges() {
    this.finishedRefreshing = true;
  }
 
  openForm = (review: CompensationReview) => {
    this.finishedRefreshing = false;
    let dialogWidth = '45%'; // default to desktop width
    // check if the device is mobile
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      dialogWidth = '90%'; // set to mobile width
    }
    const dialogRef = this.dialog.open(CompensationFormComponent, {
      width: dialogWidth,
      height: '95vh',
      autoFocus: false,
      data: {
        reviews: [{ compensationReviewId: review.compensationReviewId }],
        closedStatusTypes: this.closedStatusTypes
      } as CompensationFormData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      // Refresh the component when the dialog is closed
      if (result == 'updated' || result == 'rejected') {
        this.isLoading = true;
      }    
    });
  }

  getForm = (reviewId: number) => {
    this.compensationService.getFormReviewsById(reviewId).subscribe(result => {
      return result;
    }, error => this.handleError(error));
  }

  createdDateDisplay = ({ createdDate }: WorkflowSummary) => this.datePipe.transform(createdDate, 'MM/dd/yyyy');

  onlinerDisplay = (employee: Employee) => {
    if (employee == null) {
      return "No Reviewer Found";
    }
    return employee.firstName + ' ' + employee.lastName;
  }

  bccAllDisplay = (review: WorkflowPageViewSummary) => {
    return review.bccAllName;
  }

  practiceDisplay = ({ practice }: WorkflowPageViewSummary) => practice;

  serviceDisplay = ({ serviceLine }: WorkflowPageViewSummary) => serviceLine;

  consultantLevelDisplay = ({ consultantLevel }: WorkflowPageViewSummary) => {
    if (consultantLevel == null) {
      consultantLevel = defaultConsultantLevel;
      consultantLevel.name = '';
    }

    return consultantLevel.name;
  };

  primaryCompetencyDisplay = ({ primaryCompetencyName }: WorkflowPageViewSummary) => {
    if (primaryCompetencyName == null) {
      primaryCompetencyName = defaultCompetency.name;
      primaryCompetencyName = '';
    }

    return primaryCompetencyName;
  };

  lastStatusDateDisplay = (review: WorkflowPageViewSummary) => {
    //return review.status.length < 1 ? '' : this.datePipe.transform(review.status[review.status.length-1].statusDate, 'MM/dd/yyyy');
    return this.datePipe.transform(review.statusDate, 'MM/dd/yyyy');
  }

  daysSinceLastUpdateDisplay = (review: WorkflowPageViewSummary) => {
    var day = 1000 * 60 * 60 * 24;
    const today = new Date(new Date().toDateString());
    const statusDate = review.statusDate == null ? '' : new Date(new Date(review.statusDate).toDateString());
    const numSinceLastUpdate = statusDate == '' ? 0 : Math.floor(today.getTime() - statusDate.getTime());
    return Math.floor(numSinceLastUpdate / day);
  }

  statusDisplay = ({ statusTypeId, statusTypeName, isForce0 }: WorkflowPageViewSummary) => {
    /*if (statusTypeId == null) {
      statusTypeId = defaultStatusType.statusTypeId;
      statusTypeName = defaultStatusType.statusTypeName;
    }*/

    return statusTypeName;
  }


  handleError(error: any) {
    if (error.ErrorType === ('MissingReviewException' || 'InvalidUserOperationException' || 'EmailNotSentException')) {
      this.snackBarService.message(error.ErrorMessage);
      this.isLoading = true;
    } else {
      this.snackBarService.message(error);
    }
    this.clicked = false;
    this.isSending = false;
  }

  openEmailForm = (review: WorkflowPageViewSummary, event: any) => {
    this.isSending = true;
    this.currReview = review;
    event.stopPropagation();

    var pokeReviewer = review.statusType == StatusTypeEnum.Initiated;
    var pokeOnliner = review.statusType == StatusTypeEnum.Reviewed;
    var pokePayroll = review.statusType == StatusTypeEnum.Acknowledged;

    var msg = pokeReviewer ? review.reviewerEmployee.firstName + " " + review.reviewerEmployee.lastName :
      pokeOnliner ? review.onlinerEmployee.firstName + " " + review.onlinerEmployee.lastName :
        pokePayroll ? 'Payroll' :
          null;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Send Email', theme: 'danger',
        message: '<p>Do you wish to notify ' + msg + ' of this review?</p>',
        okButtonTitle: 'Yes', cancelButtonTitle: 'No'
      } as ConfirmDialogData,
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === 'ok') {
          if (pokeReviewer || pokeOnliner || pokePayroll) {
            this.emailService.emailUserRegardingReview(review).subscribe(res => {
              this.isSending = false;
              this.clicked = false;
              var toUser = review.statusType == StatusTypeEnum.Initiated ? review.reviewerEmployee.fullName ? review.reviewerEmployee.fullName : review.reviewerEmployee.firstName + " " + review.reviewerEmployee.lastName :
                review.statusType == StatusTypeEnum.Reviewed ? review.onlinerEmployee.fullName ? review.onlinerEmployee.fullName : review.onlinerEmployee.firstName + " " + review.onlinerEmployee.lastName :
                  review.statusType == StatusTypeEnum.Acknowledged ? "Payroll" : null;

              var msg = toUser + ', BCC: ' + this.loggedInUser.fullName;
              //msg += review.bccAll ? ', ' + review.bccAllName + '.' : '.';
              this.snackBarService.message('Email Sent to: ' + msg);
            }, error => {
              this.handleError(error);
              this.isSending = false;
              this.clicked = false;
            });
          }
        } else if (result === 'cancel') {
          this.isSending = false;
          this.clicked = false;
        }
      }, error => {
        this.handleError(error);
        this.isSending = false;
        this.clicked = false;
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  openMassEmailForm = (event: any) => {
    this.isSending = true;
    event.stopPropagation();

    var msg = this.selection.selected.length > 1 ? 'a mass email' : 'an email';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Send Email', theme: 'danger',
        message: '<p>Do you wish to send ' + msg + '?</p>',
        okButtonTitle: 'Yes', cancelButtonTitle: 'No'
      } as ConfirmDialogData,
      disableClose: true
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === 'ok') {
          this.massEmail();
        } else if (result === 'cancel') {
          this.isSending = false;
          this.clicked = false;
        }
      }, error => {
        this.handleError(error);
        this.isSending = false;
        this.clicked = false;
      });
  }

  massEmail() {
    //EmailService expecting WorkflowSummary[]
    //Actual object is type,WorkflowPageViewSummary not WorkflowSummary as sent previously

    var workflowsToEmail = [];
    var workflowsToEmailRow: WorkflowPageViewSummary[] = [];
    this.dataSource.data.forEach(review => {
      if (this.selection.isSelected(review)) {
        workflowsToEmailRow.push(review);
      }
    })
    workflowsToEmail = workflowsToEmailRow.slice();
    this.emailService.massEmailUsersRegardingReviews(workflowsToEmail).subscribe(result => {
      this.selection.clear();
      this.isSending = false;
      this.clicked = false;
      //TODO: modal window instead of toast...
      var snackbarMsg = this.selection.selected.length > 1 ? 'Emails Sent.' : 'Email Sent.'
      this.snackBarService.message(snackbarMsg);
    }, error => {
      this.snackBarService.error(error);
      this.isSending = false;
      this.clicked = false;
    });
  }

  disableCheckBoxes() {
    if (this.isLoading) return true;
    if (this.isSending) return true;

    return false;
  }

}
