import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CompensationReviewStatusType } from '../core/models/compensationReview.model';
import { Employee } from '../core/models/employee.model';
import { CompensationService } from '../core/services/compensation.service';
import { OnlinerService } from '../core/services/onliner.service';
import { AuthService } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { SnackBarService } from '../core/services/snackbar.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  filteredOnliners: Observable<Employee[]>;
  isSearching: boolean = true;
  onlinerId: string;
  closedStatusTypes:CompensationReviewStatusType[];

  resultColumnHeaders: string[] = [
    'effDate',
    'prevCompensation',
    'newCompensation',
    'increase',
    'prevVacationDays',
    'newVacationDays',
    'statusName',
    'action'
  ];

  constructor(
    private compensationService: CompensationService,
    private authService: AuthService,
    private onlinerService: OnlinerService,
    private commonService: CommonService,
    private snackBarService: SnackBarService
  ) { }

  ngOnInit() {
    this.setupPage();
  }

  private setupPage() {
      this.commonService.getAllCommonData().subscribe(
        commonData => {
          this.closedStatusTypes = commonData.closedStatusTypes;

        this.onlinerService.getAccountingUserId(this.authService.getUserId().toLowerCase()).subscribe(result => {
          this.onlinerId = result;
          this.compensationService.onlinerUpdated(this.onlinerId);

        },
        error => {
          this.snackBarService.error(error);
        });
      },
      error => {
        this.snackBarService.error(error);
      });
  }
}
