<!--prettier-ignore-->
<nav *ngIf="showMenuItems" class="navbar navbar-expand-lg navbar-dark p-0">
  <div id="navbarNav">
    <ul class="navbar-nav flex-column">
      <!-- <li class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="home" routerLinkActive="$router_link-active$" matTooltip="Home"
          [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip" [routerLinkActiveOptions]="{ exact: true }"><i
            class="fas fa-home fa-lg"></i> <span class="sr-only">(current)</span></a>
      </li> -->

      <li *ifRole="ns.formRoles" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="newforms" routerLinkActive="$router_link-active$" matTooltip="Forms"
          [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip" [routerLinkActiveOptions]="{ exact: true }"><i
           class="fas fa-search-plus fa-lg"></i></a>
      </li>

      <li *ifRole="ns.draftRoles" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="drafts" routerLinkActive="$router_link-active$" matTooltip="Drafts"
          [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip" [routerLinkActiveOptions]="{ exact: true }"><i
            class="fas fa-file-invoice fa-lg"></i></a>
      </li>
      <li class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="workflows" routerLinkActive="$router_link-active$" matTooltip="Workflows"
          [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip" [routerLinkActiveOptions]="{ exact: true }"><i
            class="fas fa-table fa-lg"></i></a>
      </li>
      <li *ifRole="ns.processedRoles.join(',')" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="processedForms" routerLinkActive="$router_link-active$"
          matTooltip="Processed Forms" [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip"
          [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-file-signature fa-lg"></i></a>
      </li>
      <li class="nav-item nav-item-container" routerLinkActive="nav-active">
          <a class="nav-link" routerLink="history" routerLinkActive="$router_link-active$"
            matTooltip="History" [matTooltipPosition]="'right'" matTooltipClass="custom-tooltip"
            [routerLinkActiveOptions]="{ exact: true }"><i class="fas fa-history fa-lg"></i></a>
      </li>
    </ul>
  </div>
</nav>
