import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { WorkflowSummary } from '../core/models/workflowSummary.model';
import { CompensationReviewStatusType, CompensationReview } from '../core/models/compensationReview.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../core/services/snackbar.service';
import { DatePipe } from '@angular/common';
import { CompensationService } from '../core/services/compensation.service';
import { CompensationFormComponent, CompensationFormData } from '../compensation-form/compensation-form.component';
import { Employee } from '../core/models/employee.model';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from '../core/services/auth.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ProcessedFormsView } from '../core/models/processedFormsView.model';
import { StatusTypeEnum } from '../core/models/enums.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-processed-forms-list',
  templateUrl: './processed-forms-list.component.html',
  styleUrls: ['./processed-forms-list.component.scss'],
})
export class ProcessedFormsListComponent implements OnInit {
  bccView: boolean = false;

  @Input() set reviews(compensations: ProcessedFormsView[]) {
    compensations && compensations.forEach(rev => this.compensationService.getFormReviewsById(rev.compensationReviewId).subscribe(
      data => {
        if (data[0].status.find(dt => dt.statusTypeId === StatusTypeEnum.Acknowledged)) rev.isForce0 = true
      }, (error) => this.handleError(error)
    ));
    if (compensations) {
      this.isLoading = false;
      this.dataSource.data = compensations;
      this.dataSource.sortingDataAccessor = (row: ProcessedFormsView, columnName: string) => {
        switch (columnName) {
          case 'onlinerName':
            return row.onliner as string;
            break;
          case 'reviewerName':
            return row.reviewer as string;
            break;
          case 'practiceName':
            return row.practice as string;
            break;
          case 'serviceName':
            return row.serviceLine as string;
            break;
          case 'consultantLevel':
            return row.consultantLevel as string;
            break;
          case 'primaryCompetency':
            return row.primaryCompetency as string;
            break;
          case 'status':
            return (row.isForce0 ? row.compRvwStatusTypeName + "*" : row.compRvwStatusTypeName) as string;
            break;
          case 'lastStatusChanged':
            return new Date(row.statusDate).getTime();
            break;
          case 'daysSinceStatusChange':
            return this.daysSinceLastUpdateDisplay(row.statusDate);
            break;
          default:
            return row.onliner;
            break;
        }
      };
    }
  }
  @Input() unfilteredReviews: WorkflowSummary[];
  @Input() closedStatusTypes: CompensationReviewStatusType[];
  @Input() canEmail: boolean;
  @Input() set reviewId(id: number) {
    if (this.unfilteredReviews && id > 0) {
      const review = this.unfilteredReviews.find((r) => r.compensationReviewId === id);

      if (review) this.openForm(review);
    }
  }
  @Input() loggedInUser: Employee;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  isLoading: boolean = true;
  dataSource = new MatTableDataSource<ProcessedFormsView>();
  currReview: WorkflowSummary;
  isSending: boolean = false;
  finishedRefreshing: boolean;

  selection = new SelectionModel<WorkflowSummary>(true, []);

  get showTable() {
    return this.dataSource && this.dataSource.data;
  }

  initiatorHeaders = [
    'onlinerName',
    'reviewerName',
    'practiceName',
    'serviceName',
    'consultantLevel',
    'primaryCompetency',
    'status',
    'lastStatusChanged',
    'daysSinceStatusChange',
    'bccAll',
  ];

  headers = [
    'onlinerName',
    'reviewerName',
    'practiceName',
    'serviceName',
    'consultantLevel',
    'primaryCompetency',
    'status',
    'lastStatusChanged',
    'daysSinceStatusChange',
  ];

  resultColumnHeaders: string[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private snackBarService: SnackBarService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private compensationService: CompensationService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.bccView = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.resultColumnHeaders = this.bccView ? this.initiatorHeaders : this.headers;
    this.subscriptions.push(
      this.compensationService.reviewUpdated$.subscribe((result) => (this.finishedRefreshing = result ? false : true))
    );
  }

  ngOnDestroy() {
    this.snackBarService.clean();
  }

  ngOnChanges() {
    this.finishedRefreshing = true;
  }

  openForm = (review: CompensationReview) => {
    this.finishedRefreshing = false;
    let dialogWidth = '45%'; // default to desktop width
    // check if the device is mobile
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      dialogWidth = '90%'; // set to mobile width
    }
    this.dialog.open(CompensationFormComponent, {
      width: dialogWidth,
      height: '95vh',
      autoFocus: false,
      data: {
        reviews: [{ compensationReviewId: review.compensationReviewId }],
        closedStatusTypes: this.closedStatusTypes,
      } as CompensationFormData,
      disableClose: true,
    });
  };

  getForm = (reviewId: number) => {
    this.compensationService.getFormReviewsById(reviewId).subscribe(
      (result) => {
        return result;
      },
      (error) => this.handleError(error)
    );
  };

  statusDateDisplay = (statusDate: Date) => {
    return statusDate && this.datePipe.transform(statusDate, 'MM/dd/yyyy');
  };

  daysSinceLastUpdateDisplay = (statusDate: Date) => {
    if (!statusDate) return;
    const today = new Date();
    statusDate = new Date(statusDate);
    return Math.floor(Math.floor(today.getTime() - statusDate.getTime()) / (1000 * 60 * 60 * 24));
  };

  handleError(error: any) {
    if (error.ErrorType === ('MissingReviewException' || 'InvalidUserOperationException' || 'EmailNotSentException')) {
      this.snackBarService.message(error.ErrorMessage);
      this.isLoading = true;
    } else {
      this.snackBarService.message(error);
    }
    this.isSending = false;
  }
}
