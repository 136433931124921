import { Component, HostListener, Inject, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from './../core/services/auto-suggest.service';
import {
  CompensationService,
  defaultHistory,
  defaultVacayHistory,
} from '../core/services/compensation.service';
import { OnlinerService } from '../core/services/onliner.service';
import { SnackBarService } from './../core/services/snackbar.service';

import { Employee } from '../core/models/employee.model';
import { StatusTypeEnum } from '../core/models/enums.model';
import {
  CompensationReview,
  CompensationReviewAudit,
  CompensationReviewStatusType,
  CompensationReviewUpdate,
  ReviewCommentsAndAudits,
  CompensationReviewHistory,
  VacationReviewHistory,
  CompensationReviewComment
} from '../core/models/compensationReview.model';
import {
  ConfirmDialogData,
  ConfirmationDialogComponent,
} from './../shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

import * as formValidators from 'src/app/shared/form-validator';
import { DraftSummary, StatusType } from '../core/models/draftSummary.model';
import { PrintService } from '../core/services/print.service';
import { CompensationPrintForm, PrintCommentsAndAudits } from '../core/models/compensationPrintForm.model';
import { CommonService } from '../core/services/common.service';


export class CompensationFormData {
  reviews: CompensationReview[];
  draftSummary: DraftSummary;
  closedStatusTypes: CompensationReviewStatusType[];
}

interface IndexedAbstractControls {
  [key: string]: AbstractControl;
}

const roles = environment.roles;


@Component({
  selector: 'app-compensation-form',
  templateUrl: './compensation-form.component.html',
  styleUrls: ['./compensation-form.component.scss'],
})
export class CompensationFormComponent implements OnInit, OnDestroy {
  @Input() mobileQuery: boolean;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  expandHistory: boolean = false;
  bccEditable: boolean;
  isMostRecentProcessedForm: boolean;
  isPrinting: boolean = false;
  newCompensation: number;
  newVacation: number;
  previousCompensation: number;
  previousVacation: number;
  compensationEffectiveDate: Date;
  vacationEffectiveDate: Date;
  compensationIncrease: number;
  vacationDollars: string;

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.compensationForm.dirty;
  }

  compensationForm: UntypedFormGroup;
  isSubmitting: boolean;
  isSaving: boolean;
  isReviewing: boolean;
  isRejecting: boolean;
  locked: boolean;
  effDateUnlocked: boolean;
  canReject: boolean;
  canSubmit: boolean;
  canSave: boolean;
  canPrint: boolean;
  authorizedForDrafts: boolean;

  currentReview: CompensationReview;
  previousReview: CompensationReview;
  thirdLastReview: CompensationReview;
  initialReviewData: CompensationReviewUpdate;
  subscriptions: Subscription[] = [];
  modifiedReview: DraftSummary;

  onlinerView: boolean;
  bccView: boolean;
  seeBothRows: boolean;
  force0: string;
  minCompEffectiveDate: Date;
  minVacationEffectiveDate: Date;

  commentsAndAudits: ReviewCommentsAndAudits = { reviewId: 0, comments: [], audits: [] };
  
  activeReview: DraftSummary;

  hasPastComments: boolean;

  reviewerEditable: boolean;
  initiatorEditable: boolean;
  reviewers: Employee[];
  onliners: Employee[];
  onliner: Employee;
  filteredReviewers: Observable<Employee[]>;
  filteredOnliners: Observable<Employee[]>;
  statusTypesList: StatusType[] = [];

  isRegularUser: boolean;
  printData: CompensationPrintForm;


  get onlinerControl() {
    let bccControl = this.compensationForm.controls['bcc'] as UntypedFormGroup;
    return bccControl.controls['bccOnliner'];
  }

  get reviewerControl() {
    let headerControl = this.compensationForm.controls['header'] as UntypedFormGroup;
    return headerControl.controls['reviewer'];
  }
  get highlightForReviewer() {
    return this.currentReview && this.currentReview.statusType.statusTypeId == StatusTypeEnum.Initiated;
  }
  _submitBtnText = 'SUBMIT';
  get submitBtnText() {
    return this._submitBtnText;
  }

  errorMatcher = new formValidators.CompControlErrorStateMatcher();

  invalidEffectiveDate = (group: UntypedFormGroup, controlName: string, isComp: boolean) => {
    const currentCompensation = isComp ? this.getCurrentControl('compensations') : this.getCurrentControl('vacations');
    var newDate = new Date(currentCompensation.controls['effectiveDate'].value);
    var prevDate = isComp
      ? new Date(this.minCompEffectiveDate ? this.minCompEffectiveDate : null)
      : new Date(this.minVacationEffectiveDate ? this.minVacationEffectiveDate : null);

    if (group) {
      if (group.controls[controlName].touched) {
        if (prevDate) {
          return prevDate > newDate;
        }
      }
    }
  };

  showError = (group: 'header' | UntypedFormGroup, controlName: string) => {
    const control =
      group === 'header' ? this.reviewerControl : group.controls['isCurrent'] ? group.controls[controlName] : undefined;

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
  };

  showDateError(groupControl, name: 'effectiveDate') {
    if (groupControl.controls['isCurrent'].value) {
      const dateControl = groupControl.controls[name];
      if (dateControl.dirty) {
        if (dateControl.hasError('required') || dateControl.hasError('datePatternError')) {
          return true;
        }
      }
    }
  }


  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public initData: CompensationFormData,
    public dialogRef: MatDialogRef<CompensationFormComponent>,
    private authService: AuthService,
    private autoSuggestService: AutoSuggestService,
    private compensationService: CompensationService,
    private commonService: CommonService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private printService: PrintService

  ) { }

  async ngOnInit() {

    this.isRegularUser = this.authService.isRegularUser();
    this.setStatusTypesList();

    var reviewsData = await this.compensationService.getFormReviewsById(this.initData.reviews[0].compensationReviewId).toPromise();
    this.initData.reviews[0] = reviewsData[0] as CompensationReview;
    this.initData.reviews[0].statusType = this.statusTypesList.find(data => data.statusTypeId === this.initData.reviews[0].statusTypeId);

    if (this.isRegularUser) {
      this.reviewers = [this.initData.reviews[0].reviewerEmployee];
      this.onliners = [this.initData.reviews[0].onlinerEmployee];
    } else {
      this.reviewers = await this.onlinerService.getAssignedReviewers().toPromise();
      this.onliners = await this.onlinerService.getOnlinersExcludingDefault().toPromise();
    }

    this.setupForm(this.initData);

  }

  ngOnDestroy() {
    this.compensationService.reviewUpdated(this.modifiedReview, this.authorizedForDrafts);
    if (this.isPrinting) this.printService.printFormRequested();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private setStatusTypesList() {
    this.commonService.getAllCommonData().subscribe(data => {
      this.statusTypesList = data.statusTypes;
    });
  }

  private setupForm(data: CompensationFormData) {
    if (!data || data.reviews.length <= 0) return;

    (data.reviews[0].onlinerEmployee && !data.reviews[0].onlinerEmployee.compensationReviewHistory)
      ? (this.currentReview = data.reviews[0],
        this.setupUILocks(data.closedStatusTypes),
        this.buildCompensationForm())
      : (this.setFormReviewsDraftsOrWorkflows(data.reviews[0]),
        this.setupUILocks(data.closedStatusTypes),
        this.buildCompensationFormForActiveReview())

    this.compensationForm.addControl('bcc', this.createBCCFormGroup());

    this.getCommentsAndAudits();
    this.force0 = this.currentReview.isForce0 ? '*' : '';
    this.setReviewerFilter();
    this.setOnlinerFilter();

    this.handlePristine();

    var compensationDate = new Date(this.getCurrentControl('compensations').controls['effectiveDate'].value);
    if (this.minCompEffectiveDate && this.minCompEffectiveDate > compensationDate && this.formStatusCheck([+StatusTypeEnum.Initiated])) this.invalidDateModal();

  }

  private handlePristine() {
    this.initialReviewData = this.getCurrentFormData();
    this.subscriptions.push(
      this.compensationForm.valueChanges.pipe(debounceTime(500)).subscribe((v) => {
        const currData = this.getCurrentFormData();
        if (this.compensationService.isSameReviewData(this.initialReviewData, currData)) {
          this.compensationForm.markAsPristine();
        }
      })
    );
  }

  private getAllowedActions(allowedStatusIds: number[], checkRole: string[]) {
    if (!allowedStatusIds && !checkRole) return false;
    return allowedStatusIds.some((statusTypeId) => statusTypeId === this.currentReview.statusTypeId) &&
      this.authService.doesUserHaveRole(checkRole);
  };

  private isTheAuthenticatedUser(id: string) {
    let isTheAuthenticatedUser: boolean = false;
    this.onlinerService.getOnliners().subscribe(onliner => onliner.map(data => {
      if (data.accountingUserId == id && data.userId == this.authService.getUserId().toLowerCase()) isTheAuthenticatedUser = true
    }));
    return isTheAuthenticatedUser;
  }

  private setupUILocks(closedStatusTypes: CompensationReviewStatusType[]) {
    const currIsClosed = closedStatusTypes.some((c) => c.statusTypeId === this.currentReview.statusTypeId);

    const onlinerView = this.getAllowedActions(
      [+StatusTypeEnum.Reviewed, +StatusTypeEnum.Acknowledged, +StatusTypeEnum.Rejected, +StatusTypeEnum.Processed],
      [roles.CompApprovUser]
    ) && this.currentReview.onlinerEmployee.userId.toLowerCase() === this.authService.getUserId().toLowerCase();

    const peopleCareView = this.getAllowedActions(
      [
        +StatusTypeEnum.Processed,
        +StatusTypeEnum.Rejected,
        +StatusTypeEnum.New,
        +StatusTypeEnum.Initiated,
        +StatusTypeEnum.Reviewed,
        +StatusTypeEnum.Acknowledged,
      ],
      [roles.CompApprovInitiator]
    );

    const reviewerView = this.getAllowedActions([+StatusTypeEnum.Initiated], [roles.CompApprovReviewer]) &&
      this.isTheAuthenticatedUser(this.currentReview.reviewerEmployee.accountingUserId);

    const payrollView = this.getAllowedActions(
      [+StatusTypeEnum.Acknowledged, +StatusTypeEnum.Processed, +StatusTypeEnum.Rejected],
      [roles.CompApprovPayroll]
    );

    const adminView = this.authService.doesUserHaveRole([roles.CompApprovAdmin]);

    const canEdit = peopleCareView || reviewerView || adminView;

    this.authorizedForDrafts = this.authService.doesUserHaveRole([roles.CompApprovAdmin, roles.CompApprovInitiator]);

    this.seeBothRows = peopleCareView || this.authService.doesUserHaveRole([roles.CompApprovReviewer]) || payrollView || adminView;

    this.locked =
      currIsClosed ||
      onlinerView ||
      payrollView ||
      !canEdit ||
      this.formStatusCheck([+StatusTypeEnum.Reviewed, +StatusTypeEnum.Acknowledged]);

    this.effDateUnlocked =
      this.formStatusCheck([+StatusTypeEnum.Acknowledged]) &&
      this.currentReview.isForce0 &&
      this.authService.doesUserHaveRole([roles.CompApprovAdmin, roles.CompApprovInitiator]);

    this.canSave =
      (!currIsClosed &&
        this.formStatusCheck([
          +StatusTypeEnum.New,
          +StatusTypeEnum.Initiated,
          +StatusTypeEnum.Reviewed,
          +StatusTypeEnum.Acknowledged,
        ]) &&
        (peopleCareView || adminView)) ||
      (this.formStatusCheck([+StatusTypeEnum.New, +StatusTypeEnum.Initiated]) && reviewerView);

    this.canSubmit =
      (this.formStatusCheck([+StatusTypeEnum.New]) && (peopleCareView || adminView || reviewerView)) ||
      (this.formStatusCheck([+StatusTypeEnum.Initiated]) && adminView) ||
      reviewerView ||
      (this.formStatusCheck([+StatusTypeEnum.Reviewed]) && onlinerView) ||
      (this.formStatusCheck([+StatusTypeEnum.Acknowledged]) && payrollView);

    this.canReject =
      (this.formStatusCheck([+StatusTypeEnum.Initiated]) && (peopleCareView || reviewerView)) ||
      (this.formStatusCheck([+StatusTypeEnum.Reviewed]) && ( (peopleCareView && !onlinerView) || (adminView && !onlinerView))) ||
      (this.formStatusCheck([+StatusTypeEnum.Acknowledged]) && (payrollView || peopleCareView || adminView));

    this.canPrint = this.formStatusCheck([+StatusTypeEnum.Acknowledged, +StatusTypeEnum.Processed]) && payrollView;

    this.bccEditable =
      this.formStatusCheck([
        +StatusTypeEnum.New,
        +StatusTypeEnum.Initiated,
        +StatusTypeEnum.Reviewed,
      ]) &&
      (peopleCareView || adminView);

    this.reviewerEditable = true;

    this.initiatorEditable = true;

    if ((this.currentReview.compensationReviewId != 0
      && this.locked
      && !this.currentReview.isForce0
      && this.formStatusCheck([+StatusTypeEnum.Acknowledged]))
      || (this.reviewers
        && !this.reviewers.some((reviewer) => reviewer.accountingUserId === this.currentReview.reviewerEmployee.accountingUserId)
        && !peopleCareView)) {
      this.reviewerEditable = false;
    }

    if (
      (this.locked && !(this.currentReview.isForce0 && this.formStatusCheck([+StatusTypeEnum.Acknowledged
      ]))) || !(peopleCareView || adminView)
    ) {
      this.initiatorEditable = false;
    }

    if (
      this.authService.doesUserHaveRole([roles.CompApprovReviewer]) &&
      this.formStatusCheck([+StatusTypeEnum.Initiated]) &&
      this.currentReview.reviewerEmployee.userId != this.authService.getUserId().toLowerCase() &&
      !peopleCareView
    ) {
      this.canReject = false;
    }

    if (peopleCareView || reviewerView || adminView) this._submitBtnText = 'SUBMIT';
    if (this.formStatusCheck([+StatusTypeEnum.Initiated])) this._submitBtnText = 'APPROVE';
    if (onlinerView || this.formStatusCheck([+StatusTypeEnum.Reviewed])) this._submitBtnText = 'ACKNOWLEDGE';
    if (payrollView || this.formStatusCheck([+StatusTypeEnum.Acknowledged])) this._submitBtnText = 'PROCESS';

    this.onlinerView = onlinerView;
    this.bccView = this.authService.doesUserHaveRole([roles.CompApprovInitiator, roles.CompApprovAdmin]);
  }

  private getCommentsAndAudits = () => {
    let last = {} as CompensationReviewAudit;
    if (this.currentReview.audits) {
      this.currentReview.audits = this.currentReview.audits.sort((a, b) => 0 - (a.auditDate < b.auditDate ? 1 : -1));
      this.currentReview.comments = this.currentReview.comments.sort(
        (a, b) => 0 - (a.commentDate < b.commentDate ? 1 : -1)
      );
      const audits = this.currentReview.audits
        .map((a) => {
          if (last.statusTypeId !== a.statusTypeId) {
            last = { ...a };
            return a;
          }
          if (last.reviewerEmployeeId !== a.reviewerEmployeeId) {
            const info = `${last.reviewerEmployee.firstName} ${last.reviewerEmployee.lastName} to ${a.reviewerEmployee.firstName} ${a.reviewerEmployee.lastName}`;
            last = { ...a };
            return { ...a, statusType: { statusTypeName: 'Re-assigned' }, info } as unknown as CompensationReviewAudit;
          }
          return null;
        })
        .filter((a) => a !== null);
      this.commentsAndAudits = {
        reviewId: this.currentReview.compensationReviewId,
        comments: [...this.currentReview.comments],
        audits: [...audits],
      };
      setTimeout(() => { this.buildForm(); }, 0);
      if (this.commentsAndAudits.comments.length > 0) {
        this.expandHistory = true;
      }
    }
    
  };

  buildForm() {
    let compReviewUpdate = this.getCurrentFormData();
    this.printData = new CompensationPrintForm();
    this.printData.commentsAndAudits = new PrintCommentsAndAudits();
    this.printData.commentsAndAudits.comments = [];
    this.printData.commentsAndAudits.audits = [];

    this.printData.compensationReviewId = compReviewUpdate.reviewId;
    this.printData.compensationEffectiveDate = this.compensationEffectiveDate;
    this.printData.previousCompensation = this.previousCompensation;
    this.printData.newCompensation = this.newCompensation;
    this.printData.compensationIncrease = this.newCompensation - this.previousCompensation;
    this.printData.vacationEffectiveDate = this.vacationEffectiveDate;
    this.printData.previousVacation = this.previousVacation;
    this.printData.newVacation = this.newVacation;
    this.printData.vacationDollars = this.vacationDollars;

    this.printData.onlinerName =
      this.initData.reviews[0].onlinerEmployee.firstName + ' ' + this.initData.reviews[0].onlinerEmployee.lastName;
    this.printData.reviewerName =
      this.initData.reviews[0].reviewerEmployee.firstName + ' ' + this.initData.reviews[0].reviewerEmployee.lastName;
    this.printData.startDate = this.initData.reviews[0].startDate;
    if (this.commentsAndAudits && this.commentsAndAudits.comments.length > 0) {
      this.commentsAndAudits.comments.forEach((comm) => {
        this.printData.commentsAndAudits.comments.push({
          commentDate: comm.commentDate,
          commentEmployeeName: comm.commentEmployee.firstName + ' ' + comm.commentEmployee.lastName,
          commentNote: comm.commentNote,
          commentTypeDescription: comm.commentType.name,
        });
      });
    };

    if (this.commentsAndAudits && this.commentsAndAudits.audits.length > 0) {
      this.commentsAndAudits.audits.forEach((audit) => {
        this.printData.commentsAndAudits.audits.push({
          auditDate: audit.auditDate,
          amount: '$' + audit.amount,
          amountEffectiveDate: audit.amountEffectiveDate,
          reviewerName: audit.reviewerEmployee.firstName + ' ' + audit.reviewerEmployee.lastName,
          statusDescription: audit.statusType.statusTypeName,
          updateEmployee: audit.updateEmployee.firstName + ' ' + audit.updateEmployee.lastName,
          vacationDays: audit.vacationDays,
          vacationDaysEffectiveDate: audit.vacationDaysEffectiveDate,
          info: audit.info,
        });
      });
    };

    this.printData.isForce0 = this.force0 == '*' ? true : false;
    this.printData.anniversaryDate = this.initData.reviews[0].anniversaryDate;
    this.printData.statusDescription = this.initData.reviews[0].statusType.statusTypeName;

    this.printService.buildPrintForm(this.printData);
  }

  private setFormReviewsDraftsOrWorkflows(cr: CompensationReview) {
    const ensureDataIntegrity = (ar: CompensationReview) => {
      ar.onlinerEmployee.compensationReviewHistory =
        ar.onlinerEmployee.compensationReviewHistory && ar.onlinerEmployee.compensationReviewHistory.length > 0
          ? ar.onlinerEmployee.compensationReviewHistory
          : [defaultHistory];
      ar.onlinerEmployee.vacationReviewHistory =
        ar.onlinerEmployee.vacationReviewHistory && ar.onlinerEmployee.vacationReviewHistory.length > 0
          ? ar.onlinerEmployee.vacationReviewHistory
          : [defaultVacayHistory];
      return ar;
    };

    this.currentReview = ensureDataIntegrity(cr);
  }

  private formStatusCheck = (formStatusIds: number[]) => {
    return formStatusIds.some((statusTypeId) => statusTypeId === this.currentReview.statusTypeId);
  };

  private buildCompensationFormForActiveReview(): UntypedFormGroup {
    this.compensationForm = this.formBuilder.group({
      header: this.createHeaderFormGroup(),
      compensations: this.formBuilder.array([
        this.createCompensationFormGroupForActiveReview(true, this.currentReview),
        this.createCompensationFormGroupForActiveReview(false, this.currentReview),
      ]),
      vacations: this.formBuilder.array([
        this.createVacationFormGroupForActiveReview(true, this.currentReview),
        this.createVacationFormGroupForActiveReview(false, this.currentReview),
      ]),
      comment: [''],
    });

    return this.compensationForm;
  }

  private buildCompensationForm(): UntypedFormGroup {
    this.compensationForm = this.formBuilder.group({
      header: this.createHeaderFormGroup(),
      compensations: this.formBuilder.array([
        this.createCompensationFormGroup(true, this.currentReview),
        this.createCompensationFormGroup(false, this.currentReview),
      ]),
      vacations: this.formBuilder.array([
        this.createVacationFormGroup(true, this.currentReview),
        this.createVacationFormGroup(false, this.currentReview),
      ]),
      comment: [''],
    });

    return this.compensationForm;
  }

  private createHeaderFormGroup(): UntypedFormGroup {
    const currReviewer = this.currentReview.reviewerEmployee;
    return this.formBuilder.group({
      onliner: [''],
      reviewer: [{ value: currReviewer, disabled: !this.initiatorEditable }, Validators.required],
      anniversary: [''],
      status: [''],
      startDate: [''],
    });
  }

  private createBCCFormGroup(): UntypedFormGroup {
    var currBCC = !this.currentReview.bccAll ? '' : this.onliners.find((r) => r.userId === this.currentReview.bccAll);
    return this.formBuilder.group({
      bccOnliner: [{ value: currBCC, disabled: !this.bccEditable }],
    });
  }

  private createCompensationFormGroupForActiveReview(isCurrent: boolean, currentReview: CompensationReview): UntypedFormGroup {
    const currentReviewCompHistory = currentReview.onlinerEmployee.compensationReviewHistory;

    this.assignMinDate(true, isCurrent, currentReview, true);

    if (isCurrent) {
      this.compensationEffectiveDate = currentReview.history[0].effectiveDate;
      this.newCompensation = currentReview.history[0].amount;
    } else {
      this.previousCompensation = currentReviewCompHistory[0].amount;
    }

    return this.formBuilder.group({
      effectiveDate: [
        {
          value: isCurrent ? currentReview.history[0].effectiveDate : currentReviewCompHistory[0].effectiveDate,
          disabled: (this.locked || !isCurrent) && !this.effDateUnlocked,
        },
        Validators.compose([Validators.required, formValidators.checkDatePattern]),
      ],
      newCompensation: [
        {
          value: isCurrent ? currentReview.history[0].amount : currentReviewCompHistory[0].amount,
          disabled: this.locked || !isCurrent,
        },
        Validators.required,
      ],
      previousCompensation: [
        {
          value: isCurrent
            ? currentReviewCompHistory[0].amount
            : currentReviewCompHistory.length < 2
              ? 0
              : currentReviewCompHistory[1].amount,
          disabled: true,
        },
      ],
      increase: [''],
      isCurrent: [isCurrent],
    });
  }

  private createVacationFormGroupForActiveReview(isCurrent: boolean, currentReview: CompensationReview): UntypedFormGroup {
    this.assignMinDate(false, isCurrent, currentReview, true);

    if (isCurrent) {
      this.vacationEffectiveDate = currentReview.vacationHistory[0].effectiveDate;
      this.newVacation = currentReview.vacationHistory[0].days / 7;
    } else {
      this.previousVacation = currentReview.onlinerEmployee.vacationReviewHistory[0].days / 7;
    }

    return this.formBuilder.group({
      effectiveDate: [
        {
          value: isCurrent
            ? currentReview.vacationHistory[0].effectiveDate
            : currentReview.onlinerEmployee.vacationReviewHistory[0].effectiveDate,
          disabled: (this.locked || !isCurrent) && !this.effDateUnlocked,
        },
        Validators.compose([Validators.required, formValidators.checkDatePattern]),
      ],
      newVacation: [
        {
          value: isCurrent
            ? currentReview.vacationHistory[0].days / 7
            : currentReview.onlinerEmployee.vacationReviewHistory[0].days / 7,
          disabled: this.locked || !isCurrent,
        },
        Validators.required,
      ],
      previousVacation: [
        {
          value: isCurrent
            ? currentReview.onlinerEmployee.vacationReviewHistory[0].days / 7
            : currentReview.onlinerEmployee.vacationReviewHistory.length < 2
              ? 0
              : currentReview.onlinerEmployee.vacationReviewHistory[1].days / 7,
          disabled: true,
        },
      ],
      isCurrent: [isCurrent],
    });
  }

  private createCompensationFormGroup(isCurrent: boolean, currentReview: CompensationReview): UntypedFormGroup {
    this.assignMinDate(true, isCurrent, currentReview, false);

    if (isCurrent) {
      this.compensationEffectiveDate = currentReview.history[0].effectiveDate;
      this.newCompensation = currentReview.history[0].amount;
      this.previousCompensation = currentReview.history[0].previousAmount;
    }

    return this.formBuilder.group({
      effectiveDate: [
        {
          value: isCurrent ? currentReview.history[0].effectiveDate : currentReview.history[0].previousEffectiveDate,
          disabled: !isCurrent || (this.locked && !this.effDateUnlocked),
        },
        Validators.compose([Validators.required, formValidators.checkDatePattern]),
      ],
      previousCompensation: [
        {
          value: isCurrent ? currentReview.history[0].previousAmount : currentReview.history[0].prevPreviousAmount,
          disabled: true,
        },
      ],
      newCompensation: [
        {
          value: isCurrent ? currentReview.history[0].amount : currentReview.history[0].previousAmount,
          disabled: this.locked || !isCurrent,
        },
        Validators.required,
      ],
      increase: [''],
      isCurrent: [isCurrent],
    });
  }

  private createVacationFormGroup(isCurrent: boolean, currentReview: CompensationReview): UntypedFormGroup {
    this.assignMinDate(false, isCurrent, currentReview, false);

    if (isCurrent) {
      this.vacationEffectiveDate = currentReview.vacationHistory[0].effectiveDate;
      this.newVacation = currentReview.vacationHistory[0].days / 7;
      this.previousVacation = currentReview.vacationHistory[0].previousDays / 7;
    }

    return this.formBuilder.group({
      effectiveDate: [
        {
          value: isCurrent
            ? currentReview.vacationHistory[0].effectiveDate
            : currentReview.vacationHistory[0].previousEffectiveDate,
          disabled: !isCurrent || (this.locked && !this.effDateUnlocked),
        },
        Validators.compose([Validators.required, formValidators.checkDatePattern]),
      ],
      previousVacation: [
        {
          value: isCurrent
            ? currentReview.vacationHistory[0].previousDays / 7
            : currentReview.vacationHistory[0].daysBeforePreviousDays / 7,
          disabled: true,
        },
      ],
      newVacation: [
        {
          value: isCurrent
            ? currentReview.vacationHistory[0].days / 7
            : currentReview.vacationHistory[0].previousDays / 7,
          disabled: this.locked || !isCurrent,
        },
        Validators.required,
      ],
      isCurrent: [isCurrent],
    });
  }

  private assignMinDate(
    isComp: boolean,
    isCurrent: boolean,
    currentReview: CompensationReview,
    isActiveReview: boolean
  ) {
    var currentReviewHistory: CompensationReviewHistory[] | VacationReviewHistory[] = null;

    if (isCurrent) {
      if (currentReview.onlinerEmployee && isComp) {
        this.minCompEffectiveDate = new Date(currentReview.startDate);
        let previousEffectiveDate = this.getPreviousEffectiveDate(currentReview);

        if (previousEffectiveDate) {
          this.minCompEffectiveDate = previousEffectiveDate;
        }
      } else if (currentReview.onlinerEmployee && !isComp) {
        this.minVacationEffectiveDate = new Date(currentReview.startDate);
        let previousEffectiveDate = this.getPreviousEffectiveDate(currentReview);

        if (previousEffectiveDate) {
          this.minVacationEffectiveDate = previousEffectiveDate;
        }
      }
    } else if (isActiveReview && !isCurrent) {
      if (currentReview.onlinerEmployee && isComp) {
        this.minCompEffectiveDate = new Date(currentReview.onlinerEmployee.compensationReviewHistory[0].effectiveDate);
      } else if (currentReview.onlinerEmployee && !isComp) {
        this.minVacationEffectiveDate = new Date(currentReview.onlinerEmployee.vacationReviewHistory[0].effectiveDate);
        this.minVacationEffectiveDate.setDate(this.minVacationEffectiveDate.getDate() + 1);
      }
    } else {
      if (currentReview.onlinerEmployee && isComp) {
        currentReviewHistory = currentReview.history;

        this.minCompEffectiveDate = new Date(currentReview.startDate);
        let previousEffectiveDate = this.getPreviousEffectiveDate(currentReview);

        if (previousEffectiveDate) {
          this.minCompEffectiveDate = previousEffectiveDate;
        }
      } else if (currentReview.onlinerEmployee && !isComp) {
        let currentVacationHistory = currentReview.vacationHistory;

        this.minVacationEffectiveDate = this.ifCurrentVacationHistoryAndHasEffectiveDate(currentVacationHistory)
          ? new Date(currentVacationHistory[0].effectiveDate)
          : new Date(currentReview.startDate);

        this.minVacationEffectiveDate.setDate(this.minVacationEffectiveDate.getDate() + 1);
      }
    }

    if (this.formStatusCheck([+StatusTypeEnum.New])) {
      this.minCompEffectiveDate = null;
    }
  }

  private ifCurrentReviewHistoryAndHasEffectiveDate(currentReviewHistory: CompensationReviewHistory[]) {
    return (
      currentReviewHistory.length > 1 || (currentReviewHistory.length == 1 && currentReviewHistory[0].effectiveDate)
    );
  }

  private ifCurrentVacationHistoryAndHasEffectiveDate(currentVacationHistory: VacationReviewHistory[]) {
    return (
      currentVacationHistory.length > 1 ||
      (currentVacationHistory.length == 1 && currentVacationHistory[0].effectiveDate)
    );
  }

  private getPreviousEffectiveDate(currentReview: CompensationReview) {
    return currentReview.history[0].previousEffectiveDate;
  }

  getOnlinerDisplay() {
    if (this.currentReview) {
      const onlinerEmployee = this.currentReview.onlinerEmployee;
      return `${onlinerEmployee.firstName} ${onlinerEmployee.lastName}`;
    }
  }

  setReviewerFilter() {
    this.filteredReviewers = this.autoSuggestService.setOnlinerFilter(this.reviewerControl, this.reviewers);
  }

  reviewerDisplay = (option?: Employee): string | undefined => {
    return this.autoSuggestService.onlinerDisplay(option);
  };

  setOnlinerFilter() {
    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(this.onlinerControl, this.onliners);
  }

  onlinerDisplay = (option?: Employee): string | undefined => {
    return this.autoSuggestService.onlinerDisplay(option);
  };

  getIncrease({
    newCompensation: { value: currentAmount },
    previousCompensation: { value: previousAmount },
  }: IndexedAbstractControls): string {
    const diff = +currentAmount - +previousAmount;

    const percentageIncrease = previousAmount > 0 ? ((diff / previousAmount) * 100).toFixed(2) : '~';
    return `$${diff} (${percentageIncrease}%)`;
  }

  getVacationDollarsPerSalaryText(
    {
      compensations: {
        controls: [{ controls: newComp }, { controls: prevComp }],
      },
      vacations: {
        controls: [{ controls: newVac }, { controls: prevVac }],
      },
    },
    isCurrent: boolean
  ): string {
    const {
      newCompensation: { value: newCompensation },
      previousCompensation: { value: previousCompensation },
    }: IndexedAbstractControls = isCurrent ? newComp : prevComp;
    const {
      newVacation: { value: newVacation },
      previousVacation: { value: previousVacation },
    }: IndexedAbstractControls = isCurrent ? newVac : prevVac;
    const vacationDollars = ((newCompensation / 52) * newVacation).toFixed(2);
    this.vacationDollars = vacationDollars;
    return `${vacationDollars}`;
  }

  closeModal = (result: string = null) => this.dialogRef.close(result);

  confirmReject() {
    const formData = this.getCurrentFormData();
    if (formData.comment && formData.comment.length > 0) {
      this.openDialog(
        {
          title: 'Form Reject',
          message: 'Please confirm you wish to Reject this Compensation Review.',
          okButtonTitle: 'Reject',
          cancelButtonTitle: 'Cancel',
          theme: 'danger',
        } as ConfirmDialogData,
        this.rejectReview
      );
    } else {
      this.openDialog({
        title: 'Form Reject',
        message: 'Before you can reject a review, You must enter an explanation in the Comments box above.',
        okButtonTitle: 'OK',
        theme: 'danger',
      } as ConfirmDialogData);
    }
  }
  confirmReviewStep() {
    this.openDialog(
      {
        title: 'Form Submit',
        message: 'Continue with the submission?',
        okButtonTitle: 'Yes',
        cancelButtonTitle: 'No',
        theme: 'success',
      } as ConfirmDialogData,
      this.processReviewStep
    );
  }
  confirmSaveStep() {
    const currentData = this.getCurrentFormData();
    if (!this.compensationService.isSameReviewData(this.initialReviewData, currentData)) {
      this.compensationForm.disable(); 
      this.isSaving = true;
      this.compensationService.saveReview(currentData).subscribe(
        (result) => {
          this.currentReview.comments = [...result.comments];
          this.currentReview.audits = [...result.audits];
          this.snackBarService.message(`Review saved!`);
          /*reset*/
          this.compensationForm.controls['comment'].setValue('');
          this.initialReviewData = {
            ...currentData,
            history: { ...currentData.history },
            vacationHistory: { ...currentData.vacationHistory },
            comment: '',
          };
          this.compensationForm.markAsPristine();
          setTimeout(() => {
            this.modifiedReview = result;
            this.closeModal('updated');
            this.isSaving = false;
            this.compensationForm.enable(); 
          }, 3000);
        },
        (error) => {
          if (error.error.ErrorType == 'InvalidReviewDate') {
            this.handleSaveError(error);
          } else {
            this.handleError(error);
          }
        }
      );
    }
  }

  handleError(error: any) {
    if (error.ErrorType === ('MissingReviewException' || 'InvalidUserOperationException')) {
      this.snackBarService.message(error.ErrorMessage);
    } else {
      this.snackBarService.message('Could not find review');
    }
    this.isSubmitting = false;
    this.openErrorNotification();
    this.closeModal();
  }

  handleSaveError(error: any) {
    this.isSubmitting = false;
    this.openSaveErrorNotification();
    this.closeModal();
  }

  openErrorNotification() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Error Encountered',
        theme: 'danger',
        message: '<p>An error has been encountered, please refresh your browser to continue.</p>',
        okButtonTitle: 'Okay',
      } as ConfirmDialogData,
      disableClose: true,
    });
  }

  openSaveErrorNotification() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Error Encountered',
        theme: 'danger',
        message: '<p>The Review Compensation date must be after the last review date.</p>',
        okButtonTitle: 'Okay',
      } as ConfirmDialogData,
      disableClose: true,
    });
  }

  invalidDateModal() {
    this.openDialog({
      title: 'Invalid Date',
      message:
        'One or more effective dates are prior to their respective previous effective dates. Please fix them in order to action the form',
      okButtonTitle: 'OK',
      theme: 'danger',
    } as ConfirmDialogData);
  }

  openDialog(data: ConfirmDialogData, callBack: () => any = null) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok' && callBack) {
        callBack();
      }
    });
  }

  rejectReview = () => {
    this.compensationForm.disable();
    this.isRejecting = true;
    this.compensationService.rejectReview(this.getCurrentFormData()).subscribe(
      (result) => {
        this.snackBarService.message(`Review Rejected!`);
        this.modifiedReview = result;
        this.closeModal('rejected');
        this.isRejecting = false;
        this.compensationForm.enable();
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  processReviewStep = () => {
    this.compensationForm.disable();
    this.isReviewing = true;
    var currReview = this.getCurrentFormData();

    if (!this.compensationService.isSameReviewData(this.initialReviewData, currReview) && this.canSave) {
      this.compensationService.saveReview(currReview).subscribe(
        (result) => {
          currReview.comment = null;
          this.compensationService.updateReview(currReview).subscribe(
            (result) => {
              this.currentReview.comments = [...result.comments];
              this.currentReview.audits = [...result.audits];
              this.snackBarService.message(`Review Updated!`);
              this.modifiedReview = result;
              this.closeModal('updated');
              this.isReviewing = false;
              this.compensationForm.enable();
            },
            (error) => {
              this.handleError(error);
            }
          );
        },
        (error) => {
          this.handleError(error);
        }
      );
    } else {
      this.compensationService.updateReview(currReview).subscribe(
        (result) => {
          this.currentReview.comments = [...result.comments];
          this.currentReview.audits = [...result.audits];
          this.snackBarService.message(`Review Updated!`);
          setTimeout(() => {
            this.modifiedReview = result;
            this.closeModal('updated');
            this.isSubmitting = false;
            this.compensationForm.enable();
          }, 3000);
        },
        (error) => {
          if (error.error.ErrorType == 'InvalidReviewDate') {
            this.handleSaveError(error);
          } else {
            this.handleError(error);
          }
        }
      );
    }
  };

  private getCurrentControl(name: 'compensations' | 'vacations'): UntypedFormGroup {
    const compensations = this.compensationForm.controls[name] as UntypedFormArray,
      currentFormGroup = (compensations.controls as UntypedFormGroup[]).find((cg) => cg.controls['isCurrent'].value);

    return currentFormGroup;
  }

  private getCurrentFormData(): CompensationReviewUpdate {
    const currentCompensation = this.getCurrentControl('compensations'),
      currentVacation = this.getCurrentControl('vacations');
    return {
      reviewId: this.currentReview.compensationReviewId,
      onlinerEmployeeId: this.currentReview.onlinerEmployeeId,
      reviewerEmployeeId: (
        (this.compensationForm.controls['header'] as UntypedFormGroup).controls['reviewer'].value as Employee
      ).accountingUserId,
      reviewerEmployee: (this.compensationForm.controls['header'] as UntypedFormGroup).controls['reviewer'].value as Employee,
      history: {
        effectiveDate: currentCompensation.controls['effectiveDate'].value,
        amount: currentCompensation.controls['newCompensation'].value,
      },
      vacationHistory: {
        effectiveDate: currentVacation.controls['effectiveDate'].value,
        days: currentVacation.controls['newVacation'].value * 7,
      },
      comment: this.compensationForm.controls['comment'].value,
      bccAll:
        !(this.compensationForm.controls['bcc'] as UntypedFormGroup).controls['bccOnliner'].value
          ? ''
          : ((this.compensationForm.controls['bcc'] as UntypedFormGroup).controls['bccOnliner'].value as Employee).userId,
    };
  }

  private onPrintForm() {
    this.openDialog(
      {
        title: 'Print Confirmation',
        message: 'Please confirm you wish to Print this Compensation Review.',
        okButtonTitle: 'Print',
        cancelButtonTitle: 'Cancel',
        theme: 'danger',
      } as ConfirmDialogData,
      this.beginPrint.bind(this)
    );
  }

  private beginPrint() {
    this.isPrinting = true;
    this.dialogRef.close();
  }

  getEmployeeName = (employee: Employee) => (employee ? `${employee.lastName}, ${employee.firstName}` : '');
  formatComment = (comment: string) => comment.replace(/\n/g, `<br>`);
}
