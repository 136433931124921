<div class="component-container">
    <div class="mb-3">
        <h3 class="page-title">Reviewer Configuration</h3>
    </div>
    <br>
    <div class="mb-3">
        <mat-radio-group aria-label="new-options-group" [(ngModel)]="newReviewerFormOption" name="radioButtons">
            <mat-radio-button *ngFor="let item of selections" [value]="item.id" [name]="item.id">
                {{item.name}}
            </mat-radio-button>
        </mat-radio-group>
        <div *ngIf="newReviewerFormOption == 'add'" class="p-3">
            <app-add-reviewer></app-add-reviewer>
        </div>
        <div *ngIf="newReviewerFormOption == 'assign'" class="p-3">
            <app-assign-reviewer></app-assign-reviewer>
        </div>

    </div>
</div>