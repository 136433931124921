import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { roles } from './roles.model';

export const sideMenu: NavigationItem[] = [

  {
    type: 'link',
    label: 'Forms',
    route: 'newforms',
    icon: 'mat:search',
    roles: [roles.admin,roles.init, roles.payroll, roles.reviewer],
  },
  {
    type: 'link',
    label: 'Drafts',
    route: 'drafts',
    icon: 'mat:description',
    roles: [roles.admin, roles.init],
  },
  {
    type: 'link',
    label: 'Workflows',
    route: 'workflows',
    icon: 'mat:drafts',
    roles: [roles.user],
  },
  {
    type: 'link',
    label: 'Processed Forms',
    route: 'processedForms',
    icon: 'mat:folder',
    roles: [roles.admin, roles.init, roles.payroll, roles.reviewer],
  },
  {
    type: 'link',
    label: 'History',
    route: 'history',
    icon: 'mat:history',
    roles: [roles.user],
  },
];
