<div *ngIf="!isLoading; else loading" class="sticky-table">
  <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.data.length > 0">
    <!-- Onliner Column -->
    <ng-container matColumnDef="onlinerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
      <td mat-cell class="text-left" *matCellDef="let review" class="leftAlignCell">
        {{ review.onliner }}
      </td>
    </ng-container>

    <!-- Reviewer Column -->
    <ng-container matColumnDef="reviewerName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Reviewer</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.reviewer }}</td>
    </ng-container>

    <!-- Practice Column -->
    <ng-container matColumnDef="practiceName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Practice</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.practice }}</td>
    </ng-container>

    <!-- Service Column -->
    <ng-container matColumnDef="serviceName">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Service</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.serviceLine }}</td>
    </ng-container>

    <!-- Consultant Level Column -->
    <ng-container matColumnDef="consultantLevel">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Consultant Level</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.consultantLevel }}</td>
    </ng-container>

    <!-- Primary Competency Column -->
    <ng-container matColumnDef="primaryCompetency">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Primary Competency</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.primaryCompetency }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell class="text-left" *matCellDef="let review">
        {{ review.isForce0 ? review.compRvwStatusTypeName + "*" : review.compRvwStatusTypeName}}
        <span *ngIf="loadingForm"><i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
      </td>
    </ng-container>

    <!-- Last Status Changed Date Column -->
    <ng-container matColumnDef="lastStatusChanged">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Last Status Changed Date</th>
      <td mat-cell class="text-center" *matCellDef="let review">
        {{ statusDateDisplay(review.statusDate) }}
      </td></ng-container
    >

    <!-- Number of Days since Last Update Column -->
    <ng-container matColumnDef="daysSinceStatusChange">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Number of Days since Last Update</th>
      <td mat-cell class="text-center" *matCellDef="let review">
        {{ daysSinceLastUpdateDisplay(review.statusDate) }}
      </td></ng-container
    >

    <!-- BCC All Column -->
    <ng-container matColumnDef="bccAll" *ngIf="bccView">
      <th mat-header-cell class="text-left" *matHeaderCellDef>BCC</th>
      <td mat-cell class="text-left" *matCellDef="let review">{{ review.bcc }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders; sticky: true" [attr.]></tr>
    <tr
      mat-row
      class="mat-row-clickable"
      *matRowDef="let row; columns: resultColumnHeaders"
      [ngClass]="{ 'row-hide': row.compensationReviewId == 0 }"
      style="cursor: pointer"
      (click)="openForm(row)"
    ></tr>
  </table>
  <div *ngIf="dataSource.data.length === 0">No reviews found</div>
</div>

<ng-template #loading>
  <div class="loading m-auto"></div>
</ng-template>
