import {throwError, Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AuthorizedHttp} from './authorized-http.service';
import { WorkflowSummary } from '../models/workflowSummary.model';
import { WorkflowPageViewSummary } from '../models/workflowPageViewSummary.model';


@Injectable()
export class EmailService {

  private apiEndpoint: string = `${environment.apiEndpoint}/api/email`;

  constructor(private http: AuthorizedHttp) {
  }

  public emailUserRegardingReview(review: WorkflowPageViewSummary): Observable<any> {
    return this.http 
      .post(`${this.apiEndpoint}/emailRegardingReview`, review)
      .pipe(
        catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Error sending email'))
      );
  }

  public massEmailUsersRegardingReviews(reviews: WorkflowSummary[]): Observable<any> {
    return this.http
      .post(`${this.apiEndpoint}/massReviewEmail`, reviews)
      .pipe(
        catchError((errorResponse: any) => throwError(errorResponse.ErrorMessage || 'Error with mass emails'))
      );
  }

}
