<div *ngIf="!isLoading; else loading" class="sticky-table mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource.filteredData.length > 0">
    <!-- Onliner Column -->
    <ng-container matColumnDef="onliner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
      <td mat-cell class="text-left" *matCellDef="let draft" class="leftAlignCell">
        {{ draft.onliner }}
      </td>
    </ng-container>

    <!-- Reviewer Column -->
    <ng-container matColumnDef="reviewer">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Reviewer</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.reviewer }}</td>
    </ng-container>

    <!-- Practice Column -->
    <ng-container matColumnDef="practice">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Practice</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.practice }}</td>
    </ng-container>

    <!-- Service Column -->
    <ng-container matColumnDef="serviceLine">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Service</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.serviceLine }}</td>
    </ng-container>

    <!-- Consultant Level Column -->
    <ng-container matColumnDef="consultantLevel">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Consultant Level</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.consultantLevel }}</td>
    </ng-container>

    <!-- Primary Competency Column -->
    <ng-container matColumnDef="primaryCompetency">
      <th mat-header-cell class="text-left" *matHeaderCellDef mat-sort-header>Primary Competency</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.primaryCompetency }}</td>
    </ng-container>

    <!-- BCC All Column -->
    <ng-container matColumnDef="bcc">
      <th mat-header-cell class="text-left" *matHeaderCellDef>BCC</th>
      <td mat-cell class="text-left" *matCellDef="let draft">{{ draft.bcc }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let draft"
        class="leftAlignCell"
        (click)="openDeleteConfirmation(draft, $event); clicked = true"
      >
        <div *ngIf="allowDelete()">
          <span *ngIf="isDeleting && currDraft == draft">
            <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
          </span>
          <button aria-label="remove form" class="btn btn-link control" matTooltip="Remove Form">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders; sticky: true"></tr>
    <tr
      mat-row
      class="mat-row-clickable"
      *matRowDef="let row; columns: resultColumnHeaders"
      [ngClass]="{ 'row-hide': row.compensationReviewId == 0 }"
      [ngStyle]="isInitiating && { 'background-color': 'lightgrey', cursor: 'wait' }"
      (click)="openForm(row)"
      style="cursor: pointer"
    ></tr>
  </table>
  <div *ngIf="dataSource.data.length === 0">No reviews found</div>
</div>
<ng-template #loading>
  <div class="loading m-auto"></div>
</ng-template>
