import { NgModule } from '@angular/core';
import { ReviewerRoutingModule } from './reviewer-routing.module';
import { ReviewerComponent } from './reviewer.component';
import { AssignReviewerComponent } from './assign-reviewer/assign-reviewer.component';
import { AddReviewerComponent } from './add-reviewer/add-reviewer.component';
import { SharedModule } from '../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        ReviewerRoutingModule,
        SharedModule,
        MatTooltipModule
    ],
    declarations: [
        ReviewerComponent,
        AddReviewerComponent,
        AssignReviewerComponent
    ],
    exports: [
        MatTooltipModule
    ],
    providers: []
})

export class ReviewerModule { }
