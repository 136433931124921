import { Injectable } from '@angular/core';
import { startWith, map } from 'rxjs/operators';
import { FilterableObject, Practice, PrimaryCompetency, ServiceLine, StatusType } from '../models/draftSummary.model';
import { Employee } from './../models/employee.model';

@Injectable()
export class AutoSuggestService {
  onlinerDisplay = (option?: Employee): string | undefined =>
    option ? `${option.firstName} ${option.lastName}`.trim() : undefined;

  practiceDisplay = (option?: Practice): string | undefined =>
    option ? `${option.name}`.trim() : undefined;

  serviceLineDisplay = (option?: ServiceLine): string | undefined =>
    option ? `${option.name}`.trim() : undefined;

  statusTypeDisplay = (option?: StatusType): string | undefined =>
    option ? `${option.statusTypeName}`.trim() : undefined;

  competencyDisplay = (option?: PrimaryCompetency): string | undefined =>
    option ? `${option.name}`.trim() : undefined;

  filterableDisplay = (option?: FilterableObject): string | undefined => (option ? `${option.name}`.trim() : undefined);
  fiscalYearDisplay = (option?: number): number | undefined => (option ? option : undefined);
  reviewerDisplay = (option?: Employee): string | undefined => option ? `${option.firstName} ${option.lastName}`.trim() : undefined;

  setOnlinerFilter(onlinerControl, onliners: Employee[]) {
    return onlinerControl.valueChanges.pipe(
      startWith<string | Employee>(null),
      map(value => (typeof value === typeof '' ? <string>value : (<any>value || {}).description)),
      map(description => description ? this.onlinerFilter(description.toString(), onliners) : onliners)
    );
  }

  private onlinerFilter(val: string, onliners: Employee[]): Employee[] {
    type EmployeeCompare = { firstName: string; lastName: string };
    const filterFn = (onliner: EmployeeCompare) =>
      `${onliner.firstName.toLowerCase()} ${onliner.lastName.toLowerCase()}`.includes(val.toLowerCase());
    return (onliners as EmployeeCompare[]).filter(filterFn) as typeof onliners;
  }

  setControlFilter(control, filterables: FilterableObject[]) {
    const practiceFilter = (val: string, practices: FilterableObject[]) => {
      return practices.filter(practice => `${practice.name.toLowerCase()}`.includes(val.toLowerCase()));
    };

    return control.valueChanges.pipe(
      startWith<string | FilterableObject>(null),
      map(value => (typeof value === typeof '' ? <string>value : (<any>value || {}).description)),
      map(description => (description ? practiceFilter(description.toString(), filterables) : filterables.slice()))
    );
  }

  setServiceLineFilter(control, serviceLines: ServiceLine[]) {
    const serviceLineFilter = (val: string, serviceLines: ServiceLine[]) => {
      const serviceLine = serviceLines.filter(serviceLine => `${serviceLine.name.toLowerCase()}`.includes(val.toLowerCase()));
      return serviceLine;
    };

    return control.valueChanges.pipe(
      startWith<string | ServiceLine>(null),
      map(value => (typeof value === typeof '' ? <string>value : (<any>value || {}).description)),
      map(description => (description ? serviceLineFilter(description.toString(), serviceLines) : serviceLines.slice()))
    );
  }

  setFiscalYearFilter(control, fiscalYears: number[]) {
    const ficsalYearFilter = (val: number, fiscalYears: number[]) => {
      const fiscalYear = fiscalYears.filter(fiscalYear => fiscalYear == val)
      return fiscalYears;
    };

    return control.valueChanges.pipe(
      startWith<number>(null),
      map(value => (typeof value === typeof 0 ? ficsalYearFilter(value as number, fiscalYears) : fiscalYears.slice()))
    )
  }
}
