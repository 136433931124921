import { Inject, Injectable, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { interceptAndRetry } from 'src/app/util/retryUtil';
import { IUserProfile } from './../models/auth.model';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, AccountInfo, CacheLookupPolicy, PopupRequest } from '@azure/msal-browser';
import { AuthorizedHttp } from '../services/authorized-http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  sessionExpiredDialogIsOpened = new EventEmitter<boolean>(false);

  public renewIdTokenRequest = {
    scopes: [environment.webApiClientId + '/.default'],
    caches: CacheLookupPolicy.AccessToken
  };

  constructor(private msalAuthService: MsalService, private http: HttpClient) { }


  logout(): void {
    const currentAccount = this.msalAuthService.instance.getActiveAccount();
    const url = environment.apiEndpoint + '/api/authorization/invalidate/' + this.getUserId();
    const idTokenHint = currentAccount?.idTokenClaims?.login_hint;
    const handleLogout = () => {
      this.msalAuthService.logoutRedirect({
        postLogoutRedirectUri: environment.postLogoutRedirect,
        logoutHint: idTokenHint,
        account: currentAccount
      }).subscribe(() => {
        window.location.href = environment.postLogoutRedirect;
      });
    }

    this.http.post(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe({
      complete: handleLogout,
      error: handleLogout
    })

 
  }

  isAuthenticated(): boolean {
    return !!this.getUserProfile();
  }

  getUserId(): string {
    return (this.getUserProfile()?.idTokenClaims?.preferred_username || '').split('@')[0];
  }

  getUserProfile(): AccountInfo {
    return this.msalAuthService.instance.getActiveAccount()
  }

  getUserRoles(): string[] {
    return this.getUserProfile()?.idTokenClaims?.roles || [];
  }




  doesUserHaveRole(roles: string[]): boolean {
    const userRoles = this.getUserProfile()?.idTokenClaims?.roles;
    if (!roles || roles.length <= 0 || !userRoles || userRoles.length <= 0) {
      return false;
    }
    const userHaveRole = userRoles.some(userRole => roles.includes(userRole));

    return userHaveRole;
  }

  isRegularUser(): boolean {
    const userRoles = this.getUserProfile()?.idTokenClaims?.roles;
    const rolesToValidate = [
      environment.roles.CompApprovInitiator,
      environment.roles.CompApprovAdmin,
      environment.roles.CompApprovReviewer,
      environment.roles.CompApprovPayroll
    ];

    return userRoles && userRoles.length > 0 && !userRoles.some(role => rolesToValidate.includes(role));

  }
  acquireToken(
    type: 'popup' | 'redirect' | 'silent',
    totalNumAttempts?: number
  ): Observable<AuthenticationResult | void> {
    // In Edge v120, `acquireTokenPopup` without `prompt` will cause the app hang.
    // Adding a 'login' promot to force user retype password will solve this issue.
    const popupRequest: PopupRequest = /edg\//i.test(window.navigator.userAgent)
      ? { ...this.renewIdTokenRequest, prompt: 'login' }
      : this.renewIdTokenRequest;

    switch (type) {
      case 'popup':
        return interceptAndRetry(
          () => this.msalAuthService.acquireTokenPopup(popupRequest),
          250,
          totalNumAttempts ?? 7
        );

      case 'redirect':
        return interceptAndRetry(
          () =>
            this.msalAuthService.acquireTokenRedirect(this.renewIdTokenRequest),
          250,
          7
        );

      case 'silent':
      default:
        return interceptAndRetry(
          () =>
            this.msalAuthService.acquireTokenSilent(this.renewIdTokenRequest),
          250,
          7
        );
    }
  }

}
