import { NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { AuthorizedHttp } from './services/authorized-http.service';
import { AutoSuggestService } from './services/auto-suggest.service';
import { NavigationService } from './services/navigation.service';
import { PendingChangesGuard } from './services/pending-changes-guard.service';
import { SnackBarService } from './services/snackbar.service';
import { UserService } from './services/user.service';
import { EmailService } from './services/email.service';

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  providers: [
    AuthorizedHttp,
    AuthService,
    AuthGuard,
    PendingChangesGuard,
    SnackBarService,
    AutoSuggestService,
    UserService,
    EmailService,
    NavigationService,
    DatePipe,
    CurrencyPipe,
    PercentPipe
  ]
})
export class CoreModule {}
