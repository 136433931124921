import { Directive, Attribute } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[minDateRangeValidator][formControl],[minDateRangeValidator][formControlName],[minDateRangeValidator][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: minDateRangeValidatorDirective, multi: true}]
})
export class minDateRangeValidatorDirective implements Validator {
  constructor(
    @Attribute('minDateRangeValidator') public minDateRangeValidator: string,
  ) {
  }

  validate(dateRangeEndDateControl: UntypedFormControl) {
    let dateRangeStartDateControl = dateRangeEndDateControl.root.get(this.minDateRangeValidator);
    if (!dateRangeStartDateControl) 
      return

    dateRangeStartDateControl.setErrors(null);
    
    let dateRangeStartDate = dateRangeStartDateControl.value;    
    let dateRangeEndDate = dateRangeEndDateControl.value;
    
    if (!dateRangeStartDate || !(dateRangeStartDate instanceof Date)){
        dateRangeStartDateControl.setErrors({"invalidStartDate": true}); 
    }     
      
    var err = (!dateRangeEndDate || !(dateRangeEndDate instanceof Date) || dateRangeEndDate < dateRangeStartDate)
      ? {"invalidEndDate": true} 
      : null;

    return err;
  }
} 