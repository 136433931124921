import { Injectable } from '@angular/core';
import { CompensationPrintForm } from '../models/compensationPrintForm.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  private buildPrintFormSource = new Subject<CompensationPrintForm>();
  public buildPrintForm$ = this.buildPrintFormSource.asObservable();
  public buildPrintForm = (printForm: CompensationPrintForm) => this.buildPrintFormSource.next(printForm);

  private printFormRequestedSource = new Subject<void>();
  public printFormRequested$ = this.printFormRequestedSource.asObservable();
  public printFormRequested = () => this.printFormRequestedSource.next();

  constructor() { }

}
