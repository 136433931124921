<div class="dropdown">
  <div class="dropdown-content">
    <div *ngFor="let item of items" (click)="close()" class="dropdown-footer" fxLayout="row" fxLayoutAlign="flex-start">
      <button class="notification-label flex justify-start" color="primary" mat-button (click)="openForm(item.id)">{{item.label}}</button>
    </div>
    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="flex-start">
      <button class="notification-label flex justify-start" color="primary" mat-button (click)="logout()">Log Out</button>
    </div>
  </div>
</div>
