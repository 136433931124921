import { Employee } from './employee.model';
import {
  CompensationReviewHistory,
  VacationReviewHistory,
  CompensationReviewComment,
  CompensationReviewStatus,
  CompensationReviewAudit,
} from './compensationReview.model';

export class DraftSummary {
  compensationReviewId: number;
  onlinerEmployee: Employee;
  statusTypeId: number;
  reviewerEmployee: Employee;
  onlinerEmployeeId: string;
  reviewerEmployeeId: string;
  createdDate: Date;
  startDate: Date;
  bccAll: string;
  bccAllName: string;
  practice: Practice;
  serviceLine: ServiceLine;
  consultantLevel: ConsultantLevel;
  primaryCompetency: PrimaryCompetency;
  statusType: StatusType;
  status: CompensationReviewStatus[];
  history: CompensationReviewHistory[];
  vacationHistory: VacationReviewHistory[];
  anniversaryDate: Date;
  comments: CompensationReviewComment[];
  audits: CompensationReviewAudit[];
  isForce0: boolean;
}

export class DraftsView {
  compensationReviewId: number;
  onlinerId: string;
  onliner: string;
  reviewerId: string;
  reviewer: string;
  serviceLineId: string;
  serviceLine: string;
  practiceId: string;
  practice: string;
  primaryCompetencyId?: number;
  primaryCompetency: string;
  consultantLevelId?: number;
  consultantLevel: string;
  bcc: string;
}

export interface FilterableObject {
  name: string;
}

export class Practice implements FilterableObject {
  practiceId: string;
  name: string;
}

export class ServiceLine implements FilterableObject {
  serviceLineId: string;
  name: string;
  practiceId: string;
  practice?: Practice;
}

export class PrimaryCompetency implements FilterableObject {
  competencyId: number;
  name: string;
}
export class ConsultantLevel implements FilterableObject {
  consultantLevelId: number;
  name: string;
}

export class StatusType {
  sortOrder: number;
  statusTypeName: string;
  statusTypeId: number;
}

export class FilterStatusType {
  sortOrder: number;
  name: string;
  statusTypeId: number;
}
