import {throwError, of , Observable} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

import {AuthorizedHttp} from './authorized-http.service';


@Injectable()
export class UserService {

  private apiEndpoint: string = `${environment.apiEndpoint}/api/user`;

  constructor(private http: AuthorizedHttp) {
  }

  public getMyProfilePhoto(): Observable<any> {
    return this.http
      .getText(`${this.apiEndpoint}/myphoto`)
      .pipe(
        catchError((errorResponse: any) => throwError(errorResponse.error || 'Server error'))
      );
  }
}
