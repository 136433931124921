import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VexModule } from '../@vex/vex.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { OnlinerService } from './core/services/onliner.service';
import { minDateRangeValidatorDirective } from './shared/min-date-range-validator.directive';
import { maxDateRangeValidatorDirective } from './shared/max-date-range-validator.directive';
import { DraftsComponent } from './drafts/drafts.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { OnlinerFormComponent } from './onlinerform/onlinerform.component';
import { CompensationFilterFormComponent } from './compensation-filter-form/compensation-filter-form.component';
import { DraftListComponent } from './draft-list/draft-list.component';
import { HomeModule } from './home/home.module';
import { CompensationFormModule } from './compensation-form/compensation-form.module';
import { CoreModule } from './core/core.module';
import { HistoryComponent } from './history/history.component';
import { CompensationResultListComponent } from './compensation-result-list/compensation-result-list.component';
import { ReviewerModule } from './reviewer/reviewer.module';
import { WorkflowsListComponent } from './workflows-list/workflows-list.component';
import { CompensationFormPrintComponent } from './compensation-form-print/compensation-form-print.component';
import { CompensationMainComponent } from './compensation-main/compensation-main.component';
import { ProcessedFormsListComponent } from './processed-forms-list/processed-forms-list.component';
import { ProcessedFormsComponent } from './processed-forms/processed-forms.component';
import { DatePipe } from '@angular/common';
import { Dialogs } from './util/Dialogs';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { ForbiddenComponent } from './shared/forbidden/forbidden.component';
import { BlankSplashComponentComponent } from './core/blank-splash-component/blank-splash-component.component';
import { CustomLayoutModule } from './core/custom-layout/custom-layout.module';


const appRoutes: Routes = [
  {
    component: BlankSplashComponentComponent,
    matcher: (url) => {
      return url.length === 1 && url[0].path.startsWith('code=')
        ? { consumed: url }
        : null;
    },
  },
  { path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full' }
];

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL Angular: ', message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.webApiClientId,
      authority: environment.cloudInstanceId + environment.tenant,
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ["User.Read"]);
  protectedResourceMap.set(environment.apiEndpoint, [environment.webApiClientId + '/.default']);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/assessment/external`, null); // skip authentication for public API endpoints.
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["User.Read"]
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    minDateRangeValidatorDirective,
    maxDateRangeValidatorDirective,
    DraftsComponent,
    WorkflowsComponent,
    OnlinerFormComponent,
    CompensationFilterFormComponent,
    DraftListComponent,
    HistoryComponent,
    CompensationResultListComponent,
    CompensationFormPrintComponent,
    CompensationMainComponent,
    WorkflowsListComponent,
    ProcessedFormsListComponent,
    ProcessedFormsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    AppRoutingModule,
    MsalModule,
    HttpClientModule,
    CoreModule,
    AdminModule,
    HomeModule,
    ReviewerModule,
    CompensationFormModule,
    SharedModule,
    NgxDatatableModule,
    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    OnlinerService,
    DatePipe,
    Dialogs],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
