<div class="row m-0 h-100">
  <div class="component-container flex-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-compensation-filter-form [searchCriteriaForm]="filterCriteriaForm" [onWorkflowPage]="false"
        [userCanSeeStatusFilter]="userCanSeeStatusFilter" [onProcessedFormsPage]="true"
        [reviewerDefault]="reviewerDefault" [reviewerUser]="reviewerUser" [reviewerStatusType]="reviewerFilterStatusType"
        [initiatorDefault]="initiatorDefault" [initiatorUser]="initiatorUser"
        (filterTriggered)="getCompensationWorkflows()">
      </app-compensation-filter-form>
    </mat-expansion-panel>
    <h4 class="mb-3 mt-3 page-title">Processed Forms</h4>
    <app-processed-forms-list class="flex-list mat-elevation-z8" [reviewId]="linkReviewId" [reviews]="filteredWorkflows"
      [unfilteredReviews]="workflows" [closedStatusTypes]="closedStatusTypes" [canEmail]="isInitiator" [loggedInUser]="loggedInUser">
    </app-processed-forms-list>
  </div>
</div>
