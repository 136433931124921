<!--prettier-ignore-->
<nav *ngIf="showMenuItems" class="navbar navbar-expand-lg navbar-dark">
  <div id="navbarNav">
    <ul class="navbar-nav flex-column">
      <!-- <li class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="home" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"> <span class="icon-container"><i
              class="fas fa-home"></i></span>Home <span class="sr-only">(current)</span></a>
      </li> -->

      <li *ifRole="ns.formRoles" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="newforms" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"> <span class="icon-container"><i
            class="fas fa-search-plus"></i></span>Forms</a>
      </li>

      <li *ifRole="ns.draftRoles" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="drafts" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"> <span class="icon-container"><i
              class="fas fa-file-invoice"></i></span>Drafts </a>
      </li>
      <li class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="workflows" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"> <span class="icon-container"><i
              class="fas fa-table"></i></span>Workflows </a>
      </li>
      <li *ifRole="ns.processedRoles.join(',')" class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="processedForms" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"><span class="icon-container"><i 
              class="fas fa-file-signature fa-lg"></i></span>Processed Forms </a>
      </li>
      <li class="nav-item nav-item-container" routerLinkActive="nav-active">
        <a class="nav-link" routerLink="history" routerLinkActive="$router_link-active$"
          [routerLinkActiveOptions]="{ exact: true }"> <span class="icon-container"><i
              class="fas fa-history"></i></span>History </a>
      </li>
    </ul>
  </div>
</nav>
