import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';

import { AutoSuggestService } from '../../core/services/auto-suggest.service';
import { OnlinerService } from '../../core/services/onliner.service';
import { SnackBarService } from '../../core/services/snackbar.service';
import { Employee } from '../../core/models/employee.model';
import { SalaryReviewerApprover } from '../../core/models/salaryReviewerApprover.model';

import { ConfirmationDialogComponent, ConfirmDialogData } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import * as formValidator from '../../shared/form-validator';

@Component({
  selector: 'app-add-reviewer',
  templateUrl: './add-reviewer.component.html',
  styleUrls: ['./add-reviewer.component.scss']
})

export class AddReviewerComponent implements OnInit {
  addReviewerForm: UntypedFormGroup;
  onliners: Employee[];

  onlinerReviewer: string;
  reviewersOfOnliner: Employee[];

  filteredOnliners: Observable<Employee[]>;
  filteredReviewers: Observable<Employee[]>;
  filteredReviewersOfOnliner: Observable<Employee[]>;

  isLoading: boolean = true;
  isSearching: boolean;
  isSubmitting: boolean;

  selectedOnlinerID: string;
  reviewers: Employee[];
  salaryReviewers: SalaryReviewerApprover[];

  get onlinerControl() { return this.addReviewerForm.controls['onliner']; };
  get getReviewerButton() { return this.addReviewerForm.controls['getReviewerButton']; };

  constructor(
    private autoSuggestService: AutoSuggestService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.setupPage();
  }
  ngOnDestroy() {
    this.snackBarService.clean();
  }

  private setupPage() {
    this.selectedOnlinerID = null;
    this.isLoading = true;

    forkJoin([this.onlinerService.getOnliners(), this.onlinerService.getReviewers(), this.onlinerService.getOnlinersOfReviewer()])
      .subscribe(([onliners, reviewers, salaryReviewers]) => {
        this.onliners = onliners.sort((a, b) => a.fullName.localeCompare(b.fullName));
        this.reviewersOfOnliner = reviewers.sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.salaryReviewers = salaryReviewers.sort((a, b) => `${a.reviewerUserId} ${a.onlinerUserId}`.localeCompare(`${b.reviewerUserId} ${b.onlinerUserId}`));

        this.addReviewerForm = this.createForm();
        this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(this.onlinerControl, this.onliners);

        this.isLoading = false;
      },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        });
  }

  private createForm() {
    return this.formBuilder.group({
      'onliner': ['', formValidator.validateOnliner()],
      'getReviewerButton': [''],
    });
  }

  onlinerDisplay = (option?: Employee): string | undefined => this.autoSuggestService.onlinerDisplay(option);

  onlinerSelected({ source, option: { value } }: MatAutocompleteSelectedEvent) {
    //this.selectedOnlinerID = value.userId
    this.selectedOnlinerID = value;
    if (source && value || value.userId === '') {
      this.getReviewerButton.disable();
    } else {
      this.getReviewerButton.enable();
    }
  }

  getReviewer() {
    this.isSubmitting = true;
    this.reviewersOfOnliner = null;
    this.onlinerService.getReviewers()
      .subscribe(data => {
        this.reviewersOfOnliner = data.sort((a, b) => a.firstName.localeCompare(b.firstName));
        this.isSubmitting = false;
      })
  }

  deleteReviewer(user: Employee) {
    const fullName = user.fullName;
    this.onlinerService.deleteReviewer(user.userId)
      .subscribe(result => {
        this.snackBarService.message(`${fullName} has been removed as a Reviewer`);
        this.getReviewer();
      },
        error => {
          this.snackBarService.error(error);
        })

  }

  addReviewer() {
    const { firstName, lastName, userId } = this.addReviewerForm.controls.onliner.value as Employee;
    this.onlinerService.addReviewer(userId)
      .subscribe(data => {
        this.snackBarService.message(`${firstName} ${lastName} has been added as a Reviewer`);
        this.isLoading = false;
        this.getReviewer();
      },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        })
  }

  isReviewerAlreadyAdded(): boolean {
    const { userId } = this.addReviewerForm.controls.onliner.value as Employee;
    if (this.reviewersOfOnliner && this.reviewersOfOnliner.length > 0) {
      // Check if the selected reviewer's userId is in the list of reviewers
      return this.reviewersOfOnliner.some(reviewer => reviewer.userId === userId);
    }
  }

  openDeleteConfirmation(reviewer: Employee) {
    const list = this.salaryReviewers.filter(s => s.reviewerUserId === reviewer.userId);

    if (list.length > 0) {
      this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: {
          title: 'Can\'t Remove Reviewer',
          message: `Please disassociate ${reviewer.fullName} from their assigned Onliners.`,
          okButtonTitle: 'OK',
          theme: 'success'
        } as ConfirmDialogData,
        disableClose: true
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: {
          title: 'Remove Reviewer',
          message: `Are you sure you want to remove ${reviewer.fullName} as a Reviewer?`,
          okButtonTitle: 'Yes',
          cancelButtonTitle: 'No',
          theme: 'danger'
        } as ConfirmDialogData,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === "ok") {
          //this.deleteReviewer(reviewer.userId);
          this.deleteReviewer(reviewer);
        }
      });
    }
  }

  openOnlinersReviewed(reviewer: Employee) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Dissassociate Onliners from Reviewer',
        okButtonTitle: 'Yes',
        theme: 'danger'
      } as ConfirmDialogData,
      disableClose: true
    });
  }
}
