import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

type themeTypes = 'success' | 'danger';
export class ConfirmDialogData {
  title: string;
  message: string;
  okButtonTitle: string;
  cancelButtonTitle: string;
  theme: themeTypes;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  confirmDialog: ConfirmDialogData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.confirmDialog = data;
    if (!this.confirmDialog.theme) {
      this.confirmDialog.theme = "success";
    }
  }

  ngOnInit() {
  }
}