import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { CompensationService, defaultFilterStatusType } from '../core/services/compensation.service';
import { CompensationFilterService, FilterFormGroup } from '../core/services/compensation-filter.service';
import { OnlinerService, defaultOnliner } from '../core/services/onliner.service';
import { SnackBarService } from '../core/services/snackbar.service';

import { CompensationReviewStatusType } from '../core/models/compensationReview.model';
import { FilterStatusType, StatusType } from '../core/models/draftSummary.model';
import { StatusTypeEnum } from '../core/models/enums.model';
import { Employee } from '../core/models/employee.model';
import { ProcessedFormsView } from '../core/models/processedFormsView.model';
import { Dialogs } from '../util/Dialogs';

@Component({
  selector: 'app-processed-forms',
  templateUrl: './processed-forms.component.html',
  styleUrls: ['./processed-forms.component.scss'],
})
export class ProcessedFormsComponent implements OnInit {
  isAdmin: boolean;
  isInitiator: boolean;
  isReviewer: boolean;
  isPayroll: boolean;
  isUser: boolean;
  loggedInUser: Employee;

  userCanSeeFilter: boolean = false;
  userCanSeeStatusFilter: boolean = false;
  workflows: ProcessedFormsView[];
  filteredWorkflows: ProcessedFormsView[];
  filterCriteriaForm: FilterFormGroup;
  closedStatusTypes: CompensationReviewStatusType[];
  reviewerDefault: boolean;
  initiatorDefault: boolean;
  reviewerUser: Employee;
  initiatorUser: Employee;
  reviewerFilterStatusType: FilterStatusType;
  subscriptions: Subscription[] = [];
  linkReviewId: number = 0;

  filterPracticeValue: string;
  filterServiceValue: string;
  filterPrimaryCompetencyValue: string;
  filterReviewerValue: string;
  filterInitiatorValue: string;
  filterConsultantLevelValue: string;
  filterStatusValue: string;
  filterFiscalYearValue: string;
  filtersValue: string;

  isRegularUser: boolean;

  get numWorkflowsDisplay(): string {
    return this.filteredWorkflows
      ? `(${this.filteredWorkflows.filter((w) => w.compensationReviewId != 0).length})`
      : '';
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private commonService: CommonService,
    private compensationService: CompensationService,
    private filterService: CompensationFilterService,
    private snackBarService: SnackBarService,
    private onlinerService: OnlinerService,
    private dialog: Dialogs
  ) { }

  ngOnInit() {
    this.isRegularUser = this.authService.isRegularUser();
    this.commonService.getAllCommonData().subscribe(
      (commonData) => {
        if (!this.isRegularUser) {
          forkJoin([this.onlinerService.getReviewers(), this.onlinerService.getOnliners()]).subscribe(
            ([reviewers, employees]) => {
              this.checkRoles(reviewers, employees, commonData.statusTypes);
              this.setupWorkflowList();
            },
            (error) => {
              this.snackBarService.error(error);
            }
          );
        } else {
          this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
            true,
            true,
            defaultOnliner,
            defaultOnliner,
            defaultFilterStatusType
          );
          this.setupWorkflowList();
        }
      },
      (error) => {
        this.snackBarService.error(error);
      }
    );
  }

  private setupWorkflowList() {
    this.getCompensationWorkflows();
    this.subscriptions.push(
      this.compensationService.reviewUpdated$.subscribe((review) => {
        if (review) {
          this.getCompensationWorkflows();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private checkRoles(reviewers: Employee[], initiators: Employee[], statusTypes: StatusType[]) {
    this.isAdmin = this.authService.doesUserHaveRole([environment.roles.CompApprovAdmin]);
    this.isInitiator = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.isReviewer = this.authService.doesUserHaveRole([environment.roles.CompApprovReviewer]);
    this.isPayroll = this.authService.doesUserHaveRole([environment.roles.CompApprovPayroll]);
    this.isUser = this.authService.doesUserHaveRole([environment.roles.CompApprovUser]);

    this.loggedInUser = initiators.find(e => e.userId == this.authService.getUserId().toLowerCase());
    this.userCanSeeFilter = this.isAdmin;

    this.initiatorDefault = this.isInitiator && !(this.isAdmin || this.isReviewer || this.isPayroll);
    if (this.isReviewer) {
      this.reviewerUser = reviewers.find((rev) => rev.userId == this.authService.getUserId().toLowerCase());
      var reviewerDefaultStatusType = statusTypes.find((status) => status.statusTypeId == StatusTypeEnum.Processed);
      this.reviewerFilterStatusType = {
        name: reviewerDefaultStatusType.statusTypeName,
        statusTypeId: reviewerDefaultStatusType.statusTypeId,
        sortOrder: reviewerDefaultStatusType.sortOrder,
      };
      if (this.reviewerUser && !this.isInitiator) {
        this.reviewerUser.practiceId = this.loggedInUser.practiceId;
        this.reviewerUser.practiceName = this.loggedInUser.practiceName;
        this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
          true,
          true,
          this.reviewerUser,
          defaultOnliner,
          this.reviewerFilterStatusType
        );
        this.reviewerDefault = true;
      }
      else if (this.reviewerUser) {
        this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
          true,
          true,
          this.reviewerUser,
          defaultOnliner,
          this.reviewerFilterStatusType
        );
        this.reviewerDefault = true;
      }
    } else {
      this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
        false,
        false,
        defaultOnliner,
        defaultOnliner,
        defaultFilterStatusType
      );
    }
  }

  public getCompensationWorkflows(ignoreCache: boolean = false) {
    const fiscalYear = (this.filterService.getFilterCriteriaModel(false, false).fiscalYear).toString();
    forkJoin([this.commonService.getAllCommonData(), this.compensationService.getAllProcessedForms(fiscalYear)]).subscribe(
      ([commonData, workflows]) => {
        this.closedStatusTypes = commonData.closedStatusTypes;
        this.workflows = workflows;
        this.filteredWorkflows = workflows;
        this.route.queryParamMap.subscribe((q) => {
          this.linkReviewId = +q.get('reviewId');
        });
       this.filterWorkflows();
      },
      (error) => {
        this.dialog.getErrorDialog(error);
        this.workflows = [];
        this.filteredWorkflows = [];
        // this.snackBarService.error(error);
      }
    );
  }

  filterWorkflows() {
    const filterCriteriaModel = this.filterService.getFilterCriteriaModel(false, false);
    this.filteredWorkflows = this.workflows && this.workflows.filter(
      (f) =>
        this.filterService.filterByPractice(f, filterCriteriaModel) &&
        this.filterService.filterByConsultantLevel(f, filterCriteriaModel) &&
        this.filterService.filterByCompetency(f, filterCriteriaModel) &&
        this.filterService.filterByReviewer(f, filterCriteriaModel) &&
        this.filterService.filterByServiceLine(f, filterCriteriaModel)
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterPracticeValue = this.filterCriteriaForm.form.controls.practice.value['name'];
    this.filterServiceValue = this.filterCriteriaForm.form.controls.serviceLine.value['name'];
    this.filterPrimaryCompetencyValue = this.filterCriteriaForm.form.controls.competency.value['name'];
    this.filterReviewerValue = this.filterCriteriaForm.form.controls.reviewer.value['fullName'];
    this.filterConsultantLevelValue = this.filterCriteriaForm.form.controls.consultantLevel.value['name'];
    this.filterFiscalYearValue = this.filterCriteriaForm.form.controls.fiscalYear.value.toString();
    this.filtersValue = 
     this.filterPracticeValue +
      ', ' +
      this.filterServiceValue +
      ', ' +
      this.filterPrimaryCompetencyValue +
      ', ' +
      this.filterReviewerValue +
      ', ' +
      this.filterConsultantLevelValue +
      ', ' +
      this.filterFiscalYearValue;   
  }
}
