import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {

  @Input() count : number;
  @Input() description : string;
  @Input() description2 : string;
  @Input() iconName : string;
  @Input() routeAddress : string;
  @Input() primaryColor : string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToAddress(){
    if(this.routeAddress && this.routeAddress !== ''){
      this.router.navigate([this.routeAddress]);
    }
  }

}
