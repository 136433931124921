import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Employee } from './../models/employee.model';
import { AuthorizedHttp } from './authorized-http.service';
import { SalaryReviewerApprover } from '../models/salaryReviewerApprover.model';

@Injectable()
export class OnlinerService {
  private apiEndpoint: string = `${environment.apiEndpoint}/api/Onliner`;
  private _onliners: Employee[];
  private _peopleCarePersons: Employee[];
  private _reviewers: Employee[];
  private _assignedReviewers: Employee[];
  private _salaryReviewers: SalaryReviewerApprover[];
  private _onlinersExcludingDefault: Employee[];
  private _peopleCareOnlinersExcludingDefault: Employee[];
  private _searchOnlinersExcludingDefault: Employee[];
  private _accountingUserId: string;

  private mapFullName = (e: Employee): Employee => {
    e.fullName = `${e.firstName} ${e.lastName}`;
    return e;
  };

  constructor(private http: AuthorizedHttp) {}

  public getOnliners(): Observable<Employee[]> {
    return this._onliners
      ? of(this._onliners)
      : this.http.get(`${this.apiEndpoint}/onliners`).pipe(
          map((result) => {
            this._onliners = result.map(this.mapFullName);
            return this._onliners;
          }),
          catchError((errorResponse: any) =>
            throwError(errorResponse.error.exceptionMessage || 'Could not find Onliners')
          )
        );
  }

  public getPeopleCareOnliners(): Observable<Employee[]> {
    return this._peopleCarePersons
      ? of(this._peopleCarePersons)
      : this.http.get(`${this.apiEndpoint}/initiators`).pipe(
        map((result) => {
          this._peopleCarePersons = result.map(this.mapFullName);
          return this._peopleCarePersons;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || 'Could not find People Care Onliners')
        )
      );
  }
  public getAssignedReviewers(): Observable<Employee[]> {
    return this._assignedReviewers
      ? of(this._assignedReviewers)
      : this.http.get(`${this.apiEndpoint}/assignedReviewers`).pipe(
          map((result) => {
            this._assignedReviewers = result.map(this.mapFullName);
            return this._assignedReviewers;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }

  public getOnlinersOfReviewer(): Observable<SalaryReviewerApprover[]> {
    return this._salaryReviewers
      ? of(this._salaryReviewers)
      : this.http.get(`${this.apiEndpoint}/onlinersOfReviewer`).pipe(
          map((result) => {
            this._salaryReviewers = result;
            return result;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }

  public getReviewers(): Observable<Employee[]> {
    return this._reviewers
      ? of(this._reviewers)
      : this.http.get(`${this.apiEndpoint}/reviewers`).pipe(
          map((result) => {
            this._reviewers = result.map(this.mapFullName);
            return this._reviewers;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }

  public getSearchOnlinersExcludingDefault(loggedInUserId: string, isReviewer: boolean): Observable<Employee[]> {
    return this.getOnliners().pipe(
      map((onliners) => {
        if (isReviewer) {
          const employee = onliners.find((o) => o.userId === loggedInUserId);
          this._searchOnlinersExcludingDefault = onliners.filter((o) => o.userId !== defaultOnliner.userId && o.practiceId === employee.practiceId);
        }
        else {
          this._searchOnlinersExcludingDefault = onliners.filter((o) => o.userId !== defaultOnliner.userId);
        }
        return this._searchOnlinersExcludingDefault;       
      }),
    );
  }
  public getPeopleCareOnlinersExcludingDefault(): Observable<Employee[]> {
    return this._peopleCareOnlinersExcludingDefault
      ? of(this._peopleCareOnlinersExcludingDefault)
      : this.getPeopleCareOnliners().pipe(
        map((onliners) => {
          this._peopleCareOnlinersExcludingDefault = onliners.filter((o) => o.userId !== defaultOnliner.userId);
          return this._peopleCareOnlinersExcludingDefault;
        })
      );
  }
  public getOnlinersExcludingDefault(): Observable<Employee[]> {
    return this._onlinersExcludingDefault
      ? of(this._onlinersExcludingDefault)
      : this.getOnliners().pipe(
        map((onliners) => {
          this._onlinersExcludingDefault = onliners.filter((o) => o.userId !== defaultOnliner.userId);
          return this._onlinersExcludingDefault;
        })
      );
  }

  public getAccountingUserId(intranetUserId: string): Observable<string> {
    intranetUserId = this.http.encrypt(intranetUserId);
    return this._accountingUserId
      ? of(this._accountingUserId)
      : this.http.get(`${this.apiEndpoint}/accountingUserId/${intranetUserId}`).pipe(
          map((result) => {
            return result;
          }),
          catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
        );
  }

  public addReviewer(userId: string): Observable<Employee> {
    userId = this.http.encrypt(userId);
    return this.http.postWithNoData(`${this.apiEndpoint}/addReviewer?userId=${userId}`).pipe(
      map((result) => {
        this._reviewers = undefined;
        return result;
      }),
      catchError((errorResponse: any) => throwError(errorResponse.error.exceptionMessage || 'Could not add reviewer'))
    );
  }

  public deleteReviewer(userId: string): Observable<Employee> {
    userId = this.http.encrypt(userId);
    return this.http.delete(`${this.apiEndpoint}/deleteReviewer/${userId}`).pipe(
      map((result) => (this._reviewers = undefined)),
      catchError((errorResponse: any) =>
        throwError(errorResponse.error.exceptionMessage || 'Could not remove reviewer')
      )
    );
  }

  public addSalaryReviewer(userId: string, reviewerId: string): Observable<Employee> {
    userId = this.http.encrypt(userId);
    reviewerId = this.http.encrypt(reviewerId);
    return this.http
      .post(`${this.apiEndpoint}/addSalaryReviewer?userId=${userId}&reviewerId=${reviewerId}`, `data`)
      .pipe(
        map((result) => {
          this._reviewers = undefined;
          this._salaryReviewers = undefined;
          return result;
        }),
        catchError((errorResponse: any) =>
          throwError(errorResponse.error.exceptionMessage || 'Could not add salary reviewer')
        )
      );
  }
}

export const defaultOnliner: Employee = {
  userId: 'ALL',
  firstName: 'All',
  lastName: '',
  fullName: 'All',
  email: '',
  accountingUserId: 'ALL',
  serviceLineId: '',
  practiceId: 'ALL',
  practiceName: 'All',
  startDate: new Date(),
  compensationReviewHistory: [],
  vacationReviewHistory: [],
  serviceLine: null,
  consultantLevel: null,
  primaryCompetency: null,
};
export const isdReviewer: Employee = {
  userId: 'isdreviewer',
  firstName: 'ISD',
  lastName: 'Reviewer',
  fullName: 'ISDReviewer',
  email: 'ISDReviewer@obsglobal.com',
  accountingUserId: 'REVIISD0',
  serviceLineId: '',
  practiceId: '',
  practiceName: '',
  startDate: new Date(),
  compensationReviewHistory: [],
  vacationReviewHistory: [],
  serviceLine: null,
  consultantLevel: null,
  primaryCompetency: null,
};
