
  <div class="row m-0 mt-2">
      <div class="col-md-4 col-sm-4 col-xs-4 float-left">
          <div>
            <img src="assets/images/obs-logo.png" class="img-fluid" alt="Online Business Systems" />
          </div>
        </div>
      <div class="col-md-8">
          <h1>Compensation Review</h1>
        </div>
  </div>
  ​
      <div class="row m-0 mt-2">
          <div class="col-sm-4">
            <div class="print-label">
                Onliner
            </div>
            <div>
                {{onlinerName}}
              </div>
          </div>
          <div class="col-sm-4">
            <div class="print-label">
                Status
            </div>
            <div >
                {{formStatus}}
              </div>
          </div>
          <div class="col-sm-4">
              <div class="print-label">
                  Reviewer
              </div>
              <div >
                  {{reviewerName}}
                </div>
          </div>
      </div>

      <div class="row m-0 mt-2">
          <div class="col-sm-4">
            <div class="print-label">
                Anniversary Date
            </div>
            <div>
                {{anniversary | date : 'MM/dd/yyyy'}}
              </div>
          </div>
          <div class="col-sm-4">
            <div class="print-label">
                Start Date
            </div>
            <div >
                {{startDate | date : 'MM/dd/yyyy'}}
              </div>
          </div>
      </div>

      <br>

      <h5>Compensation</h5>
      <div class="compensation-section">
        <div class="row header-row">
          <div class="col-md-3 font-weight-bold"> Effective Date </div>
          <div class="col-md-3 font-weight-bold"> Previous </div>
          <div class="col-md-3 font-weight-bold"> New </div>
          <div class="col-md-3 font-weight-bold"> Increase </div>
        </div>
        <!-- <div *ngFor="let compensation of compensationForm.controls['compensations'].controls; first as isCurrent"> -->
        <div>
          <!-- <div class="row body-row p-0" [formGroup]="compensation"> -->
          <div class="row body-row p-0">
            <div class="col-md-3 mx-auto">
              {{compEffectiveDate | date : 'MM/dd/yyyy'}}
            </div>
            <div class="col-md-3">
              {{previousCompensation | currency:'CAD':'symbol-narrow':'4.2-2'}}
            </div>
            <div class="col-md-3">
              {{newCompensation | currency:'CAD':'symbol-narrow':'4.2-2'}}
            </div>
            <div class="col-md-3">
              {{compensationIncrease | currency:'CAD':'symbol-narrow':'4.2-2'}} {{percentageIncrease}}
            </div>
          </div>
        </div>
      </div>
      <br>
      <h5>Vacation Entitlement</h5>
      <div class="vacation-section">
        <div class="row header-row">
          <div class="col-md-3 font-weight-bold"> Effective Date </div>
          <div class="col-md-3 font-weight-bold"> Previous </div>
          <div class="col-md-3 font-weight-bold"> New </div>
          <div class="col-md-3"></div>
        </div>
        <div>
          <div class="row body-row p-0">
            <div class="col-md-3">
              {{vacationEffectiveDate | date : 'MM/dd/yyyy'}}
            </div>
            <div class="col-md-3">
              {{previousVacation}}
            </div>
            <div class="col-md-3">
              {{newVacation}}
            </div>
            <div class="col-md-3">
              </div>
          </div>
        </div>
      </div>
      <br>
      <h5>Comments</h5>
      <div class="comments-section">
          <div class="col-md-12 col-sm-12">
              <mat-list>
                <mat-list-item>
                  <div class="col-md-2 col-sm-2 font-weight-bold">Date</div>
                  <div class="col-md-2 col-sm-2 font-weight-bold">Type</div>
                  <div class="col-md-2 col-sm-2 font-weight-bold">Comment By</div>
                  <div class="col-md-6 col-sm-6 font-weight-bold">Comment</div>
                </mat-list-item>
                <mat-list-item *ngFor="let comment of comments"
                  style="height: auto; border-bottom: 1px solid rgba(0,0,0,0.1);">
                  <div class="col-md-2 col-sm-2">{{comment.commentDate | date : 'MM/dd/yyyy'}}</div>
                  <div class="col-md-2 col-sm-2">{{comment.commentTypeDescription}}</div>
                  <div class="col-md-2 col-sm-2">{{comment.commentEmployeeName}}</div>
                  <div class="col-md-6 col-sm-6 comment" [innerHTML]="formatComment(comment.commentNote)"></div>
                </mat-list-item>
              </mat-list>
          </div>
      </div>
      <br>
      <h5 >Audit</h5>
      <div class="audits-section">
          <div class="col-md-12 col-sm-12">
              <mat-list>
                <mat-list-item>
                  <div class="col-md-2 col-sm-2 font-weight-bold">Date</div>
                  <div class="col-md-2 col-sm-2 font-weight-bold">Status</div>
                  <div class="col-md-2 col-sm-2 font-weight-bold">User</div>
                  <div class="col-md-6 col-sm-6 font-weight-bold">Info</div>
                </mat-list-item>
                <mat-list-item *ngFor="let audit of audits"
                style="height: auto; border-bottom: 1px solid rgba(0,0,0,0.1);">
                  <div class="col-md-2 col-sm-2">{{audit.auditDate | date : 'MM/dd/yyyy'}}</div>
                  <div class="col-md-2 col-sm-2">{{audit.statusDescription}}</div>
                  <div class="col-md-2 col-sm-2">{{audit.updateEmployee}}</div>
                  <div class="col-md-6 col-sm-6">{{audit.info}}</div>
                </mat-list-item>
              </mat-list>
          </div>
        </div>

  <footer class="footer">
    <!-- Copyright -->
    <div class="footer">© Online Business Systems 2019 Copyright. {{appURL}}</div>
    <div class="printedBy">Printed by {{printBy}} on {{printDate | date:'medium'}}</div>
  </footer>
