import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DraftsView } from '../core/models/draftSummary.model';
import { CompensationReview, CompensationReviewStatusType } from '../core/models/compensationReview.model';
import { AuthService } from '../core/services/auth.service';
import { CompensationService } from '../core/services/compensation.service';
import { SnackBarService } from '../core/services/snackbar.service';
import { CompensationFormComponent } from './../compensation-form/compensation-form.component';
import { environment } from 'src/environments/environment';
import {
    ConfirmDialogData,
    ConfirmationDialogComponent,
} from '../shared/confirmation-dialog/confirmation-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-draft-list',
  templateUrl: './draft-list.component.html',
  styleUrls: ['./draft-list.component.scss'],
})
export class DraftListComponent implements OnInit {
  @Input() set drafts(comps: DraftsView[]) {
    if (comps) {
      this.isLoading = false;
      this.dataSource.data = comps;
    }
  }
  @Input() closedStatusTypes: CompensationReviewStatusType[];
  @Input() statusTypes: CompensationReviewStatusType[];
  @Input() finishedUpdating: boolean;
  @Output() reloadList = new EventEmitter<boolean>();
  @Output() updateDraftNum = new EventEmitter<DraftsView>();
  @Output() updateDraftNums = new EventEmitter<DraftsView[]>();
  @Input() canMassInitiate: boolean;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  selection = new SelectionModel<DraftsView>(true, []);
  dataSource = new MatTableDataSource<DraftsView>();
  userCanDelete: boolean;
  bccView: boolean;
  isLoading: boolean = true;
  isDeleting: boolean = true;
  clicked: boolean = false;
  isInitiating: boolean = false;
  finishedRefreshing: boolean;
  currDraft: CompensationReview;
  compensationReview: CompensationReview;
  resultColumnHeaders: string[] = [];
  subscriptions: Subscription[] = [];
  initiatorHeaders = [
    'onliner',
    'reviewer',
    'practice',
    'serviceLine',
    'consultantLevel',
    'primaryCompetency',
    'bcc',
    'action',
  ];
  headers: string[] = [
    'onliner',
    'reviewer',
    'practice',
    'serviceLine',
    'consultantLevel',
    'primaryCompetency',
    'action',
  ];

  constructor(
    private authService: AuthService,
    private snackBarService: SnackBarService,
    private compensationService: CompensationService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}
  
   get showTable() {
    return this.dataSource && this.dataSource.data;
  }

  ngOnInit() {
    this.userCanDelete = this.authService.doesUserHaveRole([
      environment.roles.CompApprovInitiator,
      environment.roles.CompApprovAdmin,
    ]);
    this.bccView = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.resultColumnHeaders = this.bccView ? this.initiatorHeaders : this.headers;
    this.reloadList.next(true);
    this.subscriptions.push(
      this.compensationService.reviewUpdated$.subscribe((result) => (this.finishedRefreshing = result ? false : true))
    );
  }

  ngOnDestroy() {
    this.snackBarService.clean();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges() {
    this.finishedRefreshing = true;
  }

  openForm(review: DraftsView) {
    let dialogWidth = '45%'; // default to desktop width
    // check if the device is mobile
    if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
      dialogWidth = '90%'; // set to mobile width
    }
    this.finishedRefreshing = false;
     this.dialog.open(CompensationFormComponent, {
      data: {
        reviews: [{ compensationReviewId: review.compensationReviewId }],
        closedStatusTypes: this.closedStatusTypes,
      },
      width: dialogWidth,
      height: '95vh',
      autoFocus: false,
      disableClose: true
    });

  }

  openDeleteConfirmation = (draft: DraftsView, event: any) => {
    this.isDeleting = true;
    let draftToRemove: CompensationReview = {
      onlinerEmployeeId: draft.onlinerId,
      compensationReviewId: draft.compensationReviewId,
    };
    if (this.allowDelete()) {
      event.stopPropagation();

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {
          title: 'Confirm Delete',
          theme: 'danger',
          message: '<p>Do you wish to delete the draft for ' + draft.onliner + '?</p>',
          okButtonTitle: 'Yes',
          cancelButtonTitle: 'No',
        } as ConfirmDialogData,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'ok') {
          this.compensationService.removeDraft(draftToRemove).subscribe(
            (result) => {
              this.snackBarService.message('Draft Deleted.');
              const draftIx = this.dataSource.data.findIndex(
                (d) => d.compensationReviewId === draft.compensationReviewId
              );
              const deletedDraft = this.dataSource.data.splice(draftIx, 1);
              this.dataSource.data = [...this.dataSource.data];
              this.isDeleting = false;
              this.clicked = false;
              this.updateDraftNum.next(deletedDraft.pop());
            },
            (error) => {
              this.handleError(error);
            }
          );
        } else if (result === 'cancel') {
          this.isDeleting = false;
          this.clicked = false;
        }
      });
    }
  };

  allowDelete = () => {
    return this.userCanDelete && !this.isInitiating;
  };

  handleError(error: any) {
    if (error.ErrorType === ('MissingReviewException' || 'InvalidUserOperationException')) {
      this.snackBarService.message(error.ErrorMessage);
    } else {
      this.snackBarService.message('Could not find draft');
    }
    this.isDeleting = false;
    this.clicked = false;
    this.isLoading = true;
    this.reloadList.next(true);
  }
}
