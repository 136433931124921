<div class="component-container">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h3 class="page-title mb-3">Forms</h3>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <form [formGroup]="searchForm" novalidate>
          <mat-card appearance="outlined">

            <div class="div-content">
                <div class="row">
                  <div class="col-md-3 custom-width-div">
                    <mat-form-field appearance="outline">
                      <mat-label>Choose Onliner</mat-label>
                      <input matInput name="onliner" placeholder="Choose Onliner" [matAutocomplete]="auto" formControlName="onliner" />
                      <button *ngIf="searchForm.get('onliner').value" matSuffix mat-icon-button aria-label="Clear" (click)="searchForm.get('onliner').setValue('')">
                      <mat-icon>close</mat-icon>
                      </button>
                      <mat-icon matSuffix *ngIf="!searchForm.get('onliner').value">arrow_drop_down</mat-icon>
                      <mat-error *ngIf="searchForm.get('onliner').hasError('required') && searchForm.get('onliner').touched">
                      Please enter the name of the Onliner.
                      </mat-error>
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="onlinerDisplay" (optionSelected)="onlinerSelected($event)">
                      <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                      {{ onlinerDisplay(option) }}
                      </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <mat-divider [vertical]="true" style="height: auto;" class="card-divider"></mat-divider>
                  <div class="col-md-1.5">
                    <button mat-stroked-button class="submit-btn mr-3"
                    (click)="isForce0 ? createForceZeroForm() : createNewOnlinerForm()"
                    [disabled]="!searchForm.valid || onlinerHasOpenReview || isCreating || isSearching || generatingForms || !isAuthorized || isChecking">
                    <span *ngIf="isCreating"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>&nbsp; New
                    &nbsp;
                    </button>
                  </div>
                  <div class="col-md-1.5">
                    <mat-slide-toggle class="submit-btn custom-slide-toggle" style="font-size: 14px" [checked]="isForce0"
                    (change)="isForce0 = !isForce0"
                    [disabled]="!searchForm.valid || onlinerHasOpenReview || isCreating || isSearching || generatingForms || !isAuthorized">
                    Force-Zero*
                    </mat-slide-toggle>
                  </div>
                </div>
            </div>
 
            <mat-card-content>
              <div class="container">
                <app-compensation-result-list [isSearching]="isSearching" [clickable]="true" [linkReviewId]="linkReviewId"
                                              (doneSearch)="isSearching = false" (hasOpenReview)="onlinerHasOpenReview = $event">
                </app-compensation-result-list>
              </div>
            </mat-card-content>
          </mat-card>
</form>
    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="row mt-1" *ngIf="isAuthorized">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Generate all {{currentYear}} forms for Onliners
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-action-row>
          <button mat-stroked-button class="submit-btn" (click)="generateBatchForms(); updateProgressBar();" [disabled]="generatingForms">
            <span *ngIf="generatingForms && isLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>Generate
          </button>
        </mat-action-row>
        <mat-progress-bar class="mat-progress-bar" *ngIf="generatingForms && isLoading" mode="determinate" [value]="totalBatchFormsCreated"></mat-progress-bar>

        <mat-list *ngIf="generateResult && !isLoading" dense>
          <h3 mat-subheader>{{totalFormsCreated}} compensation forms created</h3>
          <mat-divider></mat-divider>
          <P></P>
          <td *ngIf="dataSource.data.length > 0"><h3 mat-subheader>Could not create {{ dataSource.data.length }} forms for the follwing onliners, please send list to HelpDesk to be investigated:</h3></td>
          <td *ngIf="dataSource.data.length > 0" mat-row class="download-button"><button mat-stroked-button class="submit-btn" (click)="downloadCSV()">Download CSV</button></td>
          <mat-divider *ngIf="dataSource.data.length > 0"></mat-divider>

          <div class="sticky-table">
            <table mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0">

              <!-- Onliner Column -->
              <ng-container matColumnDef="employeeWithError">
                <th mat-header-cell class="text-left" *matHeaderCellDef> Onliner </th>
                <td mat-cell class="text-left" *matCellDef="let errorList"> {{errorList.employeeWithError}}</td>
              </ng-container>

              <!-- Reason Column -->
              <ng-container matColumnDef="reasonForError">
                <th mat-header-cell class="text-left" *matHeaderCellDef> Reason for Error </th>
                <td mat-cell class="text-left" *matCellDef="let errorList"> {{errorList.reasonForError}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="resultColumnHeaders; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: resultColumnHeaders;"></tr>
            </table>
            <div *ngIf="dataSource.data.length === 0">No errors encountered</div>
          </div>

        </mat-list>
        <!-- </mat-card-content> -->

      </mat-expansion-panel>
    </div>
    <div class="col-md-1"></div>
  </div>
</div>
