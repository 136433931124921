// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AddReviewerComponent } from 'src/app/reviewer/add-reviewer/add-reviewer.component';
import { AssignReviewerComponent } from 'src/app/reviewer/assign-reviewer/assign-reviewer.component';
export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolbarUserDropdownComponent implements OnInit {
  dialogRefAdd: MatDialogRef<{}, any>;
  dialogRefAdd: MatDialogRef<{}, any>;
  dialogRefAssign: MatDialogRef<{}, any>;
  closedStatusTypes: CompensationReviewStatusType[];

  items = [
  ];

  trackById = trackById;
  userName: string;
  public profilePhotoData: string;
  private readonly _destroying$ = new Subject<void>();
  isAuthenticated: boolean = false;

  constructor(private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private dialog: MatDialog) { }

  ngOnInit() {

    this.dialogRefAdd = undefined;
    this.dialogRefAssign = undefined;


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isAuthenticated = this.authService.isAuthenticated();
        if (this.isAuthenticated) {
          const allRoles: Array<string> = this.authService.getUserRoles();
          if (allRoles.includes(environment.roles.CompApprovAdmin)) {
            this.items = [{
              id: 'add',
              label: 'Add Reviewer',
              route: '/remoteworkconfig'
            },
            {
              id: 'assign',
              label: 'Assign Reviewer',
              route: '/cmdelegation'
            }]
            this.cd.detectChanges();
          }
        }
      })
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.close();
    this.authService.logout();
  }

  closeDialog() {
    this.openForm = null;
    this.dialogRefAdd.close;
    this.dialogRefAssign.close;
  }


  openForm = (newReviewerFormOption: string) => {
    this.dialog.closeAll();

    if (newReviewerFormOption === 'add') {
      this.dialogRefAdd = this.dialog.open(AddReviewerComponent, {
        autoFocus: false,
        disableClose: false,
        panelClass: 'vex-dialog-glossy',
        //width: '100%',
        //maxWidth: '600px'
      });
    } else if (newReviewerFormOption === 'assign') {
      this.dialogRefAssign = this.dialog.open(AssignReviewerComponent, {
        autoFocus: false, disableClose: false,
        panelClass: 'vex-dialog-glossy',
        //width: '100%',
        //maxWidth: '600px'
      });
    }
  }

}
